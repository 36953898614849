<div class="fx-column" *ngIf = "team_info">
    <div *ngIf="!viewOnly" class="fx-row-end-center" [ngStyle]="{'color': save_status.color, 'font-weight': 'bold', 'margin-right': '20px', 'padding-bottom': '20px'}">{{save_status.message}}</div>
    <div *ngIf="viewOnly && !hide_buttons" class="fx-row-end-center" [ngStyle]="{'color': primary, 'font-weight': 'bold', 'margin-right': '20px', 'padding-bottom': '20px'}">Changes in this mode will not be saved.</div>
    <div class="fx-row" style="font-weight: bold; font-size: 20px" >
        <div class="fx-row-start-center fx-flex-70" style="font-weight: bold; font-size: 20px;">
            <h3>
                Takt Time: {{team_info.takt_time | number: '2.0-2'}}{{team_info.takt_time_unit}}
            </h3>
            <mat-expansion-panel class="mat-elevation-z0 fx-flex" (opened)="openState = true" (closed)="openState = false">
                <mat-expansion-panel-header  [expandedHeight]="'auto'">
                    <mat-panel-title class="sigma-cycle-time fx-row-space-between-center" *ngIf="!openState">
                        <div class="sigma-cycle-time">
                            &Sigma; CT: {{team_info.base_cycle_time | number: '1.0-2'}}{{team_info.takt_time_unit}}
                        </div>
                        <div>
                            Theoretical Processes: {{team_info.theoretical_processes | number: '1.0-1'}}
                        </div>
                        <div>
                            Actual Processes: {{team_info.actual_processes}}
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>                                              
                <mat-panel-description class="fx-row-space-between-center">
                    <mat-table [dataSource]="team_info.variant_cycle_times" class="variant-table">
    
                        <ng-container matColumnDef="variant_name">
                            <mat-header-cell class = "variant-header-row" *matHeaderCellDef>
                                Variant
                            </mat-header-cell>
                            <mat-cell class = "variant-row" *matCellDef="let d">
                                {{d.variant_name}}
                            </mat-cell>
                        </ng-container>
    
                        <ng-container matColumnDef="cycle_time">
                            <mat-header-cell class = "variant-header-row" *matHeaderCellDef>
                                &Sigma; CT
                            </mat-header-cell>
                            <mat-cell class = "variant-row" *matCellDef="let d">
                                {{d.cycle_time | number: '1.0-2'}}
                            </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="theoretical_processes">
                            <mat-header-cell class = "variant-header-row" *matHeaderCellDef>
                                Theoretical Processes
                            </mat-header-cell>
                            <mat-cell class = "variant-row" *matCellDef="let d">
                                {{d.theoretical_processes | number: '1.0-1'}}
                            </mat-cell>
                        </ng-container>
                    
                        <ng-container matColumnDef="actual_processes">
                            <mat-header-cell class = "variant-header-row" *matHeaderCellDef>
                                Actual Processes
                            </mat-header-cell>
                            <mat-cell class = "variant-row" *matCellDef="let d">
                                {{d.actual_processes}}
                            </mat-cell>
                        </ng-container>            
                            
                        <mat-header-row class = "variant-header-row" *matHeaderRowDef="displayVariantColumns">
                        </mat-header-row>
                        <mat-row class = "variant-row" *matRowDef="let row; columns: displayVariantColumns;">
                        </mat-row>
                    </mat-table>
                </mat-panel-description>
            </mat-expansion-panel>
    
            <div style="font-size: 14px;">
                Region Details:
                <div class="region-list">  
                    <div *ngFor = "let region of available_regions">
                        {{region.region}}
                        <span>
                            &nbsp; : &nbsp;
                        </span>
                        <span class="color-badge" 
                            [ngStyle]="{'background-color':region.color , 'color': region.color}">::::
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf = "!multi_team" class="fx-row-end-center fx-flex-30">
            Version:&nbsp; &nbsp; <mat-select id = "version_select" style = "width: 150px;" placeholder = "Change Version" [(value)]= "selectedVersion" (selectionChange)="changeVersion()"> 
                <mat-option *ngFor="let version of valid_versions" [value]="version.version_id" >
                     {{version.version_name}}
                   <span  *ngIf="version.active == 1" style ="color: maroon; font-size: 15px" > (Active) </span>
                </mat-option>
            </mat-select>
            <button  *ngIf = "!multi_team && team_info.standardwork_status !== 'Migrated' || !this.multi_team && this.team_info.active !== 1"  mat-raised-button id="view_edit" style="margin-left: 20px" class="mat-raised-button" type="button" label="View/Edit" (click)="changeEditStatus()">
                {{viewEditButton}}
            </button>  
        </div>
    </div>
    <div *ngIf = "versionNeedupdate"  style ="color: red; font-weight: bold; font-size: 20px ; text-align:center;"> Version Needs Attention</div> 
    <h2 class="fx-row-end-space-around" *ngIf = "team_info && !hide_buttons && !multi_team">
        <div class="fx-row">
            <button color="primary" mat-raised-button id="save" style="margin-right: 10px" [disabled]="viewOnly" class="mat-raised-button" type="button" label="Save" (click)="saveWork()">
                Save
            </button>
            <button  *ngIf="this.team_info.standardwork_status != 'Migrated'" color="primary" mat-raised-button id="create" style="margin-right: 10px" [disabled]="viewOnly || versionNeedupdate" class="btn center blue mobile-btn" type="button" label="Create Pitch" (click)="createPitch()">
                Create Pitch
            </button>
            <button  *ngIf="this.team_info.standardwork_status == 'Migrated'" color="primary" mat-raised-button id="updatesw" style="margin-right: 10px" [disabled]="viewOnly || versionNeedupdate" class="btn center blue mobile-btn" type="button" label="Update Standardized Work" (click)="updateStandardWork()">
                Update Standardized Work
            </button>
            <button color="primary" mat-raised-button style="margin-right: 10px" id="log" class="btn center blue mobile-btn" type="button" label="Print" (click)="openPrint()">
                Print
            </button>
            <button color="primary" mat-raised-button style="margin-right: 10px" id="log" class="btn center blue mobile-btn" type="button" label="Change Log" (click)="openLog()">
                Change Log
            </button>
            <button color="primary" mat-raised-button id="log" class="btn center blue mobile-btn" type="button" label="Display Ergo Score" (click)="showErgoScore()">
                Ergo Score
            </button>
        </div>     
    </h2>
</div>