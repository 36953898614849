<div><h3 mat-dialog-title>Update Standard Work</h3>
    <div>
        Update Standard Work with Yamazumi Work and Pitches?
    </div>
</div>
<br>

<div class="fx-row-center">
<button color="primary" mat-raised-button id="Confirm" class="mat-raised-button" type="button" label="Confirm" (click)="onConfirm()">
    Yes
</button>
<button color="primary" mat-raised-button id="Close" class="mat-raised-button" type="button" label="Close" (click)="onNoClick()">
    No
</button>
</div>

