<div class="fx-row-start-center">
    <div class="fx-row-start-center">
        <div *ngIf="current_nav">
            <a mat-list-item routerLink="/{{rootRoute}}/" class="navlink" style = "margin:5px">Plants</a><span>&nbsp; : &nbsp;</span> 
            <a mat-list-item routerLink="/{{rootRoute}}/{{current_nav.plant_name}}" class="navlink">{{current_nav.plant_display}}</a><span>&nbsp; : &nbsp;</span>
            <a mat-list-item routerLink="/{{rootRoute}}/{{current_nav.plant_name}}/{{current_nav.line}}" class="navlink">{{current_nav.line}}</a>
            <span *ngIf="current_nav.subline">&nbsp; : &nbsp;</span>
            <a *ngIf="current_nav.subline" mat-list-item routerLink="/{{rootRoute}}/{{current_nav.plant_name}}/{{current_nav.line}}/{{current_nav.subline}}" class="navlink">{{current_nav.subline}}</a>
        </div>
    </div>
</div>