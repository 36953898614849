<h3 mat-dialog-title>Team: {{teamName}} - {{formType}}</h3>


<div mat-dialog-content>
    <form [formGroup]="versionFormGroup">

      <mat-form-field class="card-dialog">
        <input id = "check_versionName" type = "text"  matInput cdkTextareaAutosize placeholder = "Version" formControlName="form_versionName" required>
      </mat-form-field>
      <div *ngIf="versionFormGroup.get('form_versionName').invalid && 
        versionFormGroup.get('form_versionName').errors && 
        (versionFormGroup.get('form_versionName').dirty || versionFormGroup.get('form_versionName').touched)">
        <small class="error"
          *ngIf = "versionFormGroup.get('form_versionName').hasError('required')">
              Version is Required
        </small>
      </div>

      <mat-form-field class = "card-dialog">
        <input id = "check_demand" type = "number" matInput cdkTextareaAutosize placeholder = "Demand" formControlName="form_demand" required>
      </mat-form-field>
      <div *ngIf="versionFormGroup.get('form_demand').invalid && 
        versionFormGroup.get('form_demand').errors && 
        (versionFormGroup.get('form_demand').dirty || versionFormGroup.get('form_demand').touched)">
        <small class="error"
          *ngIf = "versionFormGroup.get('form_demand').hasError('required')">
              Demand is Required
        </small>
      </div>

      <mat-form-field class = "card-dialog" *ngIf="!(formType == 'Duplicate Version')">
        <input id = "check_numPitches" type = "number" matInput cdkTextareaAutosize placeholder = "Number of Pitches" formControlName="form_numPitches" required>
      </mat-form-field>
      <div *ngIf="versionFormGroup.get('form_numPitches').invalid && 
        versionFormGroup.get('form_numPitches').errors && 
        (versionFormGroup.get('form_numPitches').dirty || versionFormGroup.get('form_numPitches').touched)">
        <small class="error"
          *ngIf = "versionFormGroup.get('form_numPitches').hasError('required')">
              Number of Pitches is Required
        </small>
      </div>

      <mat-form-field class = "card-dialog" *ngIf="!(formType == 'Duplicate Version')">
        <mat-select id = "check_twoSided" matInput cdkTextareaAutosize placeholder = "View Two Sides" formControlName="form_twoSided">
            <mat-option *ngFor="let option of sideOptions" [value]="option.value">   
                {{option.view}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class = "card-dialog">
        <input id = "check_taktTime" type = "number" matInput cdkTextareaAutosize placeholder = "TaktTime" formControlName="form_taktTime" required>
      </mat-form-field>
      <div *ngIf="versionFormGroup.get('form_taktTime').invalid && 
        versionFormGroup.get('form_taktTime').errors && 
        (versionFormGroup.get('form_taktTime').dirty || versionFormGroup.get('form_taktTime').touched)">
        <small class="error"
          *ngIf = "versionFormGroup.get('form_taktTime').hasError('required')">
              Takt time is required.
        </small>
      </div>

      <mat-form-field class = "card-dialog" *ngIf="!(formType == 'Duplicate Version')">
        <mat-select id = "check_active" matInput cdkTextareaAutosize placeholder = "Status" formControlName="form_active">
            <mat-option *ngFor="let option of activeOptions" [value]="option.value" [disabled]="option.value == 0 && active == 1">   
                {{option.view}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="card-dialog" *ngIf="!(formType == 'Duplicate Version')">
        <mat-select id = "check_baseVariantID" matInput cdkTextareaAutosize placeholder = "Base Variant" formControlName="form_baseVariantID" required> 
            <mat-option *ngFor="let variant of availableVariants" [value]="variant.variant_id">
                {{variant.variant_name}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="versionFormGroup.get('form_baseVariantID').invalid && 
        versionFormGroup.get('form_baseVariantID').errors && 
        (versionFormGroup.get('form_baseVariantID').dirty || versionFormGroup.get('form_baseVariantID').touched)">
        <small class="error"
          *ngIf = "versionFormGroup.get('form_baseVariantID').hasError('required')">
              Base Variant is Required
        </small>
      </div>   

    </form>
</div> 
<div *ngIf="isBusy">
  <mat-spinner></mat-spinner>
</div>           
<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button *ngIf = "formType == 'Create New Version'" [disabled] = "versionFormGroup.status === 'INVALID' || isBusy" mat-raised-button color="primary" (click)="createVersion()">Create</button>
  <button *ngIf = "formType == 'Update Version'" [disabled]= "versionFormGroup.status === 'INVALID' ||isBusy" mat-raised-button color="primary" (click) = "updateVersion()">Update</button>
  <button *ngIf = "formType == 'Duplicate Version'"  [disabled]= "versionFormGroup.status==='INVALID' || isBusy" mat-raised-button color="primary" (click) = "duplicateVersion()">Duplicate</button>
  <span class = "spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>
