import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { VariantType, DeletedetailsType, DeletedTeamType } from '../shared/models/type-list';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent  {

  displayTeamName: string;
  pitchID: string;
  pitchType: string;
  subassemblyID: string
  taktTime: number;
  pitchVariants: VariantType[] = [];
  canDelete: boolean;
  confirmdelete : boolean;

  deleteFormGroup: UntypedFormGroup;
  pitchFormType: string;
  variantcount:number;
  confirmationresponse :string;
  deleteList :DeletedetailsType[];
  deleteTeamList : DeletedTeamType[];
  totalwork :number;
  deleteVariant :boolean;
  deleteRegion : boolean;
  uncheckedVariants : string [];
  uncheckedVariantswork : number;
  selectregion : string;
  selectteam : string;
  deleteTeam : boolean;
  deletePitch :boolean;
  team_name : string;
  line :string;
  subline : string;
  total_pitches:number;
  variantName: string;
  deleteTeamVariant: boolean;
  deleteVersion: boolean;
  versionName : string;
  versionteam : string;

  displayDeleteionDetailsColumns: string[] = ['pitch_id','variant_count','total_work']
  displayDeleteionTeamDetailsColumns: string[] = ['line','subline','total_pitches']

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public deleteData: DeleteDialogData,
    private deleteFormBuilder: UntypedFormBuilder,
  ) 
  { 
    console.info(deleteData)
    this.deleteList = [];
    this.deleteTeamList = [];
    deleteData.team_name? this.displayTeamName = deleteData.team_name : this.displayTeamName = ''
    deleteData.team_name? this.selectteam = deleteData.team_name : this.displayTeamName = ''
    deleteData.team_name? this.versionteam = deleteData.team_name : this.versionteam = ''
    deleteData.pitch_id? this.pitchID = deleteData.pitch_id : this.pitchID = ''
    deleteData.pitchVariants ? this.pitchVariants = deleteData.pitchVariants : this.pitchVariants = []
    deleteData.pitchVariants ? this.variantcount = deleteData.pitchVariants.length : this.variantcount = 0
    deleteData.totalwork?this.totalwork=deleteData.totalwork : this.totalwork = 0
    deleteData.can_delete? this.canDelete = deleteData.can_delete : this.canDelete = false
    deleteData.form_type ? this.pitchFormType = deleteData.form_type : this.pitchFormType = 'deletePitch'
    deleteData.uncheckedvariants ? this.uncheckedVariants = deleteData.uncheckedvariants : this.uncheckedVariants = []
    deleteData.uncheckedvariantswork ? this.uncheckedVariantswork = deleteData.uncheckedvariantswork : this.uncheckedVariantswork = 0
    deleteData.region ? this.selectregion = deleteData.region : this.selectregion = ''
    deleteData.team_name ? this.team_name = deleteData.team_name = deleteData.team_name : this.team_name = ''
    deleteData.line ? this.line = deleteData.line = deleteData.line : this.team_name = ''
    deleteData.subline ? this.subline = deleteData.subline = deleteData.subline : this.team_name = ''
    deleteData.num_pitches ? this.total_pitches = deleteData.num_pitches = deleteData.num_pitches : this.total_pitches = 0
    deleteData.variant_name ? this.variantName = deleteData.variant_name: this.variantName = ''
    deleteData.version_name ? this.versionName = deleteData.version_name: this.versionName = ''

    let deletelist : DeletedetailsType = {
        pitch_id :this.pitchID,
        variant_count:this.variantcount,
        total_work:this.totalwork
         }
    this.deleteList.push(deletelist)

    let deleteTeamList : DeletedTeamType = {
      line :this.line,
      subline:this.subline,
      total_pitches:this.total_pitches
       }
  this.deleteTeamList.push(deleteTeamList)
    
    if(this.pitchFormType == 'deleteVariant')
      {
        this.deleteVariant = true;
      }
    else if (this.pitchFormType =='deleteRegion')
      {
        this.deleteRegion = true;
      }
    else if(this.pitchFormType == 'deletePitch')
      {
          this.deletePitch = true;
      }
    else if(this.pitchFormType == 'deleteTeam'){
      this.deleteTeam = true;
    }
    else if (this.pitchFormType == 'deleteTeamVariant'){
      this.deleteTeamVariant = true;
    }
    else if (this.pitchFormType == 'deleteVersion'){
      this.deleteVersion = true;
    }
    console.log(this.deleteVersion)
    console.log(this.deleteTeam)
    this.deleteFormGroup = this.deleteFormBuilder.group({
      form_deleteconfirmation: [(this.confirmationresponse == null)? '' : this.confirmationresponse, [Validators.required,CustomValidators.confirmation]]
    })

    this.deleteFormGroup.valueChanges.subscribe((formValues) =>{
      this.confirmationresponse =formValues.form_deleteconfirmation
    });

    this.deleteFormGroup.controls.form_deleteconfirmation.valueChanges.subscribe((value) => {
      if(value == "YES" ){
        this.confirmdelete = true;
      }else{
        this.confirmdelete = false;
      }
    });

    console.log(this.confirmdelete)

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  confirmDelete(){
    if(this.deleteFormGroup.status == 'VALID'){
      const deleteFormData = {
        action: 'delete'
      }
      console.log(this.confirmdelete)
      this.dialogRef.close(deleteFormData)
    } else {
      console.error('Invalid pitchFormGroup, was not caught in form control error handling.')
    }
  }  
}

export class CustomValidators {
  static confirmation: ValidatorFn = (formGroup: UntypedFormGroup) => {
    var isValid: Boolean = false;
    var confirmationvalue: string = formGroup.value;
    console.log("calling custom validator")
    console.log(confirmationvalue)
    if(confirmationvalue== "YES" ){
      
      isValid = true;
    }else{
      isValid = false;
      console.log("Confirmation should be typed in as 'YES' ")
    }
    console.log(isValid)

    return isValid ? null : { confirmation: true };
  }
}

export interface DeleteDialogData {
  team_name: string;
  version_name: string;
  pitch_config_id: string;
  pitch_id: string;
  pitchVariants: VariantType[];
  totalwork:number;
  can_delete: boolean;
  form_type: string;
  uncheckedvariants :string[];
  uncheckedvariantswork :number;
  region : string;
  subline :string;
  line :string;
  num_pitches : number;
  variant_name: string;

}
