import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription} from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { NavService } from '../services/nav.service';
import { TeamNode } from 'src/app/shared/models/type-list';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

@Component({
  selector: 'team-select',
  templateUrl: './team-select.component.html',
  styleUrls: ['./team-select.component.scss']
})

export class TeamSelect implements OnInit, OnDestroy {
  
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]);

  navSubscription: Subscription; 
  teamSubscription: Subscription;
  treeControl = new NestedTreeControl<TeamNode>(node => node.children);
  teamSource = new MatTreeNestedDataSource<TeamNode>();
  expandedNodes: TeamNode[] = [];

  PlantName = ''
  Line = ''
  subLine = ''
  display_name = ''
  constructor(
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
  ) {
  }

  hasChild = (_: number, node: TeamNode) => !!node.children && node.children.length > 0;
  
  ngOnInit(): void {
    this.getPlantList();
  }

  ngOnDestroy(): void {
    if (this.navSubscription) this.navSubscription.unsubscribe();
  }

  
  getPlantList(){
    let navObservable: Observable<TeamNode[]> = 
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => 
      this.navService.getNavList()), 
      pluck('Body'));
    if (this.navSubscription) this.navSubscription.unsubscribe();     
       
    this.navSubscription = navObservable.subscribe(
      (out) => {

        if (out !== undefined) {
          this.teamSource.data = out;
          if (this.route.snapshot.paramMap.get("plant_name") !== undefined && this.route.snapshot.paramMap.get("plant_name") !== null){
            this.expandTree("plant_name", out);
          }

          if(this.expandedNodes.length == 1 && this.route.snapshot.paramMap.get("line") !== undefined && this.route.snapshot.paramMap.get("line") !== null){
            this.expandTree("line", this.expandedNodes[0].children);
          }

          if(this.expandedNodes.length == 2 && this.route.snapshot.paramMap.get("subline") !== undefined && this.route.snapshot.paramMap.get("subline") !== null){
            this.expandTree("subline", this.expandedNodes[1].children);
          }

          if(this.expandedNodes.length == 3 && this.route.snapshot.paramMap.get("team") !== undefined && this.route.snapshot.paramMap.get("team") !== null){
            this.expandTree("team", this.expandedNodes[2].children);
          }
        }
      }
    );
  }
  
  navigate(plant_name: string, line: string, subline: string, team_id: number, version_id: number, display_name) {    
    if (subline == "") {
      this.router.navigate(["/teamwork", plant_name, line, team_id, version_id]);
    } else {
      this.router.navigate(["/teamwork", plant_name, line, subline, team_id, version_id]);
    }   
  }
  
  expandTree(param: string, nodes: TeamNode[]){
    if (this.route.snapshot.paramMap.get(param) !== undefined){
      var valid = false;
      var expandedNode: TeamNode;

      nodes.forEach(node => {
        var routeParam: string = this.route.snapshot.paramMap.get(param);
        console.log(routeParam)
        if(((node.search).toString()).toLowerCase() == routeParam.toLowerCase()){
          expandedNode = node;
          valid = true;
        }
      });

      if(valid){
        console.debug("Valid " + param + " parameter supplied, expanding " + this.route.snapshot.paramMap.get(param));
        this.treeControl.toggle(expandedNode);
        this.expandedNodes.push(expandedNode);
      }else{
        console.warn("Invalid " + param + " parameter provided");
      }
    }
  }

}
