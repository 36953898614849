   

<team-header *ngIf = "teamFormattedInfo"
    [plant_name] = "plant_name"
    [team_info] = "teamFormattedInfo"
    [save_status] = "'false'"
    [hide_buttons] = "'true'"
    [multi_team]  = "'true'"
    (changedVersion) = "onChangeVersion($event)">
</team-header>

<div *ngIf="teamFormattedInfo" cdkDropList id = "{{teamFormattedInfo.team_name}}" [cdkDropListData] = "workstationIDArray" [cdkDropListConnectedTo] = "workstationParentListArray">
    <div class="fx-row" id = "print-double-section">
        <div *ngFor = "let workstation of pitchesDisplayed">
            <div class="fx-column">
                <div>
                    <div *ngIf = "workstation['odd'] === undefined || (workstation['odd'] | json) === '{}'" class="empty-container" [ngStyle]="{'min-height': emptyMinHeight}">
                    </div>
                    <div *ngIf = "workstation['odd'] !== undefined && (workstation['odd'] | json) !== '{}'" style="page-break-after:auto;page-break-inside:avoid;" class="model-variant-container fx-column" [ngStyle]="{'height': containerMinHeight,'width': workstation['odd'] != undefined ? workstation['odd'].minimumwidth : '230px'} ">
                        <pitch
                            [pitch] = "workstation['odd']"
                            [viewOnly] = "'true'"
                            [multi_team] = "multi_team"
                            [plantName] = "plant_name"
                            [team_id] = "teamFormattedInfo.team_id"
                            [team_name] = "teamFormattedInfo.team_name"
                            [version_id] = "teamFormattedInfo.version_id"
                            [version_name] = "teamFormattedInfo.version_name"
                            [canDelete] = "'false'"
                            [minHeight] = "minHeight"
                            [side] = "'odd'"
                            [workstationParentListArray] = "workstationParentListArray"
                            [workstationPlaceholderArray] = "workstationPlaceholderArray"
                            [workstationFluctuationArray] = "workstationFluctuationArray"
                            [workstationPeriodicArray] = "workstationPeriodicArray"
                            [workstationCycleWasteArray] = "workstationCycleWasteArray"
                            [multiplierValue] = "multiplierValue"
                            [ergoEnabled] = "'false'"
                            [timeUnit] = "teamFormattedInfo.takt_time_unit"
                            (collapsedPeriodic) = "calculateWorkMinAfterCollapse(workstation['odd'])"
                            (expandedPeriodic) = "calculateWorkMinAfterExpand(workstation['odd'])">
                        </pitch>
                    </div>
                </div>
                <br>
                <div>
                    <div *ngIf ="workstation['even'] !== undefined && (workstation['even'] | json) !== '{}'" style="page-break-after:auto;page-break-inside:avoid;" class="model-variant-container fx-column" [ngStyle]="{'height': containerMinHeight,'width': workstation['even'] != undefined ? workstation['even'].minimumwidth : '230px'}">
                        <pitch
                            [pitch] = "workstation['even']"
                            [viewOnly] = "'true'"
                            [multi_team] = "multi_team"
                            [plantName] = "plant_name"
                            [team_id] = "teamFormattedInfo.team_id"
                            [team_name] = "teamFormattedInfo.team_name"
                            [version_id] = "teamFormattedInfo.version_id"
                            [version_name] = "teamFormattedInfo.version_name"
                            [canDelete] = "'false'"
                            [minHeight] = "minHeight"
                            [side] = "'even'"
                            [workstationParentListArray] = "workstationParentListArray"
                            [workstationPlaceholderArray] = "workstationPlaceholderArray"
                            [workstationFluctuationArray] = "workstationFluctuationArray"
                            [workstationPeriodicArray] = "workstationPeriodicArray"
                            [workstationCycleWasteArray] = "workstationCycleWasteArray"
                            [multiplierValue] = "multiplierValue"
                            [watchDrop] = "watchDrop"
                            [ergoEnabled] = "'false'"
                            (collapsedPeriodic) = "calculateWorkMinAfterCollapse(workstation['even'])"
                            (expandedPeriodic) = "calculateWorkMinAfterExpand(workstation['even'])">
                        </pitch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

