<br><br>
<div>
    <mat-toolbar color="primary" class="tableTitleBar" >
      <button mat-button color = "white" (click) = "goBackToTeamList()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Variant List: {{plantDisplayName}} - {{display_line}} - {{team}}</span>
      <span class="toolbar-spacer"></span>
      <span><button mat-raised-button color="link" (click)="createVariant()">Create New Variant</button></span>
    </mat-toolbar>
      <!--<div *ngIf="fullSizeMatcher.matches; else cardDisplay"><-->
          <mat-table [dataSource]="variantList">
              <ng-container matColumnDef="variant_id">
                  <mat-header-cell *matHeaderCellDef>Variant ID</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.variant_id}}</mat-cell>
                </ng-container>    
              <ng-container matColumnDef="variant_name">
                <mat-header-cell *matHeaderCellDef>Variant Name</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.variant_name}}</mat-cell>
              </ng-container>
          
      

      
              <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                  <mat-cell *matCellDef="let d">
                    <button mat-button color="primary" (click) = "updateVariant(d)"><mat-icon>create</mat-icon>Edit</button>
                    <button mat-button color="primary" (click) = "deleteVariant(d)"><mat-icon>delete_outline</mat-icon>Delete</button>
                  </mat-cell>
                </ng-container>  
      
              <mat-header-row *matHeaderRowDef="displayedVariantColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedVariantColumns;"></mat-row>
            </mat-table>
  </div>
