<div *ngIf="side == 'odd'" >
    <div class="fx-row-end-center">
        <button style="max-height:30px; padding-bottom: 10px;" mat-icon-button color="primary" (click) = "!viewOnly && createWork()" [disabled]="pitch_status === 'blocked'"><mat-icon *ngIf="!multi_team"  >add_box</mat-icon></button>
    </div>
    <div *ngIf="variant !== undefined" class="{{pitch_status + '-container'}}" >
        
        <div cdkDropList id = "{{pitch_config_id + '-' + variant.variant_id}}" [cdkDropListConnectedTo] = "workstationParentListArray" class = "{{pitch_status + '-list-odd'}} fx-column-end" [ngStyle]="{'height': minHeight}">  
            <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-placeholder'}}" [cdkDropListData]="variant.work.placeholder" [cdkDropListConnectedTo] = "workstationPlaceholderArray" [ngStyle]="{'height': placeholderHeight}" (cdkDropListDropped)="drop($event)">
            </div>

            <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-fluctuation'}}" [cdkDropListData]="variant.work.fluctuation" [cdkDropListConnectedTo] = "workstationFluctuationArray" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let work of variant.work.fluctuation">
                    <work
                        [multiplier_value] = "multiplierValue"
                        [pitch_config_id] = "pitch_config_id"
                        [pitch_id] = "pitch_id"
                        [pitch_status] = "pitch_status"
                        [plant_name] = "plantName"
                        [subassembly_id] = "subassembly_id"
                        [team_id] = "team_id"
                        [variant] = "variant"
                        [version_id] = "version_id"
                        [work] = "work"
                        [viewOnly] = "viewOnly"
                        [timeUnit] = "timeUnit"
                        (reload) = "onReload()"
                        (savedWork) = "saveWork()"
                        [multi_team] = "multi_team">
                    </work>
                </div>
            </div>
            
            <mat-expansion-panel *ngIf = "containsPeriodicWork() && periodicCount > 1" (opened)="calculateVariantHeight('open')" (closed)="calculateVariantHeight('close')" (afterCollapse) = "calculateAfterCollapse()" (afterExpand) = "calculateAfterExpand()" (destroyed) = "calculateAfterCollapse()" [expanded] = "panelOpenState">
                <mat-expansion-panel-header class="periodic-box" [collapsedHeight]="getMaxPeriodicHeight()" [expandedHeight]="getExpandedHeight()">
                    <mat-panel-title class="fx-row-space-around-center">
                        <span class="periodic-badge">
                            {{periodicCount}}
                        </span>
                        <span>
                            &nbsp;{{getMaxTimePeriodicWork().description}}
                        </span>
                        <span class="time-to-complete" *ngIf="!panelOpenState">     
                            {{getMaxTimePeriodicWork().time_to_complete}}{{timeUnit}}
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>        

                <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-periodic'}}" [ngStyle]="{'height': expandedHeight}" [cdkDropListData]="variant.work.periodic" [cdkDropListConnectedTo] = "workstationPeriodicArray" (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let work of variant.work.periodic">
                        <work
                            [multiplier_value] = "multiplierValue"
                            [pitch_config_id] = "pitch_config_id"
                            [pitch_id] = "pitch_id"
                            [pitch_status] = "pitch_status"
                            [plant_name] = "plantName"
                            [team_id] = "team_id"
                            [subassembly_id] = "subassembly_id"
                            [variant] = "variant"
                            [version_id] = "version_id"
                            [work] = "work"
                            [viewOnly] = "viewOnly"
                            (reload) = "onReload()"
                            (savedWork) = "saveWork()"
                            [multi_team] = "multi_team">
                        </work>
                    </div>
                </div>
            </mat-expansion-panel>

            <div *ngIf = "periodicCount == 1" cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-periodic'}}" [cdkDropListData]="variant.work.periodic" [cdkDropListConnectedTo] = "workstationPeriodicArray" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let work of variant.work.periodic">
                    <work
                        [multiplier_value] = "multiplierValue"
                        [pitch_config_id] = "pitch_config_id"
                        [pitch_id] = "pitch_id"
                        [pitch_status] = "pitch_status"
                        [plant_name] = "plantName"
                        [team_id] = "team_id"
                        [subassembly_id] = "subassembly_id"
                        [variant] = "variant"
                        [version_id] = "version_id"
                        [work] = "work"
                        [viewOnly] = "viewOnly"
                        (reload) = "onReload()"
                        (savedWork) = "saveWork()"
                        [multi_team] = "multi_team">
                    </work>
                </div>
            </div>

            <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-cycle'}}" [cdkDropListData]="variant.work.cycle" [cdkDropListConnectedTo] = "workstationCycleWasteArray" style="display: flex;flex-direction: column-reverse;" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let work of variant.work.cycle">
                    <work
                        [multiplier_value] = "multiplierValue"
                        [pitch_config_id] = "pitch_config_id"
                        [pitch_id] = "pitch_id"
                        [pitch_status] = "pitch_status"
                        [plant_name] = "plantName"
                        [team_id] = "team_id"
                        [subassembly_id] = "subassembly_id"
                        [variant] = "variant"
                        [version_id] = "version_id"
                        [work] = "work"
                        [viewOnly] = "viewOnly"
                        (reload) = "onReload()"
                        (savedWork) = "saveWork()"
                        [multi_team] = "multi_team">
                    </work>
                </div>
            </div>
        </div>

        <hr class="updated-line" [ngStyle]="{'bottom':calculateTaktLinePosition()}">
        <dt  class="container-header">
            <button color="graybutton" mat-raised-button id="variant" class="btn center blue mobile-btn" type="button" label="Variant">
                {{variant.variant_name}}
            </button>    
        </dt>
        <div *ngIf="pitch_status == 'active'" class="metric-panel">
            <mat-progress-bar [matTooltip]="'Cycle Time: ' + calculateCycleTime()" class= "progress-bar" mode="determinate" value="{{calculateProgress()}}"></mat-progress-bar>
        </div>
        <div *ngIf="pitch_status != 'active'" class="metric-panel-hidden">
            <mat-progress-bar class= "progress-bar" mode="determinate" value="{{calculateProgress()}}">
            </mat-progress-bar>  
        </div>
    </div>
</div>
<div *ngIf="side == 'even'" >
    <div *ngIf="variant !== undefined" class="{{pitch_status + '-container'}}">
        <div *ngIf="pitch_status == 'active'" class="metric-panel">
            <mat-progress-bar [matTooltip]="'Cycle Time: ' + calculateCycleTime()" class= "progress-bar" mode="determinate" value="{{calculateProgress()}}"></mat-progress-bar>  
        </div>
        <div *ngIf="pitch_status != 'active'" class="metric-panel-hidden">
            <mat-progress-bar class= "progress-bar" mode="determinate" value="{{calculateProgress()}}"></mat-progress-bar>  
        </div>
        <dt  class="container-header">
            <button color="graybutton" mat-raised-button id="variant" class="btn center blue mobile-btn" type="button" label="Variant">
                {{variant.variant_name}}    
            </button>    
        </dt>
        <div class="fx-row-end">
            <button mat-icon-button color="primary" style="max-height:30px;" (click) = "!viewOnly && createWork()" [disabled]="pitch_status === 'blocked'">
                <mat-icon *ngIf="!multi_team" >
                    add_box
                </mat-icon>
            </button>
        </div>
        <hr class="updated-line" [ngStyle]="{'bottom':calculateTaktLinePosition(takt_time, 'lower')}">
        <div cdkDropList id = "{{pitch_config_id +'-'+variant.variant_id}}" [cdkDropListConnectedTo] = "workstationParentListArray" class = "{{pitch_status + '-list-even'}} fx-column-start" [ngStyle]="{'height': minHeight}">
            
            <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-cycle'}}" [cdkDropListData]="variant.work.cycle" [cdkDropListConnectedTo] = "workstationCycleWasteArray" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let work of variant.work.cycle">
                    <work
                        [multiplier_value] = "multiplierValue"
                        [pitch_config_id] = "pitch_config_id"
                        [pitch_id] = "pitch_id"
                        [pitch_status] = "pitch_status"
                        [plant_name] = "plantName"
                        [team_id] = "team_id"
                        [subassembly_id] = "subassembly_id"
                        [variant] = "variant"
                        [version_id] = "version_id"
                        [work] = "work"
                        [viewOnly] = "viewOnly"
                        (reload) = "onReload()"
                        (savedWork) = "saveWork()"
                        [multi_team] = "multi_team">
                    </work>
                </div>
            </div>

            <mat-expansion-panel *ngIf = "containsPeriodicWork() && periodicCount > 1" (opened)="calculateVariantHeight('open')" (closed)="calculateVariantHeight('close')" (afterCollapse) = "calculateAfterCollapse()" (afterExpand) = "calculateAfterExpand()" (destroyed) = "calculateAfterCollapse()" [expanded] = "panelOpenState">
                <mat-expansion-panel-header class="periodic-box" [collapsedHeight]="getMaxPeriodicHeight()" [expandedHeight]="getExpandedHeight()">
                    <mat-panel-title class="fx-row-space-around-center">
                        <span class="periodic-badge">
                            {{periodicCount}}
                        </span>
                        <span>
                            &nbsp;{{getMaxTimePeriodicWork().description}}
                        </span>
                        <span class="time-to-complete" *ngIf="!panelOpenState">     
                            {{getMaxTimePeriodicWork().time_to_complete}}{{timeUnit}}
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>        

                <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-periodic'}}" [ngStyle]="{'height': expandedHeight}" [cdkDropListData]="variant.work.periodic" [cdkDropListConnectedTo] = "workstationPeriodicArray" (cdkDropListDropped)="drop($event)">
                    <div *ngFor="let work of variant.work.periodic">
                        <work
                            [multiplier_value] = "multiplierValue"
                            [pitch_config_id] = "pitch_config_id"
                            [pitch_id] = "pitch_id"
                            [pitch_status] = "pitch_status"
                            [plant_name] = "plantName"
                            [team_id] = "team_id"
                            [subassembly_id] = "subassembly_id"
                            [variant] = "variant"
                            [version_id] = "version_id"
                            [work] = "work"
                            [viewOnly] = "viewOnly"
                            (reload) = "onReload()"
                            (savedWork) = "saveWork()"
                            [multi_team] = "multi_team">
                        </work>
                    </div>
                </div>
            </mat-expansion-panel>

            <div *ngIf = "containsPeriodicWork() && periodicCount == 1" cdkDropList id="{{pitch_config_id +'-'+variant.variant_id+ '-periodic'}}" [cdkDropListData]="variant.work.periodic" [cdkDropListConnectedTo] = "workstationPeriodicArray" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let work of variant.work.periodic">
                    <work
                        [multiplier_value] = "multiplierValue"
                        [pitch_config_id] = "pitch_config_id"
                        [pitch_id] = "pitch_id"
                        [pitch_status] = "pitch_status"
                        [plant_name] = "plantName"
                        [team_id] = "team_id"
                        [subassembly_id] = "subassembly_id"
                        [variant] = "variant"
                        [version_id] = "version_id"
                        [work] = "work"
                        [viewOnly] = "viewOnly"
                        (reload) = "onReload()"
                        (savedWork) = "saveWork()"
                        [multi_team] = "multi_team">
                    </work>
                </div>
            </div>

            <div cdkDropList id="{{pitch_config_id +'-'+variant.variant_id + '-fluctuation'}}" [cdkDropListData]="variant.work.fluctuation" [cdkDropListConnectedTo] = "workstationFluctuationArray" (cdkDropListDropped)="drop($event)">
                <div *ngFor = "let work of variant.work.fluctuation">
                    <work
                        [multiplier_value] = "multiplierValue"
                        [pitch_config_id] = "pitch_config_id"
                        [pitch_id] = "pitch_id"
                        [pitch_status] = "pitch_status"
                        [plant_name] = "plantName"
                        [subassembly_id] = "subassembly_id"
                        [team_id] = "team_id"
                        [variant] = "variant"
                        [version_id] = "version_id"
                        [work] = "work"
                        [viewOnly] = "viewOnly"
                        (reload) = "onReload()"
                        (savedWork) = "saveWork()"
                        [multi_team] = "multi_team">
                    </work>
                </div>                          
            </div>

            <div cdkDropList id="{{pitch_config_id + '-'+variant.variant_id + '-placeholder'}}"  [cdkDropListData]="variant.work.placeholder" [cdkDropListConnectedTo] = "workstationPlaceholderArray" [ngStyle]="{'height':placeholderHeight}" (cdkDropListDropped)="drop($event)">
            </div>
        </div>
    </div>
</div>