<h3 mat-dialog-title>{{displayTeamName}} - {{regionFormTitle}}</h3>
<div mat-dialog-content>
  <form [formGroup]="regionFormGroup">

   <mat-form-field class="card-dialog">
      <input id="region_name" type="text" matInput cdkTextareaAutosize placeholder="Region Name" formControlName="form_regionName"
        required>
    </mat-form-field>
    <div *ngIf="regionFormGroup.get('form_regionName').invalid && 
    regionFormGroup.get('form_regionName').errors && 
            (regionFormGroup.get('form_regionName').dirty || regionFormGroup.get('form_regionName').touched)">
      <small class="error" *ngIf="regionFormGroup.get('form_regionName').hasError('required')"> Region is Required </small>
   </div> 

    <mat-form-field class="card-dialog">
        <mat-select id = "check_color" matInput cdkTextareaAutosize placeholder = "Colors" formControlName="form_Color">
          <mat-select-trigger><mat-icon [ngStyle]="{'color': getColorName()}">lens</mat-icon>{{currentColorName}}</mat-select-trigger>     
            <mat-option *ngFor="let region_color of showregionColors" [value]="region_color.color">
              <mat-icon [ngStyle]="{'color': region_color.color}">lens</mat-icon>{{region_color.color_name}}
            </mat-option>
          </mat-select>
    </mat-form-field>
    
 </form>
</div>

<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button *ngIf="regionFormType == 'create'" [disabled]="regionFormGroup.status === 'INVALID'" mat-raised-button
    color="primary" (click)="createRegion()" >Create</button>
  <button *ngIf="regionFormType == 'update'" mat-raised-button color="primary" (click)="updateRegion()">Update</button>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>

