import {Component,Input,OnChanges} from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {CdkDragDrop, DragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { PlantType, Navigation, LineTeam, SelectTeam, Version } from '../../shared/models/type-list';
import { TeamService } from 'src/app/team-edit/services/team.service';
import { Observable, Subscription} from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';


@Component({
  selector: 'view-select',
  templateUrl: './view-select.component.html',
  styleUrls: ['./view-select.component.scss']
})
export class ViewSelectComponent  implements OnChanges {
  currentNav: Navigation = {
    plant_name: '',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  }

  @Input()
    plantname ="" ;  
  @Input()
   line  = "";
  @Input()
   subline = "";
  teamSubscription: Subscription;
  teamlist : SelectTeam[] = [];
  done : SelectTeam[] = []; 
  orgTeamList: SelectTeam[] = []
  teamstosave : boolean = false
  
  @Input()
  multi_team = false;
  constructor( 
    private route: ActivatedRoute,
    private router: Router,
    private teamService: TeamService) {

   }

  ngOnChanges(): void {
    console.log(' The value of done array ',this.done)
    if (this.done.length > 1){
      console.log(' Setting team to save ')
      this.teamstosave = true
    }

    this.getTeamList() 
  }
  
  getTeamList(){
    let teamObservable: Observable<SelectTeam[]> = 
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.teamService.getTeamsWithVersions(this.plantname, this.line, this.subline, 'true','true')),
        pluck('Body'));
 
    if (this.teamSubscription) this.teamSubscription.unsubscribe();

    this.teamSubscription = teamObservable.subscribe((out) => {
      this.teamlist = out
      this.orgTeamList = out
      console.log(this.teamlist)
      console.log(this.orgTeamList)

    })
 
  }
 

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }


  viewTeams(){
    console.log(' Submit Teams to Multi-View ', this.done)
    let selectedTeams = JSON.stringify(this.done)
    this.router.navigate(['/multiteamwork'], { queryParams: { teamlist: selectedTeams}})
  }

  clearDone(){
    this.teamlist = [];
    this.done = [];
    this.getTeamList();
  }

  disableTeam(team: SelectTeam){
    let teamSelected = this.done.filter(sTeam => sTeam.team_id === team.team_id)
    return teamSelected.length > 0;
  }
  ngOnDestroy(): void {
    if (this.teamSubscription) this.teamSubscription.unsubscribe();
  }

}



