import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TeamService } from './services/team.service';
import { LineTeam } from 'src/app/shared/models/type-list';
import { Router } from '@angular/router';


@Component({
  selector: 'app-team-dialog',
  templateUrl: './team-dialog.component.html',
  styleUrls: ['./team-dialog.component.scss']
})
export class TeamDialog implements OnInit{
  LineOptions: string[];
  plantName: string;
  displayPlantName: string;
  line: string;
  subline: string;
  teamID: number;
  teamName: string;
  numPitches: number;
  twoSided: string;
  taktTime: number;
  active: string;
  lineOptions: string[];
  filteredLines: Observable<string[]>
  timeUnit: string
  timeUnitList: string[] = ["Minutes", "Seconds"]
  plantList: string[];
  plantNameSubscription: Subscription;
  TeamIDOptions: string[];
  TeamIDAvailable: string[];
  

  teamFormGroup: UntypedFormGroup;
  teamFormType: string;
  teamFormTitle: string;
  loading: boolean = false;
  unique: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<TeamDialog>,
    @Inject(MAT_DIALOG_DATA) public teamData: TeamDialogData,
    private teamFormBuilder: UntypedFormBuilder,
    private teamService: TeamService,
    private router: Router
  ) 
  { 
    this.plantNameSubscription = this.teamService.plantList().subscribe(
      out=>{
        this.plantList = out.Body.map(plant => plant.name)
    })


    console.info(teamData)
    teamData.team_id? this.teamID = teamData.team_id : this.teamID = 0
    teamData.plant_name? this.plantName = teamData.plant_name : this.plantName = ''
    teamData.plant_display_name? this.displayPlantName = teamData.plant_display_name : this.displayPlantName = ''
    
    this.TeamIDOptions = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    
    teamData.line? this.line = teamData.line : this.line = ''
    teamData.subline? this.subline = teamData.subline : this.subline = ''
    teamData.team_name? this.teamName = teamData.team_name : this.teamName = ''
    teamData.line_teams? this.lineOptions = teamData.line_teams.map(line => line.line).filter((value, index, self) => self.indexOf(value)===index) : this.lineOptions = []
    teamData.form_type ? this.teamFormType = teamData.form_type : this.teamFormType = 'create'
    teamData.takt_time_unit? this.timeUnit = teamData.takt_time_unit : this.timeUnit = 's'

    this.teamFormGroup = this.teamFormBuilder.group({
      form_plant: (this.plantName == null)? '': this.plantName,     
      form_line: [(this.line == null)? '': this.line, Validators.required],
      form_subline: (this.subline == null)? '': this.subline,
      form_teamName: [(this.teamName == null)? '': this.teamName, [Validators.required]], 
      form_timeUnit: (this.timeUnit == 'm')? 'Minutes': 'Seconds'
    })
    
    this.teamFormGroup.valueChanges.subscribe((formValues) =>{
      this.plantName = formValues.form_plant,
      this.line = formValues.form_line,
      this.subline = formValues.form_subline,
      this.teamName = formValues.form_teamName
      this.timeUnit = (formValues.form_timeUnit == 'Minutes') ? 'm' : 's'
    })
    if (this.teamFormType == 'update'){
      this.teamFormTitle = 'Edit Team'
    }
    else{
      this.teamFormTitle = 'Create New Team'
      this.filteredLines = this.teamFormGroup.controls['form_line'].valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value, this.line))
      );
    }
  }

  ngOnInit(){
    this.nextteamid()
  }

  nextteamid(){
    this.TeamIDAvailable = this.TeamIDOptions

    for (let i= 0 ; i < this.TeamIDOptions.length; i++ ){
      for (let j = 0 ; j < this.teamData.line_teams.length ; j++){
        if (this.teamData.line_teams[j].team_name == this.TeamIDOptions[i]){
          this.TeamIDAvailable.forEach( (item, index) => {
            if(item === this.TeamIDOptions[i] && this.TeamIDOptions[i] != this.teamName) this.TeamIDAvailable.splice(index,1);
          });
        }
      }
    } 
    if (this.teamName == ''){
      this.teamFormGroup.controls['form_teamName'].setValue(this.TeamIDAvailable[0])
    }
    else  
      this.teamFormGroup.controls['form_teamName'].setValue(this.teamName)
   
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createTeam(): void {
    this.loading = true
    this.unique = this.isTeamNameUnique()

    if (this.teamFormGroup.status == 'VALID' && this.unique) {
      this.teamService.createTeam(this.plantName, this.line, this.teamName, this.subline, this.timeUnit).subscribe(out => {
        let newTeamID = out.Body.TEAM_ID
        if (newTeamID != '' && newTeamID != undefined){
          console.log(newTeamID)
          this.teamService.team_id$.next(newTeamID)
          this.teamService.createVariant(newTeamID, 'Base', 1, this.plantName).subscribe(out => {
            this.dialogRef.close()  
            this.loading = false
          })
        } else {
          this.dialogRef.close()
          this.loading = false
        }
        this.router.navigate(["/teamedit", this.plantName, this.line, this.subline]);
      })
    } else if (!this.unique) {
      this.loading = false
    } else {
      console.error('Invalid teamFormGroup, was not caught in form control error handling.')
    }
  }

  updateTeam(){
    this.loading = true
    this.unique = this.isTeamNameUnique()
    if(this.teamFormGroup.status == 'VALID' && this.unique)
      this.teamService.updateTeam(this.teamID, this.plantName, this.line, this.teamName, 'false', this.subline, this.timeUnit).subscribe(out => {
        this.dialogRef.close()
        this.loading = false
      })
    else if (!this.unique)
      this.loading = false
    else
      console.error('Invalid teamFormGroup, was not caught in form control error handling.')
  }

  displayLine():string{
    return this.line
  }

  normalizeString(s: string): string {
    return s.replace(/\s+/g, '').toLowerCase()
  }

  isTeamNameUnique(): boolean {
    var filteredTeams = this.teamData.line_teams
    filteredTeams = filteredTeams.filter(t => this.normalizeString(t.line) === this.normalizeString(this.line))
      .filter(t => this.normalizeString(t.team_name) === this.normalizeString(this.teamName))

    if (this.subline.length > 0) {
      filteredTeams = filteredTeams.filter(t => this.normalizeString(t.subline) === this.normalizeString(this.subline))
    }

    if (this.timeUnit.length > 0) {
      filteredTeams = filteredTeams.filter(t => t.takt_time_unit === this.timeUnit)
    }

    return (filteredTeams.length == 0)
  }

  private _filter(value: any, initLine:string): string[] {
    if (value != initLine){
      value = initLine
    }
    
    const filterValue = value.toLowerCase();
    return this.lineOptions.filter(function (option)
    {
      return option.toLowerCase().includes(filterValue)
    });

  }

}
  
export interface TeamDialogData {
  plant_name: string;
  plant_display_name: string;
  line: string;
  subline: string;
  team_id: number;
  team_name: string;
  line_teams: LineTeam[];
  form_type: string;
  takt_time_unit: string;
}