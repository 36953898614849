import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PitchDialog } from 'src/app/team-edit/pitch/pitch-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WorkstationFormatted, VariantType, PitchType, ServiceTie } from 'src/app/shared/models/type-list'
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { TeamService } from 'src/app/team-edit/services/team.service';
import { Observable, Subscription} from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ErgoColors } from 'src/app/shared/models/enum-list';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { first } from 'rxjs/operators'
import { ErgoDialog } from 'src/app/team-edit/pitch/ergo-dialog.component';

@Component({
  selector: 'pitch',
  templateUrl: './pitch.component.html',
  styleUrls: ['./pitch.component.scss']
})
export class Pitch implements OnChanges {

  @Input()  
    pitch: WorkstationFormatted = {
      pitch_config_id: 0,
      pitch_id: '',
      status: '',
      type: '',
      subassembly_id: '',
      takt_time: 0,
      variant: [],
      flags: [],
      isexpanded: false,
      expandedhgt: 0,
      minimumwidth : 0,
      pitch_name: '',
      ergo_score: -1,
      ergo_comments: '',
      service_attribute:'',
      service_color:'',
      pitch_ties:[],
      service_pitches: []
    }

  @Input()
    viewOnly = false; 
  @Input()
    multi_team = false;
  @Input()
    plantName = "";  
  @Input()
    team_id = 0;
  @Input()
    team_name = "";
  @Input()
    version_id = 0;
  @Input()
    version_name = "";
  @Input()
    standardwork_status = "";
  @Input()
    canDelete = false;
  @Input()
    minHeight = "0px";
  @Input()
    side = "";
    @Input()
    workstationParentListArray = [];
  @Input()
    workstationPlaceholderArray = [];
  @Input()
    workstationFluctuationArray = [];
  @Input()
    workstationPeriodicArray = [];
  @Input()
    workstationCycleWasteArray = [];
  @Input()
    multiplierValue = 15;
  @Input()
    taktTime = 0;
  @Input()
    ergoEnabled = false;
  @Input()
    timeUnit = "s";
  
  @Output() savedWork = new EventEmitter();
  @Output() reload = new EventEmitter();
  @Output() dropped = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() collapsedPeriodic = new EventEmitter<WorkstationFormatted>();
  @Output() expandedPeriodic = new EventEmitter<WorkstationFormatted>();
  @Output() loadAvailableRegions = new EventEmitter();

  pitchSubscription: Subscription;
  pitchList: PitchType[] = [];
  displayErgoScore: Boolean = false;
  displayErgoSpace: Boolean = false;
  ergoAdminFlag: boolean;
  serviceTies: ServiceTie[] = []

  ergoAdminSub$: Subscription;
  
  constructor(
    private pitchDialog: MatDialog,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private geaMesCognito: GeaMesCognitoAuthService
  ) { }

  ngOnInit() {
    // Check to see if this user is an ergo admin
    this.ergoAdminSub$ = this.geaMesCognito.isUserInRole("Admin").pipe(first()).subscribe( out => { 
      this.ergoAdminFlag = out;
      if (this.ergoAdminSub$) this.ergoAdminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
  }

  ngOnChanges(): void {
    this.getPitchList();
    this.setDisplayErgoScore();
    this.getServiceTies();
  }

  onBlockToggle(event: any){
    this.saveWork()
    let variantIDs: number[] = [];
  
    this.pitch.variant.forEach(variant=> {
      variantIDs.push(variant.variant_id)
    })

    let pitch_status: string
    let subassembly_id: string
    
    if (event.checked){
      pitch_status = 'blocked'
      this.pitch.status = 'blocked'
    }else{
      pitch_status = 'open'
      this.pitch.status = 'open'
    }

    if (this.pitch.subassembly_id == undefined)
      subassembly_id = ""      
    else
      subassembly_id = this.pitch.subassembly_id
    this.teamService.updatePitch(this.plantName, this.team_id, this.version_id, this.pitch.pitch_config_id, this.pitch.pitch_id, this.pitch.type, subassembly_id, this.pitch.takt_time, pitch_status, variantIDs, this.pitch.pitch_name, this.pitch.ergo_score,this.pitch.ergo_comments).subscribe(
      out => {})
  }

  updatePitch(){
    this.saveWork()
    let pitchVariants: VariantType[] = []
    let totalwork = 0
    console.log('Team ID: ', this.team_id)
    //Gets variants that exist for the pitch and accumulates work for each variant for information needed to delete
    this.pitch.variant.forEach(variant=> {
      let pitchVariant: VariantType = 
      {
        "team_id": this.team_id,
        "variant_name": variant.variant_name,
        "variant_id": variant.variant_id,
        "sort_order": 0,
        "total_work": variant.work.cycle.length + variant.work.periodic.length + variant.work.fluctuation.length
      } 
      pitchVariants.push(pitchVariant)
      totalwork = totalwork + pitchVariant.total_work
    })
    console.log('Pitch Variants: ', pitchVariants)
    const dialogRef = this.pitchDialog.open(PitchDialog, {
      width: '500px',
      data: 
      {
        plant_name: this.plantName,
        team_id: this.team_id,
        team_name: this.team_name,
        version_id: this.version_id,
        version_name: this.version_name,
        pitch_config_id: this.pitch.pitch_config_id,
        pitch_id: this.pitch.pitch_id,
        type: this.pitch.type,
        subassembly_id: this.pitch.subassembly_id,
        takt_time: this.pitch.takt_time,
        pitchVariants: pitchVariants,
        flags: this.pitch.flags,
        can_delete: this.canDelete,
        form_type: 'Edit Pitch',
        totalwork: totalwork,
        status: this.pitch.status,
        pitch_name: this.pitch.pitch_name,
        pitchList: this.pitchList,
        service_ties: this.serviceTies,
        service_attribute: this.pitch.service_attribute,
        service_color: this.pitch.service_color     
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.onReload()
    });
  }

  updateErgoScore(){
    this.saveWork()
    let pitchVariants: VariantType[] = []
    let totalwork = 0
    //Gets variants that exist for the pitch and accumulates work for each variant for information needed to delete
    this.pitch.variant.forEach(variant=> {
      let pitchVariant: VariantType = 
      {
        "team_id": this.team_id,
        "variant_name": variant.variant_name,
        "variant_id": variant.variant_id,
        "sort_order": 0,
        "total_work": variant.work.cycle.length + variant.work.periodic.length + variant.work.fluctuation.length
      } 
      pitchVariants.push(pitchVariant)
      totalwork = totalwork + pitchVariant.total_work
    })

    let serviceTies: ServiceTie[] = []
    this.pitch.pitch_ties.forEach(pitch =>{
      let serviceTie: ServiceTie = {
        pitch_config_id: pitch.pitch_config_id,
        pitch_id: pitch.pitch_id
      }
      serviceTies.push(serviceTie)
    })
    console.log('Service Ties: ', serviceTies)
    
    const dialogRef = this.pitchDialog.open(ErgoDialog, {
      width: '500px',
      data: 
      {
        plant_name: this.plantName,
        team_id: this.team_id,
        team_name: this.team_name,
        version_id: this.version_id,
        version_name: this.version_name,
        pitch_config_id: this.pitch.pitch_config_id,
        pitch_id: this.pitch.pitch_id,
        type: this.pitch.type,
        subassembly_id: this.pitch.subassembly_id,
        takt_time: this.pitch.takt_time,
        pitchVariants: pitchVariants,
        flags: this.pitch.flags,
        can_delete: this.canDelete,
        form_type: 'Edit Ergo Score',
        totalwork: totalwork,
        status: this.pitch.status,
        pitch_name: this.pitch.pitch_name,
        pitchList: this.pitchList,
        ergo_score: this.pitch.ergo_score,
        ergo_comments: this.pitch.ergo_comments,
        service_ties: this.serviceTies,
        service_attribute: this.pitch.service_attribute,
        service_color: this.pitch.service_color
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.onReload()
    });
  }

  onReload(){
    this.reload.emit()
  }

  onDrop(event: CdkDragDrop<string[]>){
    this.dropped.emit(event)
  }

  onCollapsePeriodic(){
    this.collapsedPeriodic.emit(this.pitch)
  }

  onExpandPeriodic(){
    this.expandedPeriodic.emit(this.pitch)
  }

  countPeriodicWork(variant): number{
    return variant.work.periodic.length
  }

  saveWork(){
    this.savedWork.emit()
  }

  onLoadAvailableRegions(){
    this.loadAvailableRegions.emit()
  }

  getPitchList() {
    let pitchObservable: Observable<PitchType[]> = this.route.paramMap.pipe(switchMap((params: ParamMap) =>
       this.teamService.getPitches(this.team_id, this.version_id)),
       pluck('Body'));

    if (this.pitchSubscription) this.pitchSubscription.unsubscribe();     

    this.pitchSubscription = pitchObservable.subscribe((out) => {
      this.pitchList = out
    });
  }

  getErgoScoreCategory(ergo_score: number) {
    let ergoCategory: String;
    if (ergo_score >= 0 && ergo_score <= 19) {
      ergoCategory = ErgoColors.Green
    } else if (ergo_score >= 20 && ergo_score <= 29) {
      ergoCategory = ErgoColors.Yellow
    } else if (ergo_score > 29) {
      ergoCategory = ErgoColors.Red
    } else {
      ergoCategory = ErgoColors.Gray
    }
    return ergoCategory;
  }

  setDisplayErgoScore() {
    let workCount = 0;
    for (var variant of this.pitch.variant) {
      workCount += variant.work.cycle.length + variant.work.periodic.length + variant.work.fluctuation.length
    }
    this.displayErgoScore = workCount > 0 && this.ergoEnabled;
    this.displayErgoSpace = workCount == 0 && this.ergoEnabled;
  }

  getServiceTies() {
    this.pitch.pitch_ties.forEach(service =>{
      let serviceTie: ServiceTie = 
      {
        pitch_config_id: service.pitch_config_id,
        pitch_id: service.pitch_id
      }
      this.serviceTies.push(serviceTie)
    })
  }
}
