<h3 mat-dialog-title style="color: red;">Delete Confirmation!</h3>

  <form [formGroup]="deleteFormGroup" >
    <div *ngIf="deletePitch">
      <mat-table [dataSource]="deleteList">

        <ng-container matColumnDef="pitch_id">
          <mat-header-cell *matHeaderCellDef>
            Pitch
          </mat-header-cell>
          <mat-cell *matCellDef="let d">
            {{d.pitch_id}}
          </mat-cell>
        </ng-container>   

        <ng-container matColumnDef="variant_count">
            <mat-header-cell *matHeaderCellDef>
              No of Variants in Pitch
            </mat-header-cell>
            <mat-cell *matCellDef="let d">
              {{d.variant_count}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total_work">
          <mat-header-cell *matHeaderCellDef>
            Total Work Count
          </mat-header-cell>
          <mat-cell *matCellDef="let d">
            {{d.total_work}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayDeleteionDetailsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayDeleteionDetailsColumns;"></mat-row>
      </mat-table>

      <br>

      <div>
        You are about to delete the above items from Dashboard . If you wish to proceed please Type in <strong>'YES'</strong> and click confirm to <strong>DELETE</strong>
      </div>

      <mat-form-field class="card-dialog">
        <input id="deleteconfirmation" type="string" matInput cdkTextareaAutosize formControlName="form_deleteconfirmation" required>
      </mat-form-field>

      <div *ngIf="deleteFormGroup.get('form_deleteconfirmation').invalid && deleteFormGroup.get('form_deleteconfirmation').errors && (deleteFormGroup.get('form_deleteconfirmation').dirty || deleteFormGroup.get('form_deleteconfirmation').touched)">
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('required')">
          Confirmation is required.
        </small>
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('confirmation')">
          Confirmation should be typed in as "YES" 
        </small>
      </div>
    </div>

    <div *ngIf="deleteVariant">
      <div>
        You are about to delete the <strong>{{uncheckedVariants}} </strong>Variant(s) from Dashboard containing <strong>{{uncheckedVariantswork}}</strong> works . If you wish to proceed please Type in <strong>'YES'</strong> and click confirm to <strong>DELETE</strong>
      </div>

      <mat-form-field class="card-dialog">
        <input id="deleteconfirmation" type="string" matInput cdkTextareaAutosize formControlName="form_deleteconfirmation" required>
      </mat-form-field>
      <div *ngIf="deleteFormGroup.get('form_deleteconfirmation').invalid && deleteFormGroup.get('form_deleteconfirmation').errors && (deleteFormGroup.get('form_deleteconfirmation').dirty || deleteFormGroup.get('form_deleteconfirmation').touched)">
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('required')">
          Confirmation is required.
        </small>
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('confirmation')">
          Confirmation should be typed in as "YES" 
        </small>
      </div>
    </div>
    
    <div *ngIf="deleteRegion">
      <div>You are about to delete the <strong>{{selectregion}}</strong> Region from Team <strong>{{selectteam}}</strong>. If you wish to proceed please Type in <strong>'YES'</strong> and click confirm to <strong>DELETE</strong></div>
      <mat-form-field class="card-dialog">
          <input id="deleteconfirmation" type="string" matInput cdkTextareaAutosize formControlName="form_deleteconfirmation" required>
        </mat-form-field>
      <div *ngIf="deleteFormGroup.get('form_deleteconfirmation').invalid && deleteFormGroup.get('form_deleteconfirmation').errors && (deleteFormGroup.get('form_deleteconfirmation').dirty || deleteFormGroup.get('form_deleteconfirmation').touched)">
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('required')">
          Confirmation is required.
        </small>
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('confirmation')">
          Confirmation should be typed in as "YES" 
        </small>
      </div>
    </div>  
    
    <div *ngIf="deleteVersion">
      <div>You are about to delete the <strong>{{versionName}}</strong> Version and <strong>ALL</strong> associated pitches and work from the Team <strong>{{versionteam}}</strong>. If you wish to proceed please Type in <strong>'YES'</strong> and click confirm to <strong>DELETE</strong></div>
      <mat-form-field class="card-dialog">
          <input id="deleteconfirmation" type="string" matInput cdkTextareaAutosize formControlName="form_deleteconfirmation" required>
        </mat-form-field>
      <div *ngIf="deleteFormGroup.get('form_deleteconfirmation').invalid && deleteFormGroup.get('form_deleteconfirmation').errors && (deleteFormGroup.get('form_deleteconfirmation').dirty || deleteFormGroup.get('form_deleteconfirmation').touched)">
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('required')">
          Confirmation is required.
        </small>
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('confirmation')">
          Confirmation should be typed in as "YES" 
        </small>
      </div>
    </div>  

    <div *ngIf="deleteTeam">
      <mat-table [dataSource]="deleteTeamList">
        <ng-container matColumnDef="line">
          <mat-header-cell *matHeaderCellDef>
            Line
          </mat-header-cell>
          <mat-cell *matCellDef="let d">
            {{d.line}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="subline">
          <mat-header-cell *matHeaderCellDef>
            Subline
          </mat-header-cell>

          <mat-cell *matCellDef="let d">
            {{d.subline}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="total_pitches">
          <mat-header-cell *matHeaderCellDef>
            Total Pitch Count
          </mat-header-cell>

          <mat-cell *matCellDef="let d">
            {{d.total_pitches}}
          </mat-cell>
        </ng-container> 

        <mat-header-row *matHeaderRowDef="displayDeleteionTeamDetailsColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayDeleteionTeamDetailsColumns;"></mat-row>
      </mat-table>

      <br>

      <div>
        You are about to delete the  team with above details and <strong>all the related works</strong> . If you wish to proceed please Type in <strong>'YES'</strong> and click confirm to <strong>DELETE</strong>
      </div>

      <mat-form-field class="card-dialog">
        <input id="deleteconfirmation" type="string" matInput cdkTextareaAutosize formControlName="form_deleteconfirmation" required>
      </mat-form-field>
      <div *ngIf="deleteFormGroup.get('form_deleteconfirmation').invalid && deleteFormGroup.get('form_deleteconfirmation').errors && (deleteFormGroup.get('form_deleteconfirmation').dirty || deleteFormGroup.get('form_deleteconfirmation').touched)">
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('required')">
          Confirmation is required.
        </small>
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('confirmation')">
          Confirmation should be typed in as "YES" 
        </small>
      </div>
    </div>

    <div *ngIf="deleteTeamVariant">
      <div>
        You are about to delete the <strong>{{variantName}}</strong> variant. All related pitches and work will also be deleted.
        If you wish to proceed please type in <strong>'YES'</strong> and click Confirm to <strong>DELETE</strong> the variant.
      </div>

      <mat-form-field class="card-dialog">
        <input id="deleteconfirmation" type="string" matInput cdkTextareaAutosize formControlName="form_deleteconfirmation" required>
      </mat-form-field>
      <div *ngIf="deleteFormGroup.get('form_deleteconfirmation').invalid && deleteFormGroup.get('form_deleteconfirmation').errors && (deleteFormGroup.get('form_deleteconfirmation').dirty || deleteFormGroup.get('form_deleteconfirmation').touched)">
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('required')">
          Confirmation is required.
        </small>
        <small class="error" *ngIf="deleteFormGroup.get('form_deleteconfirmation').hasError('confirmation')">
          Confirmation should be typed in as "YES" 
        </small>
      </div>
    </div>
    
  </form>
<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button  mat-raised-button [disabled]="deleteFormGroup.status === 'INVALID'"color="warn" (click)="confirmDelete()">CONFIRM</button>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>
