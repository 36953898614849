import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { TeamWork, WorkUpdatesType, Region, TeamWorkFormatted, EditVariantWork, TeamVersion } from '../../shared/models/type-list';


@Injectable({
  providedIn: 'root'
})

export class WorkService extends AbstractRestService {
  postInit(): void{}

  public variant_work$ = new BehaviorSubject<EditVariantWork>(null)

  getRegions(team_id: number): Observable<GenericLambdaReturn<Region[]>>
  {
    let params: any = {};
    params.TeamID = team_id;

    return this.get({
      ApiResource: "/region",
      data: params,
      repeatCall: false
    });
  }


  createWorkBlock(plant_name:string, work_type: string, time_to_complete: number, region_id: number, work_description: string, frequency: number, variant_id: number, team_id: number, version_id: number, service_config_id: number):Observable<GenericLambdaReturn<any>>

  {
    let params: any = {};
    console.log('Service Frequency: ', frequency)

    params.WorkType = work_type.toLowerCase();
    params.TimeToComplete = time_to_complete;
    params.RegionID = region_id;
    params.Description = work_description;
    params.PlantName = plant_name;
    params.Frequency = frequency;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.ServiceConfigID = service_config_id;


    console.log("Test: ", params);
    params.Variantid = variant_id;


    return this.post({
      ApiResource: "/work",
      data: {
        data: params
      }
    });
  }

  updateWorkBlock(plant_name:string, work_id: number, work_type: string, time_to_complete: number, region_id: number, work_description: string, frequency: number, variant_id: number, team_id: number, version_id: number, service_config_id: number):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    console.log(params);

    params.WorkID = work_id;
    params.WorkType = work_type.toLowerCase();
    params.TimeToComplete = time_to_complete;
    params.RegionID = region_id;
    params.Description = work_description;
    params.PlantName = plant_name;
    params.Frequency = frequency;
    params.Variantid = variant_id;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.ServiceConfigID = service_config_id;

    return this.post({
      ApiResource: "/work",
      data: {data: params},
      repeatCall: false
    })
  }

  deleteWorkBlock(work_id: number, pitch_config_id: number, team_id: number, version_id: number, type: string, description: string, plant_name):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.WorkID = work_id;
    params.PitchConfigID = pitch_config_id;
    params.TeamID = team_id;
    params.VersionID = version_id;
    params.type = type;
    params.description = description;
    params.PlantName = plant_name

    console.log(params)
    
    return this.delete({
      ApiResource: "/work",
      data: params,
      repeatCall: false
    })
  }


  saveTeamWork(plant_name: string, team_id: number, version_id: number, work_updates: WorkUpdatesType[]):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PlantName = plant_name;
    params.TeamID = team_id;
    params.VersionID = version_id;

    params.WorkUpdates = work_updates;

    

    return this.post({
      ApiResource: "/worklocation",
      data: {data: params},
      repeatCall: false
    });
  }


  createRegion(plant_name:string, team_id: number, region: string, color: string, action_type: string):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};

    params.PlantName = plant_name;
    params.TeamID = team_id
    params.Region = region;
    params.Color = color;
    params.ActionType = action_type;
  
    return this.post({
      ApiResource: "/region",
      data: {
        data: params
      }
    });
  }

  updateRegion(plant_name:string, team_id: number, region:string, color: string, region_id: number):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    
    params.PlantName = plant_name;
    params.TeamID = team_id
    params.Region = region;
    params.Color = color;
    params.ActionType = 'Update';
    params.RegionID = region_id;

    return this.post({
      ApiResource: "/region",
      data: {data: params},
      repeatCall: false
    })
  }

  deleteRegion(plant_name:string, team_id: number, region:string, region_id: number):Observable<GenericLambdaReturn<any>>
  {
    let params: any = {};
    
    params.PlantName = plant_name;
    params.TeamID = team_id
    params.Region = region;
    params.RegionID = region_id;
    
    console.log(params)
    return this.delete({
      ApiResource: "/region",
      data: params,
      repeatCall: false
    });
  }

  createPitchWorkConfig(pitch_config_id: number, variant_id: number, work_id: number, sort_order: number, pitch_status: string, plant_name: string){
    let params: any = {};

    params.PlantName = plant_name;
    params.PitchConfigID = pitch_config_id;
    params.VariantID = variant_id;
    params.WorkID = work_id;
    params.SortOrder = sort_order;
    params.PitchStatus = pitch_status;

    return this.post({
      ApiResource: "/pitchwork",
      data: {
        data: params
      }
    });
  }

  getTeamLog(team_id: number, version_id: number, records:number=100) {
    let params: any = {};

    params.TeamID = team_id;
    params.VersionID = version_id;
    params.records = records;

    return this.get({
      ApiResource: "/team/log",
      data: params,
      repeatCall: false
    }); 
  }

  getWorkFormatted(team_id: number, version_id: number): Observable<GenericLambdaReturn<TeamWorkFormatted>>{
    let params: any = {};

    params.TeamID = team_id;
    params.VersionID = version_id;

    return this.get({
      ApiResource: "/work/formatted",
      data: params,
      repeatCall: false
    });
  }

  getPDF(team_list: TeamVersion[], printSingleSided: boolean, skipEmptyPitches: boolean, printErgoScore: boolean):Observable<Blob> {
    let params: any = {};    
    params.OverrideTwoSided = printSingleSided;
    params.SkipEmpty = skipEmptyPitches;
    params.PrintErgo = printErgoScore;
    params.TeamList = JSON.stringify(team_list);
	
    return this.getBinary({
      ApiResource: "/team/pdf",
      data: params
    });
  }

  saveErgoScores(ErgoScores:any[]):Observable<GenericLambdaReturn<any>>{
    let params: any = {};

    params.ErgoScore = ErgoScores;

    return this.post({
      ApiResource: "/ergo",
      data: params,
      repeatCall: false
    });
  }

}