<br>
    
<nav-link *ngIf = "currentNav" rootRoute="plants">
</nav-link>
    

<team-header
    [plant_name] = "currentNav.plant_name"
    [team_info] = "teamFormattedInfo"
    [save_status] = "saveStatus"
    (savedWork) = "onSaveWorkHeader()"
    (createdPitch) = "getTeamWork()"
    (changedEditStatus) = "getTeamWork()"
    (changedEditStatus) = "onChangeEditStatus($event)"
    (changedVersion) = "onChangeVersion($event)"
    (changedDisplayErgoStatus) = "onChangeDisplayErgoStatus($event)">
</team-header>

<div *ngIf="teamFormattedInfo" cdkDropList id = "{{teamFormattedInfo.team_name}}" [cdkDropListData] = "workstationIDArray" [cdkDropListConnectedTo] = "workstationParentListArray">
    <div class="scrolling-wrapper fx-row" id = "print-double-section">
        <div *ngFor = "let workstation of pitchesDisplayed">
            <div class="fx-column">
                <div>
                    <div *ngIf = "workstation['odd'] === undefined || (workstation['odd'] | json) === '{}'" class="empty-container" [ngStyle]="{'min-height': emptyMinHeight}">
                    </div>
                    <div *ngIf = "workstation['odd'] !== undefined && (workstation['odd'] | json) !== '{}'" style="page-break-after:auto;page-break-inside:avoid;" class="model-variant-container fx-column" [ngStyle]="{'height': containerminHeight,'width': workstation['odd'] != undefined ? workstation['odd'].minimumwidth : '230px'} ">
                        <pitch
                            [pitch] = "workstation['odd']"
                            [viewOnly] = "viewOnly"
                            [plantName] = "currentNav.plant_name"
                            [team_id] = "teamFormattedInfo.team_id"
                            [team_name] = "teamFormattedInfo.team_name"
                            [version_id] = "teamFormattedInfo.version_id"
                            [version_name] = "teamFormattedInfo.version_name"
                            [standardwork_status] = "teamFormattedInfo.standardwork_status"
                            [canDelete] = "canDeletePitch(workstation['odd'])"
                            [minHeight] = "minHeight"
                            [side] = "'odd'"
                            [workstationParentListArray] = "workstationParentListArray"
                            [workstationPlaceholderArray] = "workstationPlaceholderArray"
                            [workstationFluctuationArray] = "workstationFluctuationArray"
                            [workstationPeriodicArray] = "workstationPeriodicArray"
                            [workstationCycleWasteArray] = "workstationCycleWasteArray"
                            [multiplierValue] = "multiplierValue"
                            [ergoEnabled] = "displayErgoScore"
                            [timeUnit] = "teamFormattedInfo.takt_time_unit"
                            (savedWork) = "onSaveWorkPitch()"
                            (reload) = "getTeamWork()"
                            (dropped) = "drop($event)"
                            (collapsedPeriodic) = "calculateWorkMinAfterCollapse(workstation['odd'])"
                            (expandedPeriodic) = "calculateWorkMinAfterExpand(workstation['odd'])">
                        </pitch>
                    </div>
                </div>
                <br>
                <div>
                    <div *ngIf ="workstation['even'] !== undefined && (workstation['even'] | json) !== '{}'" style="page-break-after:auto;page-break-inside:avoid;" class="model-variant-container fx-column" [ngStyle]="{'height': containerminHeight,'width': workstation['even'] != undefined ? workstation['even'].minimumwidth : '230px'}">
                        <pitch
                            [pitch] = "workstation['even']"
                            [viewOnly] = "viewOnly"
                            [plantName] = "currentNav.plant_name"
                            [team_id] = "teamFormattedInfo.team_id"
                            [team_name] = "teamFormattedInfo.team_name"
                            [version_id] = "teamFormattedInfo.version_id"
                            [version_name] = "teamFormattedInfo.version_name"
                            [canDelete] = "canDeletePitch(workstation['even'])"
                            [minHeight] = "minHeight"
                            [side] = "'even'"
                            [workstationParentListArray] = "workstationParentListArray"
                            [workstationPlaceholderArray] = "workstationPlaceholderArray"
                            [workstationFluctuationArray] = "workstationFluctuationArray"
                            [workstationPeriodicArray] = "workstationPeriodicArray"
                            [workstationCycleWasteArray] = "workstationCycleWasteArray"
                            [multiplierValue] = "multiplierValue"
                            [watchDrop] = "watchDrop"
                            [ergoEnabled] = "displayErgoScore"
                            (savedWork) = "onSaveWorkPitch()"
                            (reload) = "getTeamWork()"
                            (dropped) = "drop($event)"
                            (collapsedPeriodic) = "calculateWorkMinAfterCollapse(workstation['even'])"
                            (expandedPeriodic) = "calculateWorkMinAfterExpand(workstation['even'])">
                        </pitch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>