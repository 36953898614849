import { Version } from '@angular/compiler';
import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TeamService } from '../services/team.service';
import { ViewType, VariantType } from 'src/app/shared/models/type-list';
import { VersionNotice } from './version-notice.component';


@Component({
  selector: 'team-version-dialog',
  templateUrl: './team-version-dialog.component.html',
  styleUrls: ['./team-version-dialog.component.scss']
})
export class TeamVersionDialog {
  demand: number;
  line: string;
  plantName: string;
  plantDisplayName: string;
  siteConfigID: number;
  teamID: number;
  teamName: string;
  versionID: number;
  versionName: string;
  numPitches: number;
  twoSided: string;
  taktTime: number;
  active: string;
  formType: string;
  dupVersion: string = '';
  baseVariantID: number;
  taktTimeUnit: string;
  availableVariants: VariantType[] = []

  sideOptions: ViewType[] = [{value: '0', view: 'No'}, {value: '1', view:'Yes'}];
  activeOptions: ViewType[] = [{value: '0', view: 'Inactive'}, {value: '1', view: 'Active'}];

  versionFormGroup: UntypedFormGroup;
  isBusy: boolean = false;

  activeVersion: Version;

  constructor(
    public dialogRef: MatDialogRef<TeamVersionDialog>,
    @Inject(MAT_DIALOG_DATA) public versionData: TeamVersionDialogData,
    private versionFormBuilder: UntypedFormBuilder,
    private teamService: TeamService,
    private versionNotice: MatDialog
  ) 
  {
    versionData.plant_name? this.plantName = versionData.plant_name: this.plantName = ''
    versionData.site_config_id? this.siteConfigID = versionData.site_config_id: this.siteConfigID = 0
    versionData.team_id? this.teamID = versionData.team_id: this.teamID = 0
    versionData.team_name? this.teamName = versionData.team_name: this.teamName = ''
    versionData.version_id? this.versionID = versionData.version_id: this.versionID = 0
    versionData.version_name? this.versionName = versionData.version_name: this.versionName = ''
    versionData.active_version? this.activeVersion = versionData.active_version: this.activeVersion = <Version>{}
    this.teamService.getVariant(this.teamID).subscribe((
      out=>{
        this.availableVariants = out.Body
      }
    ))
    versionData.base_variant_id? this.baseVariantID = versionData.base_variant_id: this.baseVariantID = 0
    versionData.demand? this.demand = versionData.demand: this.demand = 0
    versionData.num_pitches? this.numPitches = versionData.num_pitches: this.numPitches = 0
    versionData.takt_time_unit? this.taktTimeUnit = versionData.takt_time_unit: this.taktTimeUnit = versionData.takt_time_unit
    if (versionData.two_sided == '1')
      this.twoSided = '1'
    else
      this.twoSided = '0'
    versionData.takt_time? this.taktTime = versionData.takt_time: this.taktTime = 0
    if (versionData.active == '1')
      this.active = '1'
    else
      this.active = '0'

    versionData.form_type? this.formType = versionData.form_type: this.formType = 'Create New Version'
    if(this.formType == 'Duplicate Version'){
      this.dupVersion = this.versionName
      this.versionName = ''
    }

    console.log('Base Variant ID: ', this.baseVariantID)

    this.versionFormGroup = this.versionFormBuilder.group({
      form_versionName: [(this.versionName == null)? '': this.versionName, Validators.required],
      form_demand:[(this.demand == 0)? '': this.demand, Validators.required],
      form_numPitches: [(this.numPitches == 0)? '': this.numPitches, Validators.required],
      form_twoSided: (this.twoSided == null)? '0': this.twoSided,
      form_taktTime:[(this.taktTime == 0)? '': this.taktTime, Validators.required],
      form_active:(this.active == null)? '0': this.active,
      form_baseVariantID:[(this.baseVariantID == 0)? '': this.baseVariantID, Validators.required]
    })
    
    this.versionFormGroup.valueChanges.subscribe((formValues) =>{
      this.versionName = formValues.form_versionName
      this.demand = formValues.form_demand
      this.numPitches = formValues.form_numPitches
      this.twoSided = formValues.form_twoSided
      this.taktTime = formValues.form_taktTime
      this.active = formValues.form_active
      this.baseVariantID = formValues.form_baseVariantID
    })

    
    if (this.formType == 'Duplicate Version') {
      this.versionFormGroup.controls.form_numPitches.setValue(this.numPitches);
      this.versionFormGroup.controls.form_twoSided.setValue(this.twoSided);
      this.versionFormGroup.controls.form_baseVariantID.setValue(this.baseVariantID)
   }
    
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  createVersion(){
    this.isBusy = true
    let confirmResponse: boolean = false
    if (this.versionName == '')
      this.versionName = this.demand.toString()
    if (this.versionFormGroup.status == 'VALID'){
      if (this.active == '1'){
        const dialogRef = this.versionNotice.open(VersionNotice, {
          width: '800px',
          data: 
          { 
            active_version: this.activeVersion["version_name"],
            version_name: this.versionName
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined){
            confirmResponse = result.response
            console.log('Response: ', confirmResponse)
            if (confirmResponse){
              this.teamService.createVersion(this.teamID, this.teamName, this.siteConfigID, this.plantName, this.versionName, this.numPitches, this.twoSided, this.taktTime, this.active, this.demand, this.baseVariantID, this.taktTimeUnit).subscribe(out => {
                this.isBusy = false  
                this.dialogRef.close()
              })
            } else {
              this.isBusy = false
              this.dialogRef.close()
            }
          } else {
            this.isBusy = false
            this.dialogRef.close()
          }
        });
      } else {
        this.teamService.createVersion(this.teamID, this.teamName, this.siteConfigID, this.plantName, this.versionName, this.numPitches, this.twoSided, this.taktTime, this.active, this.demand, this.baseVariantID, this.taktTimeUnit).subscribe(out => {
          this.isBusy = false  
          this.dialogRef.close()
        })
      }
    } else {
      console.error('Invalid versionFormGroup, was not caught in form control error handling.')
    }
  }

  updateVersion(){
    this.isBusy = true
    let confirmResponse: boolean = false
    if (this.versionName == '')
      this.versionName = this.demand.toString()

    if (this.versionFormGroup.status == 'VALID'){
      if (this.activeVersion["version_id"] != this.versionID && this.active == '1'){
        const dialogRef = this.versionNotice.open(VersionNotice, {
          width: '800px',
          data: 
          { 
            active_version: this.activeVersion["version_name"],
            version_name: this.versionName
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined){
            confirmResponse = result.response
            if (confirmResponse){
              this.teamService.updateVersion(this.teamID, this.plantName, this.versionName, this.numPitches, this.twoSided, this.taktTime, this.active, this.versionID, this.teamName, this.siteConfigID, this.demand, this.baseVariantID, this.taktTimeUnit).subscribe(out => {
                this.isBusy = false  
                  this.dialogRef.close()
              })
            } else {
              this.isBusy = false
              this.dialogRef.close()
            }
          } else {
            this.isBusy = false
            this.dialogRef.close()
          }
        });
      } else {
        this.teamService.updateVersion(this.teamID, this.plantName, this.versionName, this.numPitches, this.twoSided, this.taktTime, this.active, this.versionID, this.teamName, this.siteConfigID, this.demand, this.baseVariantID, this.taktTimeUnit).subscribe(out => {
          this.isBusy = false  
            this.dialogRef.close()
        })
      }
    } else {
      console.error('Invalid versionFormGroup, was not caught in form control error handling.')
    }
  }

  duplicateVersion(){
    this.isBusy = true
  
    if (this.versionName == '')
      this.versionName = this.demand.toString()

    if (this.versionFormGroup.status == 'VALID'){
      this.teamService.duplicateVersion(this.teamID, this.versionID, this.plantName, this.versionName, this.taktTime, this.demand, this.dupVersion).subscribe(out => {
        this.isBusy = false    
        this.dialogRef.close()
      })
    } else {
      console.error('Invalid versionFormGroup, was not caught in form control error handling.')
    }
  }

}

export interface TeamVersionDialogData {
  plant_name: string;
  site_config_id: number;
  team_id: number;
  team_name: string;
  version_id: number;
  version_name: string;
  demand: number;
  num_pitches: number;
  two_sided: string;
  takt_time: number;
  active: string;
  base_variant_id: number;
  takt_time_unit: string;
  form_type: string;
  active_version: Version;
}
