import { Injectable } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { AbstractRestService, GenericLambdaReturn } from '@gea-mes/cognito';
import { TeamNode, Navigation, MultiTeamFilter } from 'src/app/shared/models/type-list';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class NavService extends AbstractRestService {
  postInit(): void{}

  private listNav:BehaviorSubject<GenericLambdaReturn<TeamNode[]>> = new BehaviorSubject<GenericLambdaReturn<TeamNode[]>>(null);
  private listSub$:Subscription;

  private currentNav$:BehaviorSubject<Navigation> = new BehaviorSubject<Navigation>(null);

  navListSubscription():void {
    if (this.listSub$) this.listSub$.unsubscribe();

    this.listSub$ = this.get({
      ApiResource: "/nav",
      repeatCall: false
    }).subscribe(
      (out:GenericLambdaReturn<TeamNode[]>) => {
        this.listNav.next(out); // Change to just Body, don't care about the rest
      }

    );
  }

  getNavList(): Observable<GenericLambdaReturn<TeamNode[]>>{
    if (!this.listSub$) this.navListSubscription();

    return this.listNav.asObservable();
  }

  getNavListLines(filter: MultiTeamFilter): Observable<GenericLambdaReturn<TeamNode[]>> {
    console.debug("Getting Navigation List Structure to line/sub line level");

    const options: any = {  // Important: Use 'any' or a more specific type if known
      ApiResource: "/nav/lines",
      repeatCall: false
    };

    if (filter && filter.ismultiteam) {
      options.params = { ismultiteam: 'true' };
    }

    return this.get<GenericLambdaReturn<TeamNode[]>>(options); // Specify the return type
  }

  getCurrentNav():Observable<Navigation> {
    return this.currentNav$.asObservable();
  }

  setCurrentLine(plant_name:string, line:string, subline:string) {
    this.currentNav$.next(null); // Clear out current nav while find the new current nav

    this.getNavList().pipe(
      filter(out => out != null), // Filter out empty values
      take(1) // Take only the first value that passes the above filter
      ).subscribe(
        (out) => {
          let plant:TeamNode[] = out.Body.filter(plant => plant.search == plant_name);

          if (plant && plant.length > 0) {
            let currentNav:Navigation = {
              plant_name: plant_name,
              plant_display: plant[0].description,
              line: line,
              subline:subline,
              team_id: 0,
              version_id: 0
            }

            this.currentNav$.next(currentNav);
          }
        }
      )


  }

  setCurrentTeamID(team_id:number) {
    if (this.currentNav$.getValue() == null || this.currentNav$.getValue().team_id != team_id) {
      // New team_id or no previous currentNav, look up the correct values

      this.currentNav$.next(null); // Clear out current nav while find the new current nav
    
      this.getNavList().pipe(
          filter(out => out != null), // Filter out empty values
          take(1) // Take only the first value that passes the above filter
          ).subscribe(
            (out) => {
              let teamDetails:TeamNode;
              let plantDisplay:string;

              out.Body.forEach( 
                plant => {
                  // Look thru each plant
                  if ("children" in plant) {
                    // Look thru each line
                    plant.children.forEach( 
                      line => {
                        if ("children" in line) {
                          // Look thru sub lines or list of teams
                          line.children.forEach(
                            sublineOrTeam => {

                              if ("team_id" in sublineOrTeam) {
                                // This is a team, check if it matches the team we're looking for
                                if (sublineOrTeam.team_id === team_id) {
                                  teamDetails = sublineOrTeam;
                                  plantDisplay = plant.description; // Store the plant display name
                                } 
                              } else if ("children" in sublineOrTeam) {
                                  // This is a subline, look at the list of teams
                                  sublineOrTeam.children.forEach(
                                    team => {
                                      if (team.team_id === team_id) {
                                        // This is a team, check if it matches the team we're looking for
                                        teamDetails = team;
                                        plantDisplay = plant.description; // Store the plant display name
                                      }
                                    });
                              }
                          });
                        }
                      })
                  }
              });

              if (teamDetails) {
                let currentNav:Navigation = {
                  plant_name: teamDetails.plant_name,
                  plant_display: plantDisplay,
                  line: teamDetails.line,
                  subline: teamDetails.subline,
                  team_id: teamDetails.team_id,
                  version_id: +teamDetails.version_id
                }

                this.currentNav$.next(currentNav);

              }
          });
    }
  }
  
}




