<div><h3 mat-dialog-title>Active Version Notice</h3>
    <div style="font-size: 18px;">
        When version <b>{{versionName}}</b> is set to <b>Active</b>, 
        the current active version <b>{{activeVersion}}</b> will be set to <b>Inactive</b>.
        <br>
        Please confirm that you want version <b>{{versionName}}</b> to be the team's <b>Active</b> version. 
    </div>
</div>
<br>
<br>

<div class="fx-row-center">
<button color="primary" mat-raised-button id="Confirm" class="mat-raised-button" type="button" label="Confirm" (click)="onConfirm()">
    Confirm
</button>
<button color="primary" mat-raised-button id="Close" class="mat-raised-button" type="button" label="Close" (click)="onNoClick()">
    Close
</button>
</div>
