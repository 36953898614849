import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'view-mode-dialog',
  templateUrl: './view-mode-dialog.component.html',
  styleUrls: ['./view-mode-dialog.component.scss']
})
export class ViewModeDialog  {

  constructor (public dialogRef: MatDialogRef<ViewModeDialog>) { }

  onConfirm(): void {
    this.dialogRef.close()
  }
}