<h3 mat-dialog-title>{{displayTeamName}} - {{variantFormTitle}}</h3>
<div mat-dialog-content>
  <form [formGroup]="variantFormGroup">

    <mat-form-field class="card-dialog">
      <input id="variant_name" type="text" matInput cdkTextareaAutosize placeholder="Variant Name" formControlName="form_variantName"
        required>
    </mat-form-field>
    <div *ngIf="variantFormGroup.get('form_variantName').invalid && 
    variantFormGroup.get('form_variantName').errors && 
            (variantFormGroup.get('form_variantName').dirty || variantFormGroup.get('form_variantName').touched)">
      <small class="error" *ngIf="variantFormGroup.get('form_variantName').hasError('required')"> Variant is Required </small>
    </div>

 </form>
</div>

<div mat-dialog-actions class="fx-row-space-evenly-center">
  <div *ngIf="variantFormGroup.status === 'VALID' && !unique" class="fx-row-space-evenly-center" [style]="{'color': 'red', 'font-weight': 'bold', 'padding-bottom': '20px'}">
    A variant can only be created or updated if a variant with the same name does not exist for the team.
  </div>
  <button *ngIf="variantFormType == 'create'" [disabled]="variantFormGroup.status === 'INVALID' || loading" mat-raised-button
    color="primary" (click)="createVariant()" >Create</button>
  <button *ngIf="variantFormType == 'update'" [disabled] = "loading" mat-raised-button color="primary" (click)="updateVariant()">Update</button>
  <!-- <button *ngIf="canDelete" mat-raised-button color="warn" >Delete</button> -->
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>
<div *ngIf="loading">

  <mat-spinner></mat-spinner>

</div>
