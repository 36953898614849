<h3 mat-dialog-title>{{teamFormTitle}}</h3>


<div mat-dialog-content>
    <form [formGroup]="teamFormGroup">

      <mat-form-field class = "card-dialog">
        <mat-select id = "check_plantName" matInput cdkTextareaAutosize placeholder = "Plant" formControlName="form_plant">
            <mat-option *ngFor="let plant of plantList" [value]="plant">   
                {{plant}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="card-dialog">
        <input type = "text"
          placeholder="Line"
          aria-label="line"
          matInput
          formControlName="form_line"
          [matAutocomplete] = "auto"
          [disabled]="teamFormType === 'update'">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let line of filteredLines | async" [value]="line" required>   
                    {{line}}
                </mat-option> 
            </mat-autocomplete>
      </mat-form-field>
      <div *ngIf="teamFormGroup.get('form_line').invalid && 
        teamFormGroup.get('form_line').errors && 
        (teamFormGroup.get('form_line').dirty || teamFormGroup.get('form_line').touched)">
        <small class="error"
          *ngIf = "teamFormGroup.get('form_line').hasError('required')">
              Line is Required
        </small>
      </div>   

      <mat-form-field class="card-dialog">
        <input id = "check_subline" type = "text" matInput cdkTextareaAutosize placeholder = "Subline" formControlName="form_subline" [disabled] = "teamFormType === 'update'">
      </mat-form-field>
      

      <mat-form-field class="card-dialog" >
        <mat-select id="teamnameid" matInput cdkTextareaAutosize placeholder="Team Leader" formControlName="form_teamName">
          <mat-option *ngFor="let option of TeamIDAvailable" [value]="option">
            {{option}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="teamFormGroup.get('form_teamName').invalid && 
        teamFormGroup.get('form_teamName').errors && 
        (teamFormGroup.get('form_teamName').dirty || teamFormGroup.get('form_teamName').touched)">
        <small class="error"
          *ngIf = "teamFormGroup.get('form_teamName').hasError('required')">
              Team is Required
        </small>
      </div>

      <mat-form-field class = "card-dialog">
        <mat-select id = "check_plantName" matInput cdkTextareaAutosize placeholder = "Time Unit" formControlName="form_timeUnit">
            <mat-option *ngFor="let unit of timeUnitList" [value]="unit">   
                {{unit}}
            </mat-option>
        </mat-select>
      </mat-form-field>

    </form>
</div> 
<div *ngIf="loading">

  <mat-spinner></mat-spinner>

</div>           

<div *ngIf="teamFormGroup.status === 'VALID' && !unique" class="fx-row-space-evenly-center" [style]="{'color': 'red', 'font-weight': 'bold', 'padding-bottom': '20px'}">
  A team can only be created or updated if a team with the same name does not exist for the line and subline.
</div>
<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button *ngIf = "teamFormType == 'create'" [disabled] = "teamFormGroup.status === 'INVALID' || loading" mat-raised-button color="primary" (click)="createTeam()">Create</button>
  <button *ngIf = "teamFormType == 'update'" mat-raised-button color="primary" (click) = "updateTeam()">Update</button>
  <span class = "spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>

