<gea-mes-header
  showLogOut="desktop"
  showMenu="both"

  showLeftIcon1="both"
  routeLeftIcon1="home"
  leftIcon1Src="home"
  leftIcon1ToolTip="Home Page"

  showLeftIcon2="both"
  routeLeftIcon2="viewteams"
  leftIcon2Src="poll"
  leftIcon2ToolTip="Multi Team View"

  showRightIcon1="both"
  routeRightIcon1="teams"
  rightIcon1Src="settings_applications"
  rightIcon1ToolTip="Admin Configuration"
  >
</gea-mes-header>
<router-outlet></router-outlet>
