import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavService } from 'src/app/nav/services/nav.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, Subscription} from 'rxjs';
import { filter, pluck, switchMap, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TeamService } from '../services/team.service';
import { WorkService } from '../../work/services/work.service';
import { Region, CurrentTeamType, LineTeam, Navigation } from 'src/app/shared/models/type-list';
import { RegionDialogComponent } from '../region-edit/region-dialog/region-dialog.component';
import { DeleteDialogComponent } from '../../shared/delete-dialog.component'


@Component({
  selector: 'app-region-edit',
  templateUrl: './region-edit.component.html',
  styleUrls: ['./region-edit.component.scss']
})
export class RegionEditComponent implements OnInit, OnDestroy {
  plant_name: string = ''
  line: string = ''
  subline: string = ''
  team_id: string = ''
  team: string = ''
  version: string = ''
  team_data: CurrentTeamType
  display_line: string = ''
  lineTeam: LineTeam
  plantDisplayName: string

  RegionSubscription: Subscription
  regionList: Region[] = []

  displayedRegionColumns: string[] = ['region','color','action']

  constructor(  private workService: WorkService,
    private teamService: TeamService,
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private regionDialog: MatDialog,
    private teamDialog: MatDialog) { 
      this.plant_name = this.route.snapshot.paramMap.get('plant_name');
      this.line = this.route.snapshot.paramMap.get('line');
      this.team = this.route.snapshot.paramMap.get('team');
      this.version = this.route.snapshot.paramMap.get('version');
      this.subline = this.route.snapshot.paramMap.get('subline');
      this.display_line = this.line;
      if (this.subline != '')
        this.display_line = this.display_line + ' - ' + this.subline;
    }

  ngOnInit(): void {
    this.navService.getCurrentNav().pipe(
      filter(out => out != null), // Filter out empty values
      take(1) // Take only the first value that passes the above filter
      ).subscribe(
        (out:Navigation) => {
          this.plantDisplayName = out.plant_display;
        }
      );
    
    this.teamService.line_team$.subscribe(out =>{
      this.lineTeam = out
      if (this.lineTeam == null || this.lineTeam == undefined)
        this.router.navigate(['teams/']);
      else
        this.getRegionList()
    });
  }

  ngOnDestroy(): void {
    if (this.RegionSubscription) this.RegionSubscription.unsubscribe();  
  }

  getRegionList(){
    let RegionObservable: Observable<Region[]> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
       this.workService.getRegions(this.lineTeam.team_id)),
       pluck('Body'));
      
    if (this.RegionSubscription) this.RegionSubscription.unsubscribe();     
       
    this.RegionSubscription = RegionObservable.subscribe((out) => {
        console.log(out)
        this.regionList = out
      });
      console.log( ' The regions ',this.regionList)
  }

  goBackToTeamList(){
    this.router.navigate(['teamedit/', this.plant_name, this.line, this.subline]);
  } 

  updateRegion(region_info: Region){
    console.log(' Open dialog for region ')
    
    const dialogRef = this.regionDialog.open(RegionDialogComponent, {
      width: '500px',
      data: 
      {
        team_name: this.lineTeam.team_name,
        team_id: this.lineTeam.team_id,
        region_id: region_info.region_id,
        region: region_info.region,
        color:region_info.color,
        form_type: 'update'
      } 
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) 
        this.workService.updateRegion(this.plant_name, this.lineTeam.team_id, result.region, result.color, region_info.region_id).subscribe(out => {
          this.regionList = []
          this.getRegionList()
        })
    }); 
  }

  createRegion(){
    const dialogRef = this.regionDialog.open(RegionDialogComponent, {
      width: '500px',
      data: 
      {
        team_name: this.lineTeam.team_name,
        team_id: this.lineTeam.team_id,
        region: '',
        color: '',
        form_type: 'create'      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) 
        this.workService.createRegion(this.plant_name, this.lineTeam.team_id, result.region , result.color, 'Create').subscribe(out => {
          this.regionList = []
          this.getRegionList()
        })
    }); 

  }

deleteRegion(region_info: Region){

  console.log ('Delete Region ');
  /*  let lineArray: string[] = this.lineTeamList.map(line => line.line) */
    console.log('The value of region is ',region_info)
    const dialogRef = this.teamDialog.open(DeleteDialogComponent, {
      width: '500px',
      data: 
      { 
        team_name: this.lineTeam.team_name,
        team_id: this.lineTeam.team_id,
        region: region_info.region,
        color: '',
        form_type: 'deleteRegion'   
      }
    }); 
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.action == 'delete') 
        this.workService.deleteRegion(this.plant_name, this.lineTeam.team_id, region_info.region, region_info.region_id).subscribe(out => {
          this.regionList = []
          this.getRegionList()
        })
    });
}

}
