<div class="fx-row-start-start">
    <div><mat-icon>description</mat-icon></div>
    <div><h3 mat-dialog-title>Change Log ({{loadedRecords}} displayed)</h3></div>
</div>
<div class="fx-column-end-stretch">
    <div mat-dialog-content >
        <div *ngIf="loading" class="fx-column-center-center">
            <div>
                <mat-spinner></mat-spinner>
            </div>
            <div>
                Loading log ...
            </div>
        </div>
        <div class="fx-row-start-center">
                <div>Records:</div>
                <div><input matInput type="number" min="1" size="5" [(ngModel)]="records" name="records" style="cursor:pointer"></div>
                <div><button mat-raised-button color="basic" type="button" (click)="getLog()">Refresh</button></div>
        </div> 
        <div *ngIf="!loading">
            <mat-table [dataSource]="dataSource" style="overflow:auto; height: 395px;"> 

                <ng-container matColumnDef="Timestamp">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Timestamp </mat-header-cell>
                    <mat-cell *matCellDef="let entry"> {{entry.timestamp | date:'MM-dd-yyyy HH:mm:ss'}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="User">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> User </mat-header-cell>
                    <mat-cell *matCellDef="let entry"> {{entry.user_name}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="Action">
                    <mat-header-cell *matHeaderCellDef mat-sort-header> Action </mat-header-cell>
                    <mat-cell *matCellDef="let entry"> {{entry.action}} </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
        </div>
    </div>
    <div mat-dialog-actions class="fx-row-end-end" style="padding-top: 15px;">
        <div>
            <button mat-raised-button color="primary" type="button" (click)="onNoClick()">Close</button>
        </div>
    </div>
</div>