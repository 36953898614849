import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DefaultColors, RegionColors, WorkTypes } from 'src/app/shared/models/enum-list';
import { WorkblockDialog } from '../workblock-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})

export class Work implements OnChanges {
  workObject = {
    cycle_waste         : [],
    periodic            : [],
    fluctuation         : [],
    placeholder         : [],
    max_cycle_sort      : 0,
    max_periodic_sort   : 0,
    max_fluctuation_sort: 0,
  }
  @Input()
  multi_team = false;
  @Input()
    multiplier_value = 15;
  @Input()
    pitch_config_id = 0;
  @Input()
    pitch_id = "";
  @Input()
    pitch_status = "active";
  @Input()
    plant_name = "";
  @Input()
    team_id = 0;
  @Input()
    subassembly_id = "";
  @Input()
    variant = {
      work: this.workObject,
      variant_name: "",
      variant_id: 0,
      placeholderhgt: "0px"
    };
  @Input()
    version_id = 0;
  @Input()  
    work = {
      work_id: 0,
      sort_order: 0,
      type: "",
      region: 0,
      description: "",
      time_to_complete: 0,
      variant: "",
      variant_id: 0,
      frequency: 0,
      color: "",
      time_unit: 's',
      serivce_config_id: 0
    };
  @Input()
    viewOnly = false;
    

  @Output () reload = new EventEmitter();
  @Output() savedWork = new EventEmitter();

  backgroundColor: string = "";
  height: string = "";
  minHeight: string = "";
  
  constructor(
    private workblockDialog: MatDialog,
  ) { }

  ngOnChanges(): void {
    if (this.work != undefined){
      this.setWorkFormat()
    }
  }

  setWorkFormat(){
    this.height = Math.ceil(this.multiplier_value * + this.work.time_to_complete).toString() + "px";
    this.minHeight = this.height;

    if (!Object.values(RegionColors).includes(this.work.color as RegionColors) || this.work.color == undefined){
      switch(this.work.type){
          case WorkTypes.Cycle: this.backgroundColor = DefaultColors.Default_Cycle; break;
          case WorkTypes.Periodic: this.backgroundColor = DefaultColors.Default_Periodic; break;
          case WorkTypes.Fluctuation: this.backgroundColor = DefaultColors.Default_Fluctuation; break;
          case WorkTypes.Waste: this.backgroundColor = DefaultColors.Default_Waste; break;
        }
    } else {
      this.backgroundColor = this.work.color
    }

  }

  expandWorkHeight(){
      this.height = "auto";
  }

  resetWorkHeight(){
    this.height = Math.ceil(this.multiplier_value * this.work.time_to_complete).toString() + "px";
  }

  updateWork(){
    this.savedWork.emit()
    let dialogRef : any;
    console.info('Work Block Dialog Open: ' + this.variant.variant_id)
    dialogRef = this.workblockDialog.open(WorkblockDialog, {
      width: '500px',
      data: 
      {
        formType: 'Update Work',
        pitch_config_id: this.pitch_config_id,
        pitch_id: this.pitch_id,
        pitch_status: this.pitch_status,
        plant_name: this.plant_name,
        team_id: this.team_id,
        subassembly_id: this.subassembly_id,
        version_id: this.version_id,
        work: this.work,
        max_cycle_sort: this.variant.work.max_cycle_sort,
        max_periodic_sort: this.variant.work.max_periodic_sort,
        max_fluctuation_sort: this.variant.work.max_fluctuation_sort,
        variant_id: this.variant.variant_id,
        variant_name: this.variant.variant_name
      }
    })   

    dialogRef.afterClosed().subscribe(result => {
      console.log('HERE')
      this.reload.emit();
    });
  }
}
