import { Component, Inject } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { RegionColors } from '../../../shared/models/enum-list';
import { ColorType } from '../../../shared/models/type-list';

@Component({
  selector: 'app-region-dialog',
  templateUrl: './region-dialog.component.html',
  styleUrls: ['./region-dialog.component.scss']
})
export class RegionDialogComponent {
  Region: string;
  teamID: string;
  Color: string;
  displayTeamName: string;

  
  regionFormGroup: UntypedFormGroup;
  regionFormTitle: string;
  regionFormType: string;
  currentColorName: string;

  showregionColors: ColorType[] = []

  constructor(
    public dialogRef: MatDialogRef<RegionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public regionData: RegionDialogData,
    private regionFormBuilder: UntypedFormBuilder,    
  ) { 
    
    Object.keys(RegionColors).forEach(key => {
      let regionColor: ColorType = {
        color: RegionColors[key],
        color_name: key
      }
      this.showregionColors.push(regionColor)
    })
    console.debug('The region value ',regionData)
    regionData.team_id? this.teamID = regionData.team_id : this.teamID = ''
    regionData.region? this.Region = regionData.region : this.Region = ''
    regionData.team_name? this.displayTeamName = regionData.team_name : this.displayTeamName = ''
    regionData.color? this.Color = regionData.color : this.Color = ''
    regionData.form_type ? this.regionFormType = regionData.form_type : this.regionFormType = 'create'
 
    if (this.regionFormType == 'update'){
      this.regionFormTitle = 'Edit Region'

    }else{
      this.regionFormTitle = 'Add New Region'

    }
    
    this.regionFormGroup = this.regionFormBuilder.group({
      form_regionName: [(this.Region == null)? '': this.Region, Validators.required],
      form_Color: (this.Color == null)? '': this.Color 
    })
    this.regionFormGroup.valueChanges.subscribe( formValues =>{
      this.Region = formValues.form_regionName,
      this.Color = formValues.form_Color

   });

  }
  onNoClick(): void {
    this.dialogRef.close();
  }


  createRegion(): void {
    console.log( ' inside create region dialog ')
    if (this.regionFormGroup.status == 'VALID'){
      const regionFormData = {
        region: this.Region,
        color: this.Color
      }
      this.dialogRef.close(regionFormData)
    } else {
      console.error('Invalid VariantFormGroup, was not caught in form control error handling.')
    }
  }

  updateRegion():void{
    if (this.regionFormGroup.status == 'VALID'){
      const regionFormData = {
        region: this.Region,
        color: this.Color
      }
      this.dialogRef.close(regionFormData)
    } else {
      console.error('Invalid regionFormGroup, was not caught in form control error handling.')
    }
  }

  getColorName(){
    let selected_color = this.regionFormGroup.controls.form_Color.value

    if (this.regionFormType  == 'update') {
      this.currentColorName = this.showregionColors[this.showregionColors.findIndex(color => color.color === selected_color)].color_name
    }
    return selected_color
  }
} 
export interface RegionDialogData {
  team_id: string;
  region: string;
  team_name:string;
  form_type: string;
  color:string;
}