import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavService } from 'src/app/nav/services/nav.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription} from 'rxjs';
import { filter, pluck, switchMap, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as dayjs from 'dayjs'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TeamService } from '../services/team.service';
import { VariantType, CurrentTeamType, LineTeam, Navigation } from 'src/app/shared/models/type-list';
import { TeamVariantDialogComponent } from './team-variant.dialog.component';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog.component';

@Component({
  selector: 'app-variant-edit',
  templateUrl: './variant-edit.component.html',
  styleUrls: ['./variant-edit.component.scss']
})
export class VariantEditComponent implements OnInit, OnDestroy {
  plant_name: string = ''
  line: string = ''
  subline: string = ''
  team_id: string = ''
  team: string = ''
  version: string = ''
  team_data: CurrentTeamType
  display_line: string = ''
  lineTeam: LineTeam
  plantDisplayName: string

  variantSubscription: Subscription
  variantList: VariantType[] = []
  
  displayedVariantColumns: string[] = ['variant_id','variant_name','actions']

  constructor( private teamService: TeamService,
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private variantDialog: MatDialog,
    private deleteDialog: MatDialog,
    private _snackbar: MatSnackBar
  ){
      this.plant_name = this.route.snapshot.paramMap.get('plant_name');
      this.line = this.route.snapshot.paramMap.get('line')
      this.team = this.route.snapshot.paramMap.get('team');
      this.version = this.route.snapshot.paramMap.get('version');
      this.subline = this.route.snapshot.paramMap.get('subline')
      this.display_line = this.line
      if (this.subline != '')
        this.display_line = this.display_line + ' - ' + this.subline
  } 

  ngOnInit(): void {
    this.navService.getCurrentNav().pipe(
      filter(out => out != null), // Filter out empty values
      take(1) // Take only the first value that passes the above filter
      ).subscribe(
        (out:Navigation) => {
          this.plantDisplayName = out.plant_display;
        }
      );

    this.teamService.line_team$.subscribe(out =>{
      this.lineTeam = out
      if (this.lineTeam == null || this.lineTeam == undefined)
        this.router.navigate(['teams']);
      else
        this.getVariantList()
    });
  }

  getVariantList(){
    let variantObservable: Observable<VariantType[]> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
       this.teamService.getVariant(this.lineTeam.team_id)),
       pluck('Body'));
      
    if (this.variantSubscription) this.variantSubscription.unsubscribe();     
       
    this.variantSubscription = variantObservable.subscribe((out) => {
        console.log(out)
        this.variantList = out
      });
  }

  createVariant(){
    const dialogRef = this.variantDialog.open(TeamVariantDialogComponent, {
      width: '500px',
      data: 
      {
        team_name: this.lineTeam.team_name,
        variant_id: '',
        variant_name: '',
        form_type: 'create',
        variant_list: this.variantList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) 
        this.teamService.createVariant(this.lineTeam.team_id,result.variant_name, 0,this.plant_name).subscribe(out => {
          this.variantList = []
          this.getVariantList()
        })
    });
    
  }

  updateVariant(variant_info: VariantType){
    const dialogRef = this.variantDialog.open(TeamVariantDialogComponent, {
      width: '500px',
      data: 
      {
        team_name: this.lineTeam.team_name,
        team_id: this.lineTeam.team_id,
        variant_id: variant_info.variant_id,
        variant_name: variant_info.variant_name,
        form_type: 'update',
        variant_list: this.variantList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) 
        this.teamService.updateVariant(this.lineTeam.team_id, result.variant_name, variant_info.variant_id, 0, this.plant_name).subscribe(out => {
          this.variantList = []
          this.getVariantList()
        })
    });
  }

  goBackToTeamList(){
    this.router.navigate(['teamedit/', this.plant_name, this.line, this.subline]);
  }

  ngOnDestroy(): void {
    if (this.variantSubscription) this.variantSubscription.unsubscribe();
  }

  deleteVariant(variant: VariantType){
    const dialogRef = this.deleteDialog.open(DeleteDialogComponent, {
      width: '500px',
      data: 
      {
        variant_name: variant.variant_name,
        form_type: 'deleteTeamVariant'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined)
        if (result.action = 'delete') 
          this.teamService.deleteVariant(this.plant_name, this.lineTeam.team_id, variant.variant_id, variant.variant_name).subscribe(out => {
          this.variantList = []
          this.getVariantList()
        })
    });
  }

}
