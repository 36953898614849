import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TeamService } from '../../services/team.service';
import { PitchType } from 'src/app/shared/models/type-list';
import { FormGroup, FormBuilder, Form } from '@angular/forms';

@Component({
  selector: 'app-pitch-work-copy',
  templateUrl: './pitch-work-copy.component.html',
  styleUrls: ['./pitch-work-copy.component.scss']
})
export class PitchWorkCopyDialog implements OnInit {
  plantName: string;
  pitchesToCopy: PitchType[] = [];
  pitchesToUpdate: PitchType[] = [];

  copyGroup: FormGroup;

  copyPitch: PitchType = <PitchType>{};
  copyPitchID: number = 0;
  updatePitch: PitchType = <PitchType>{};
  updatePitchID: number = 0;


  constructor(
    public dialogRef: MatDialogRef<PitchWorkCopyDialog>,
    @Inject(MAT_DIALOG_DATA) public pitchWorkData: PitchWorkData,
    private teamService: TeamService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.pitchWorkData.plant_name ? this.plantName = this.pitchWorkData.plant_name: this.plantName = '';
    this.pitchWorkData.pitch_list ? this.pitchesToCopy = this.pitchWorkData.pitch_list: this.pitchesToCopy = [];

    console.log(this.pitchesToCopy)

    this.copyGroup = this.fb.group({
      f_copyPitch: [(this.copyPitchID == 0) ? 0: this.copyPitchID],
      f_updatePitch: [(this.updatePitchID == 0) ? 0: this.updatePitchID]
    })

    this.copyGroup.valueChanges.subscribe((values) =>{
      this.copyPitchID = values.f_copyPitch,
      this.updatePitchID = values.f_updatePitch
    });

    this.copyGroup.controls['f_copyPitch'].valueChanges.subscribe((value) => {
      console.log(value)
      let pitchIndex = this.pitchesToCopy.findIndex(pitch => pitch.pitch_config_id === value)
      if (pitchIndex != -1){
        if(this.pitchesToCopy[pitchIndex].type == "pitch"){
          this.pitchesToUpdate = this.pitchWorkData.pitch_list.filter(pitch => pitch.type === "pitch" && pitch.pitch_config_id !== value)
        } else if (this.pitchesToCopy[pitchIndex].type == "subassembly") {
          this.pitchesToUpdate = this.pitchWorkData.pitch_list.filter(pitch => pitch.type === "subassembly" && pitch.pitch_config_id !== value)
        }
      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submitCopy(){
    console.log(this.plantName, this.updatePitchID, this.copyPitchID)
    if ((this.updatePitchID != 0 && this.copyPitchID != 0) && this.copyGroup.status == "VALID"){
      this.teamService.copyPitchWork(this.plantName, this.copyPitchID, this.updatePitchID).subscribe(out=>{
        this.dialogRef.close()
      });
    }
  }

}

export interface PitchWorkData {
  plant_name: string;
  pitch_list: PitchType[];
}
