import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder, FormControl, ValidatorFn } from '@angular/forms';
import { VariantType } from 'src/app/shared/models/type-list';

@Component({
  selector: 'app-team-variant.dialog',
  templateUrl: './team-variant.dialog.component.html',
  styleUrls: ['./team-variant.dialog.component.scss']
})
export class TeamVariantDialogComponent  {

  variantName: string;
  variantID: string;
  displayTeamName: string;

  variantFormGroup: UntypedFormGroup;
  variantFormType: string;
  variantFormTitle: string;
  loading:Boolean=false;
  unique: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<TeamVariantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public variantData: VariantDialogData,
    private variantFormBuilder: UntypedFormBuilder,
  ) 
  { 
    console.debug(variantData)
    variantData.variant_id? this.variantID = variantData.variant_id : this.variantID = ''
    variantData.variant_id? this.variantID = variantData.variant_id : this.variantID = ''
    variantData.team_name? this.displayTeamName = variantData.team_name : this.displayTeamName = ''

    variantData.variant_name? this.variantName = variantData.variant_name : this.variantName = ''
    variantData.form_type ? this.variantFormType = variantData.form_type : this.variantFormType = 'create'

    if (this.variantFormType == 'update'){
      this.variantFormTitle = 'Edit Variant'

    }else{
      this.variantFormTitle = 'Add New Variant'

    }
    this.variantFormGroup = this.variantFormBuilder.group({
      form_variantName: [(this.variantName == null)? '': this.variantName, Validators.required]
    })
    
    this.variantFormGroup.valueChanges.subscribe( formValues =>{
      this.variantName = formValues.form_variantName
    });

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createVariant(): void {
    this.loading = true
    this.unique = this.isTeamNameUnique()

    if (this.variantFormGroup.status == 'VALID' && this.unique){
      const variantFormData = {
        variant_name: this.variantName
      }
      this.dialogRef.close(variantFormData)  
      this.loading = false
    } else if (!this.unique) {
      this.loading = false
    } else {
      console.error('Invalid VariantFormGroup, was not caught in form control error handling.')
    }
  }

  updateVariant():void{
    this.loading = true
    this.unique = this.isTeamNameUnique()

    if (this.variantFormGroup.status == 'VALID' && this.unique){
      const variantFormData = {
        variant_name: this.variantName
      }
      this.loading = false
      this.dialogRef.close(variantFormData)
    } else if (!this.unique) {
      this.loading = false
    } else {
      console.error('Invalid VariantFormGroup, was not caught in form control error handling.')
    }
  }

  normalizeString(s: string): string {
    return s.replace(/\s+/g, '').toLowerCase()
  }

  isTeamNameUnique(): boolean {
    var filteredList = this.variantData.variant_list
    filteredList = filteredList.filter(v => this.normalizeString(v.variant_name) === this.normalizeString(this.variantName))
    return (filteredList.length === 0)
  }
}

export interface VariantDialogData {
  variant_id: string;
  variant_name: string;
  form_type: string;
  team_name:string;
  variant_list: VariantType[];

}