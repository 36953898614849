<div class="button-row" >
    <div>
        <h3>WARNING</h3>
        <div>
            You are currently in view mode.<br>Changes in this mode will not be saved.
        </div>
    </div>
    <br>
    <div>
        <button class="mat-raised-button" type="button" label="Confirm" (click)="onConfirm()">
            I Understand
        </button>
    </div>
</div>