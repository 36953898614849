import { Component, OnInit , OnDestroy, HostListener } from '@angular/core';
import { WorkService } from 'src/app/work/services/work.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subscription, combineLatest} from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { pluck, switchMap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ViewModeDialog } from 'src/app/work/team-header/view-mode-dialog.component';
import { PrintDialog } from 'src/app/work/print-dialog/print-dialog.component';     
import { WorkUpdatesType, Navigation, TeamWorkFormatted, TeamFormatted, 
         WorkstationFormatted, PitchPair, MultiTeam, SelectTeam, Team } from 'src/app/shared/models/type-list';
import { NavService } from 'src/app/nav/services/nav.service';


@Component({
  selector: 'multi-team-work',
  templateUrl: './multi-team-work.component.html',
  styleUrls: ['./multi-team-work.component.scss']
})
export class MultiTeamWorkComponent implements OnInit, OnDestroy {
  workFormattedSubscription: Subscription
  twoSided: Boolean = false
  minHeight: string
  containerminHeight : string
  emptyMinHeight: string
  isExpanded : boolean = false
  viewOnly: boolean = true
  multi_team:boolean = true
  viewOnlyUserConfirm: boolean = false
  baseVariant: number
  multiplierValue: number
  currentNav: Navigation = {
    plant_name: 'AP1',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  }
  oddWorkstations: WorkstationFormatted[]
  evenWorkstations: WorkstationFormatted[]
  pitchPairs: PitchPair[] = []
  pitchesDisplayed: PitchPair[] = []
  workstationAllListArray: string[] = []
  workstationParentListArray: string[] = []
  workstationFluctuationArray: string[] = []
  workstationPeriodicArray: string[] = []
  workstationCycleWasteArray: string[] = []
  workstationPlaceholderArray: string[] = []
  pitchSaves: WorkstationFormatted[] = []
  saveStatus: any = {
    message: "",
    color: "primary"
  }
  displayErgoScore: Boolean = false;
  separatorHeight: string = '500px';

  multiTeams: MultiTeam[] = [];
  maxTaktTime: number = 0;
  combinedSub: Subscription;


  selectedTeams: SelectTeam[] = []
  
  theoreticalProcesses: number = 0;
  actualProcesses: number = 0;
  headerHeight: number = 225;
  numberOfSides: number = 1;
  screenWidth: number = 0;
  screenHeight: number = 0;
  root = document.body.style;



  constructor(
    private workService: WorkService,
    private route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private viewModeDialog: MatDialog,
    private printDialog: MatDialog,
    private navService: NavService
  ){  }
  
  canDeactivate(): boolean {
    if (this.saveStatus.message == 'Unsaved Changes'){
      const result = window.confirm('Unsaved Changes will be lost.');
      return result
    }
    return true;
  }
    

  ngOnInit() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    let scrollHeight: number = Number(this.screenHeight) - 200;
    this.root.setProperty('--scroll-height', scrollHeight.toString() + 'px')
    
    this.navService.setCurrentTeamID(+this.currentNav.team_id);
    this.route.queryParamMap.subscribe(params => {
      let jsonString = params.get('teamlist')
      this.selectedTeams = JSON.parse(jsonString)
    })
    this.getTeamWork();
  }

  ngOnDestroy() {
    if (this.workFormattedSubscription) this.workFormattedSubscription.unsubscribe();
    if (this.combinedSub) this.combinedSub.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload() {
    if (this.pitchSaves.length > 0){
      return false
    } 
  }

  getTeamWork(){
    this.multiTeams = []
    let  teamObs:Observable<TeamWorkFormatted>[] = [];

    this.selectedTeams.forEach( team => {
      console.log('Team ID: ', team.team_id)
      console.log('Version ID: ', team.version_id)

      let workObservable: Observable<TeamWorkFormatted> =
      this.route.paramMap.pipe(
        switchMap((params: ParamMap) =>
        this.workService.getWorkFormatted(team.team_id, team.version_id)),
        pluck('Body'));     
        
      teamObs.push(workObservable);       
    })
    
    //Using combineLatest function to ensure all selected teams are read then loop thru teams to identify max takt time for scaling
    //then calling the calculateWorkMinHeight function

    if (this.combinedSub) this.combinedSub.unsubscribe();    
    
    this.combinedSub = combineLatest(teamObs).subscribe(result => {
      
      result.forEach(out => {      
        
        let selectedTeam: MultiTeam = {
          team_info: <TeamFormatted>{},
         pitches: []
        }

        this.pitchPairs = []
        console.info(out)
        
        selectedTeam.team_info = out.team_version_info
    
        this.createPitchPairs(out.odd_workstations, out.even_workstations)
        selectedTeam.pitches = this.pitchPairs
        selectedTeam.empty_containers = this.getNumberOfEmptyContainers(this.pitchPairs)
        console.log(selectedTeam.empty_containers)
      
        this.multiTeams.push(selectedTeam)

        if (this.maxTaktTime < selectedTeam.team_info.takt_time){
          this.maxTaktTime = selectedTeam.team_info.takt_time
        }

        if(selectedTeam.team_info.two_sided == 1)
          this.numberOfSides = 2

        //multiplier value for determining workblock, pitch, and variant box sizes
        if  (this.maxTaktTime > 60 &&  this.maxTaktTime < 1000)
          this.multiplierValue = 3
        else if(this.maxTaktTime > 1000) 
          this.multiplierValue = 1
        else
          this.multiplierValue = 15        
      })

      console.log(this.multiTeams)
      this.calculateWorkMinHeight()
      this.updateCycleStats()
    })    
  }

  updateCycleStats(){
    this.actualProcesses = 0
    this.theoreticalProcesses = 0
    this.multiTeams.forEach(team =>{
      console.log(team.team_info.team_id)
      let variantIndex: number = 0
      let baseIndex: number = 0
      let baseCycleTime: number = 0
      let variantCycleTime: number = 0
      let baseVariantID: number = team.team_info.base_variant_id

      team.team_info.actual_processes = 0
      team.team_info.base_cycle_time = 0
      team.team_info.theoretical_processes = 0

      team.team_info.variant_cycle_times.forEach(variant=>{
        variant.actual_processes = 0
        variant.cycle_time = 0
        variant.theoretical_processes = 0

        team.pitches.forEach(pitch => {
          Object.keys(pitch).forEach(key => {
            if (pitch[key] != undefined && pitch[key].hasOwnProperty('variant')){
              variantIndex = pitch[key].variant.findIndex(v => v.variant_id === variant.variant_id)
              if (variantIndex != -1){
                variantCycleTime = 0 
                variantCycleTime = pitch[key].variant[variantIndex].work.cycle.map(work => work.time_to_complete).reduce((prev,curr) => prev + curr, 0)
                if (variantCycleTime > 0){
                  variant.cycle_time += variantCycleTime
                  variant.actual_processes += 1
                  variant.theoretical_processes += variantCycleTime/pitch[key].takt_time
                }
                if (variant.variant_id == baseVariantID){
                  baseCycleTime = 0
                  baseCycleTime = pitch[key].variant[variantIndex].work.cycle.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
                  if (baseCycleTime > 0){
                    team.team_info.actual_processes += 1
                    team.team_info.base_cycle_time += baseCycleTime
                    team.team_info.theoretical_processes += baseCycleTime/pitch[key].takt_time

                    this.actualProcesses += 1
                    this.theoreticalProcesses += baseCycleTime/pitch[key].takt_time
                  }
                }
              } else {
                baseIndex = pitch[key].variant.findIndex(b => b.variant_id === team.team_info.base_variant_id)
                if (baseIndex != -1){
                  baseCycleTime = 0
                  baseCycleTime = pitch[key].variant[baseIndex].work.cycle.map(work => work.time_to_complete).reduce((prev,curr) => prev + curr, 0)
                  if (baseCycleTime > 0){
                    variant.actual_processes += 1
                    variant.cycle_time += baseCycleTime
                    variant.theoretical_processes += baseCycleTime/pitch[key].takt_time
                  }
                }
              }
            }
          })
        })
        
        if (variant.variant_id == team.team_info.base_variant_id)
          team.team_info.theoretical_processes = variant.theoretical_processes
      })
    })
  }


  drop(event: CdkDragDrop<string[]>) {
    console.log(event)
    let validMovement: boolean = true

    let currentFirstDash = event.container.id.indexOf('-')
    let currentSecondDash = event.container.id.indexOf('-', currentFirstDash + 1)

    let currentIndex: number = -1
    let currentPitchSide: string = ''
    let currentPitchIndex: number = -1
    let currentPitch = Number(event.container.id.substring(0, currentFirstDash))
    let currentVariantIndex: number = -1
    let currentVariant = Number(event.container.id.substring(currentFirstDash + 1, currentSecondDash))
    let currentContainerID = event.container.id.substring(0, currentSecondDash)
    let currentTeamIndex: number =  -1
    
    console.log(currentPitch)
    this.multiTeams.forEach((team, index) => {
        
      if (currentPitchIndex == -1 && team['pitches'].map(pitch => pitch.odd).filter(pitchOdd => pitchOdd != undefined).length > 0) {
        currentPitchIndex = team['pitches'].map(pitch => pitch.odd).findIndex(pitchOdd => pitchOdd.pitch_config_id === currentPitch)
        currentTeamIndex = index
        currentPitchSide = 'odd'
      }

      if (currentPitchIndex == -1 && team['pitches'].map(pitch => pitch.even).filter(pitchEven => pitchEven != undefined).length > 0) {
        currentPitchIndex = team['pitches'].map(pitch => pitch.even).findIndex(pitchEven => pitchEven.pitch_config_id === currentPitch)
        currentTeamIndex = index
        currentPitchSide = 'even'
      } 
    })

    if (currentPitchIndex != -1)
      currentVariantIndex = this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].variant.findIndex(variant => variant.variant_id === currentVariant)
    
    console.log('Current Variant Index: ', currentVariantIndex)
    console.log('Current Pitch Side: ', currentPitchSide)

    //Parse out previous drag values - the pitch/variant from which the work is dragged
    let previousFirstDash = event.previousContainer.id.indexOf('-')
    let previousSecondDash = event.previousContainer.id.indexOf('-', previousFirstDash + 1)

    let previousIndex: number = -1
    let previousPitchIndex: number = -1
    let previousPitchSide: string = ''
    let previousPitch = Number(event.previousContainer.id.substring(0, previousFirstDash))
    let previousVariantIndex: number = -1
    let previousVariant = Number(event.previousContainer.id.substring(previousFirstDash + 1, previousSecondDash))
    let previousContainerID = event.previousContainer.id.substring(0, previousSecondDash)
    let previousTeamIndex: number = -1

    console.log(previousPitch)
    this.multiTeams.forEach((team, index) => {
      if (previousPitchIndex == -1 && team['pitches'].map(pitch => pitch.odd).filter(pitchOdd => pitchOdd != undefined).length > 0) {
        previousPitchIndex = team['pitches'].map(pitch => pitch.odd).findIndex(pitchOdd => pitchOdd.pitch_config_id === previousPitch)
        previousTeamIndex = index
        previousPitchSide = 'odd'
      }

      if (previousPitchIndex == -1 && team['pitches'].map(pitch => pitch.even).filter(pitchEven => pitchEven != undefined).length > 0) {
        previousPitchIndex = team['pitches'].map(pitch => pitch.even).findIndex(pitchEven => pitchEven.pitch_config_id === previousPitch)
        previousTeamIndex = index
        previousPitchSide = 'even'
      } 
    })

    if (previousPitchIndex != -1)
      previousVariantIndex = this.multiTeams[previousTeamIndex]['pitches'][previousPitchIndex][previousPitchSide].variant.findIndex(variant => variant.variant_id === previousVariant)

    console.log('Previous Variant Index: ', previousVariantIndex)
    //Determines current index
    if (currentPitchSide == 'odd'){
      if (event.container.id.includes('placeholder'))
        currentIndex = event.currentIndex
      else {
        if(previousPitch == currentPitch && previousVariant == currentVariant)
          currentIndex = (event.container.data.length -1) - event.currentIndex
        else
          currentIndex = (event.container.data.length) - event.currentIndex
      }
    } else if (currentPitchSide == 'even') 
      currentIndex = event.currentIndex

    // Determines previous index
    if (previousPitchSide == 'odd'){
      if (event.previousContainer.id.includes('cycle'))
        previousIndex = (event.previousContainer.data.length - 1) - event.previousIndex
      else
        previousIndex = event.previousIndex
    } else if (previousPitchSide == 'even')
      previousIndex = event.previousIndex

    //Movement
    console.log('Current Work Index: ', currentIndex)
    console.log('Previous Work Index: ', previousIndex)
    if (currentContainerID == previousContainerID){
      moveItemInArray(event.container.data, previousIndex, currentIndex)

      let pitchSave: WorkstationFormatted = this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide]
      pitchSave.TeamID = this.multiTeams[currentTeamIndex]['team_info'].team_id
      pitchSave.VersionID = this.multiTeams[currentTeamIndex]['team_info'].version_id
      this.pitchSaves.push(pitchSave)

    } else {
      //Pitch Movement Validation: from one pitch/variant to another pitch/variant
      if (event.previousContainer.id.includes('fluctuation')){
        let fluctuationLength = this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work.fluctuation.length
        if (fluctuationLength > 0)
          validMovement = false
      }

      if (this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].status == 'blocked')
        validMovement = false
      
      console.info("Movement Validation Result: ", validMovement)
      if (validMovement){
        transferArrayItem(event.previousContainer.data, event.container.data, previousIndex, currentIndex)

        //Clean up placeholder array, it should always default back to empty
        if (event.container.id.includes('placeholder')){
          let workType = event.previousContainer.id.substring(previousSecondDash + 1)
          let index = this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work[workType].length
          console.log('Current Team Index: ', this.multiTeams[currentTeamIndex])

          this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work[workType][index]
          = this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work.placeholder[0]
          
          delete this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work.placeholder[0]
        }

        console.log(this.multiTeams[currentTeamIndex])

        //Add pitch changes to array for pitches to be saved
        this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide].status = 'active'
        let openPitch: boolean = true
        this.multiTeams[previousTeamIndex]['pitches'][previousPitchIndex][previousPitchSide].variant.forEach(variant =>
          Object.keys(variant.work).forEach(key =>{
            if (key == 'cycle' || key == 'periodic' || key == 'fluctuation'){
              if (variant.work[key].length > 0)
                openPitch = false
            }
          })
        )
        if (openPitch)
          this.multiTeams[previousTeamIndex]['pitches'][previousPitchIndex][previousPitchSide].status = 'open'
      
        let pitchSave: WorkstationFormatted = this.multiTeams[currentTeamIndex]['pitches'][currentPitchIndex][currentPitchSide]
        pitchSave.TeamID = this.multiTeams[previousTeamIndex]['team_info'].team_id
        pitchSave.VersionID = this.multiTeams[previousTeamIndex]['team_info'].version_id
        this.pitchSaves.push(pitchSave)

        pitchSave = this.multiTeams[previousTeamIndex]['pitches'][previousPitchIndex][previousPitchSide]
        pitchSave.TeamID = this.multiTeams[previousTeamIndex]['team_info'].team_id
        pitchSave.VersionID = this.multiTeams[previousTeamIndex]['team_info'].version_id
        this.pitchSaves.push(pitchSave)

        
        this.updateCycleStats()
        this.calculateWorkMinHeight()
      }
    }
  }

  saveWork(){
    let updates: WorkUpdatesType[] = []
    console.log('Save: ', this.pitchSaves)

    this.pitchSaves.forEach(pitch => {
      pitch.variant.forEach(variant => {
        Object.keys(variant.work).forEach(key =>{
          let sortOrder = 0
          if (key == 'cycle' || key == 'periodic' || key == 'fluctuation'){
            variant.work[key].forEach(work => {
              sortOrder += 1
              let update: WorkUpdatesType = {
                pitchConfigID: pitch.pitch_config_id,
                workID: work.work_id,
                variantID: variant.variant_id,
                sortOrder: sortOrder,
                pitchID: pitch.pitch_id,
                variantName: variant.variant_name,
                workType: work.type,
                workDescription: work.description,
                TeamID: pitch.TeamID,
                VersionID: pitch.VersionID
              }
              updates.push(update)
            })
          }
        })
      })
    })

    if(updates.length != 0){
      this.workService.saveTeamWork(this.currentNav.plant_name, 0, 0, updates).subscribe(result => {
        this.getTeamWork();
        if(result["Body"] > 0){
          let message = "Saved";
          let action = "";
          this._snackbar.open(message, action, {duration: 2000,});
        }
      });
    }
    this.pitchSaves = [];
  }

  calculateWorkMinAfterCollapse(pitch:WorkstationFormatted){
    this.isExpanded = false;
    pitch.isexpanded = false;
    this.calculateWorkMinHeight();
  }

  calculateWorkMinAfterExpand(pitch:WorkstationFormatted){
    this.isExpanded = true;
    pitch.isexpanded = true;
    this.calculateWorkMinHeight();
  }

  calculateWorkMinHeight(){
    console.info('Calculate Work Min Height')

    let placeholderPadding : number = 5;
    let minHeight: number;
    let calcMinHeight: number;
    let maxPeriodicWork : number = 0;
    let expansionHeader : number = 5;
    let containerHeader : number = 240;

    if (this.maxTaktTime > 60 && this.maxTaktTime < 1000)
      placeholderPadding = 20
    else if (this.maxTaktTime > 1000)
      placeholderPadding = 60
    
    console.log("max takt time", this.maxTaktTime)
    minHeight = (this.maxTaktTime + 1);    
    
    this.multiTeams.forEach(team=> {
      team.pitches.forEach(pitch=> {
        Object.keys(pitch).forEach(key => {
          if (pitch[key] != undefined && pitch[key].hasOwnProperty('variant')){
            //work through variant's work to calculate needed height for a pitch
            pitch[key].variant.forEach(variant=> {
              if (pitch[key].takt_time + placeholderPadding > minHeight)
                minHeight = pitch[key].takt_time + placeholderPadding

              calcMinHeight = 0;
              maxPeriodicWork = 0;
  
              calcMinHeight = calcMinHeight + variant.work.fluctuation.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
    
              if (variant.work.periodic.length > 0)
                maxPeriodicWork = Math.max(...variant.work.periodic.map(out => out.time_to_complete))

              calcMinHeight = calcMinHeight + maxPeriodicWork
              pitch[key].expandedhgt = variant.work.periodic.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)

    
              calcMinHeight += variant.work.cycle.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
            
              if (pitch[key].isexpanded == true){
                this.isExpanded = true
                calcMinHeight = (calcMinHeight - maxPeriodicWork) + expansionHeader + pitch[key].expandedhgt;
              }
            
              calcMinHeight = calcMinHeight + placeholderPadding

              if  (calcMinHeight > minHeight)
                minHeight = calcMinHeight
            })
          }
        })
      })
    })

    this.minHeight = Math.ceil(this.multiplierValue * + minHeight).toString() + "px";
    console.info('MIN Variant Height: ', this.minHeight)
    this.containerminHeight = Math.ceil(this.multiplierValue * + minHeight + containerHeader).toString() + "px";
    console.info('MIN Pitch Height: ', this.containerminHeight)
    this.emptyMinHeight = Math.ceil(this.multiplierValue * + (minHeight) + containerHeader). toString() + "px";
    console.info('EMPTY MIN Height: ', this.emptyMinHeight)

    this.separatorHeight = Math.ceil(((this.multiplierValue *+ minHeight) + this.headerHeight)*this.numberOfSides).toString()+"px"
  }

  onChangeEditStatus(view_only: boolean){
    this.viewOnly = view_only
    this.viewOnlyUserConfirm = !view_only
  }

  onChangeVersion(version_id: string){
    this.currentNav.version_id = +version_id;
    this.getTeamWork();
  }

  canDeletePitch(pPitch: WorkstationFormatted): boolean {
    let canDelete: boolean = true
    //looks for subassemblies tied to the pitch if subassemblyies are found cannot delete
    if (pPitch.type == 'pitch'){
      this.pitchPairs.forEach(pitch =>{
        Object.keys(pitch).forEach(key => {
          if (pitch[key] != undefined && pitch[key].hasOwnProperty('pitch_id'))
            if (pitch[key].pitch_id == pPitch.pitch_id && pitch[key].type == 'subassembly')
              canDelete == false
        })
      })
    }
    return canDelete
  }

  createPitchPairs(odd_workstations, even_workstations ){
    odd_workstations.map((workstation, index) => {
      let pitchPair: PitchPair = {
        odd: (workstation != undefined) ? workstation : undefined,
        even: (even_workstations[index] != undefined) ? even_workstations[index] : undefined
      }
      this.pitchPairs.push(pitchPair)

      if (workstation.hasOwnProperty('variant')){
        workstation.variant.forEach(v =>{
          console.log('Workstation: ', workstation.pitch_config_id)
          console.log('Variant: ', v)
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString())
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationFluctuationArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationFluctuationArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPeriodicArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationPeriodicArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationCycleWasteArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationCycleWasteArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPlaceholderArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')
        })
      }

      if(even_workstations[index] != undefined && even_workstations[index].hasOwnProperty('variant')){
        even_workstations[index].variant.forEach(v =>{
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString())
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationFluctuationArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationFluctuationArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPeriodicArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationPeriodicArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationCycleWasteArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationCycleWasteArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPlaceholderArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')
        })
      }
    })
    console.log(this.pitchPairs)
  }

  onSaveWorkHeader(){
    this.saveStatus.message = 'Saved'
    this.saveStatus.color = 'green'
    this.saveWork()
  }

  onSaveWorkPitch(){
    this.saveStatus.message = 'Auto Saved'
    this.saveStatus.color = '#0277BD'
    this.saveWork()
  }

  onChangeDisplayErgoStatus(event: boolean) {
    this.displayErgoScore = event
  }

  getNumberOfEmptyContainers(pitches: PitchPair[]):number[]{
    let numberOfEmptyContainers: number[] = []

    if (pitches.length < 7){
      numberOfEmptyContainers = Array(Math.ceil(7-pitches.length)).fill(0).map((x,i)=>i);
    }

    return numberOfEmptyContainers
  }

  openPrint(){
    console.debug('Open Print Dialog');
    const dialogRef = this.printDialog.open(PrintDialog, {
      width: '500px',
      data: {
        'plant_name': this.currentNav.plant_name,
        'team_list': this.selectedTeams,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug("Closed Print Dialog!!!", result);
    });  
  }
  
}
