<h3 mat-dialog-title>Transfer Pitch Work</h3>

  <div mat-dialog-content>
    <form [formGroup]="copyGroup">
  
      <mat-form-field class="card-dialog">
        <mat-select id="copy-pitch" matInput cdkTextareaAutosize placeholder="From Pitch" formControlName="f_copyPitch" required>
          <mat-option *ngFor="let pitch of pitchesToCopy" [value]="pitch.pitch_config_id">
            {{pitch.pitch_id}}<span *ngIf="pitch.type == 'subassembly'">&nbsp;({{pitch.subassembly_id}})</span>&nbsp;&nbsp;{{pitch.pitch_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="card-dialog">
        <mat-select id="copy-pitch" matInput cdkTextareaAutosize placeholder="To Pitch" formControlName="f_updatePitch" required>
          <mat-option *ngFor="let pitch of pitchesToUpdate" [value]="pitch.pitch_config_id">
            {{pitch.pitch_id}}<span *ngIf="pitch.type == 'subassembly'">&nbsp;({{pitch.subassembly_id}})</span>&nbsp;&nbsp;{{pitch.pitch_name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
  
      
    </form>
  </div>
  
  
  <div mat-dialog-actions>
    <button [disabled]="copyGroup.status === 'INVALID'" mat-raised-button color="primary" (click)="submitCopy()">Submit</button>
    <span class="spacer"></span>
    <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
  </div>