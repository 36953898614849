import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'version-dialog',
  templateUrl: './version-dialog.component.html',
  styleUrls: ['./version-dialog.component.scss']
})
export class VersionDialog  {

  versionName: string;

  constructor(
    public dialogRef: MatDialogRef<VersionDialog>,
    @Inject(MAT_DIALOG_DATA) public versionData: VersionDialogData,
  ) 
  { 
    console.debug(versionData)
    versionData.version_name? this.versionName = versionData.version_name : this.versionName = ''
  }

  onNoClick(): void {
    const confirmResponse = {
      response: false  
    }
    console.log(confirmResponse)
    this.dialogRef.close(confirmResponse)
  }
  
  onConfirm(): void {
    const confirmResponse = {
      response: true  
    }
    console.log(confirmResponse)
    this.dialogRef.close(confirmResponse)
  }
}


export interface VersionDialogData {
  version_name:string;
} 
