<div class="fx-row-start-start">
    <div><mat-icon>print</mat-icon></div>
    <div><h3 mat-dialog-title>Print</h3></div>
</div>

<div mat-dialog-content class="printDialogContent">
    <form [formGroup]="printFormGroup">
        <mat-form-field class="card-dialog" >
            <mat-select matInput cdkTextareaAutosize placeholder = "Print Single Sided" formControlName="form_printSingleSided">
                <mat-option *ngFor="let option of sideOptions" [value]="option.value" [disabled] = "twoSided === 0">   
                    {{option.view}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class = "card-dialog">
            <mat-select matInput cdkTextareaAutosize placeholder= "Print Empty Pitches" formControlName="form_printEmptyPitches">
                <mat-option *ngFor="let option of emptyOptions" [value]="option.value">   
                    {{option.view}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        
        <mat-form-field class = "card-dialog">
            <mat-select matInput cdkTextareaAutosize placeholder= "Print Ergo Scores" formControlName="form_printErgoScore">
                <mat-option *ngFor="let option of ergoOptions" [value]="option.value">   
                    {{option.view}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </form>
    <div *ngIf="loading" class="fx-column-start-center">
        <div><mat-spinner diameter="50"></mat-spinner></div>
        <div>Generating PDF ...</div>
    </div>
    <div *ngIf="!loading && downloadComplete" class="fx-column-start-center">
        <div>
            A PDF of this team has been created. 
            Click the button below to download the file to your computer.
        </div>
        <div><button mat-raised-button color="basic" type="button" (click)="download()">Download PDF</button></div>
    </div>
    <div *ngIf="!loading && downloadError" class="fx-column-start-center">
        <div>
            An error occurred while trying to create the PDF file. Please contact support.
        </div>
    </div>
</div>
<div mat-dialog-actions class="fx-row-end-end" style="padding-top: 15px;">
    <div>
        <button mat-raised-button color="primary" type="button" (click)="getPDF()">Submit Print</button>
        <button mat-raised-button color="primary" type="button" (click)="onNoClick()">Close</button>
    </div>
</div>
