import { Component, OnInit , OnDestroy, HostListener } from '@angular/core';
import { WorkService } from './services/work.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Observable, Subscription} from 'rxjs';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { pluck, switchMap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ViewModeDialog } from './team-header/view-mode-dialog.component';     
import { WorkUpdatesType, Navigation, TeamWorkFormatted, TeamFormatted, 
         WorkstationFormatted, PitchPair, Work } from '../shared/models/type-list';
import { NavService } from '../nav/services/nav.service';

@Component({
  selector: 'app-team-work',
  templateUrl: './team-work.component.html',
  styleUrls: ['./team-work.component.scss']
})

export class TeamWorkComponent implements OnInit, OnDestroy {
  @HostListener('window:unload', ['$event'])
  workFormattedSubscription: Subscription
  twoSided: Boolean = false
  minHeight: string
  containerminHeight : string
  emptyMinHeight: string
  isExpanded : boolean = false
  viewOnly: boolean = true
  viewOnlyUserConfirm: boolean = false
  baseVariant: number
  multiplierValue: number
  currentNav: Navigation = {
    plant_name: '',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  }
  teamFormattedInfo: TeamFormatted
  oddWorkstations: WorkstationFormatted[]
  evenWorkstations: WorkstationFormatted[]
  pitchPairs: PitchPair[] = []
  pitchesDisplayed: PitchPair[] = []
  workstationAllListArray: string[] = []
  workstationParentListArray: string[] = []
  workstationFluctuationArray: string[] = []
  workstationPeriodicArray: string[] = []
  workstationCycleWasteArray: string[] = []
  workstationPlaceholderArray: string[] = []
  pitchSaves: WorkstationFormatted[] = []
  saveStatus: any = {
    message: "",
    color: "primary"
  }
  displayErgoScore: Boolean = false;

  constructor(
    private workService: WorkService,
    private route: ActivatedRoute,
    private _snackbar: MatSnackBar,
    private viewModeDialog: MatDialog,
    private navService: NavService
  ){
    this.currentNav.plant_name = this.route.snapshot.paramMap.get('plant_name');
    this.currentNav.team_id = +this.route.snapshot.paramMap.get('team')
    this.currentNav.version_id = +this.route.snapshot.paramMap.get('version');
  }
  
  canDeactivate(): boolean {
    if (this.saveStatus.message == 'Unsaved Changes'){
      const result = window.confirm('Unsaved Changes will be lost.');
      return result
    }
    return true;
  }
    

  ngOnInit() {
    this.navService.setCurrentTeamID(+this.currentNav.team_id);
    this.getTeamWork();
  }

  ngOnDestroy() {
    if (this.workFormattedSubscription) this.workFormattedSubscription.unsubscribe();
  }

  @HostListener('window:beforeunload', ['$event'])
  onBeforeUnload() {
    if (this.pitchSaves.length > 0){
      return false
    } 
  }

  getTeamWork(){
    this.pitchPairs = []

    let workObservable: Observable<TeamWorkFormatted> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
       this.workService.getWorkFormatted(this.currentNav.team_id, this.currentNav.version_id)),
       pluck('Body'));

    if (this.workFormattedSubscription) this.workFormattedSubscription.unsubscribe();     
       
    this.workFormattedSubscription = workObservable.subscribe((out) => { 
      console.info(out)
      this.teamFormattedInfo = out.team_version_info

      this.createPitchPairs(out.odd_workstations, out.even_workstations)
      this.pitchesDisplayed = this.pitchPairs

      //multiplier value for determining workblock, pitch, and variant box sizes
      if  (this.teamFormattedInfo.takt_time > 60 &&  this.teamFormattedInfo.takt_time < 1000)
          this.multiplierValue = 3
      else if(this.teamFormattedInfo.takt_time > 1000) 
          this.multiplierValue = 1
      else
        this.multiplierValue = 15
      
      this.calculateWorkMinHeight()
      console.log('finish')
    }) 
  }

  updateCycleStats(){
    let variantIndex: number = 0
    let baseIndex: number = 0
    let baseCycleTime: number = 0
    let variantCycleTime: number = 0
    let baseVariantID: number = this.teamFormattedInfo.base_variant_id

    this.teamFormattedInfo.actual_processes = 0
    this.teamFormattedInfo.base_cycle_time = 0
    this.teamFormattedInfo.theoretical_processes = 0

    this.teamFormattedInfo.variant_cycle_times.forEach(variant=>{
      variant.actual_processes = 0
      variant.cycle_time = 0
      variant.theoretical_processes = 0

      this.pitchPairs.forEach(pitch => {
        Object.keys(pitch).forEach(key => {
          if (pitch[key] != undefined && pitch[key].hasOwnProperty('variant') && !['waterspider','kitter','repacker'].includes(pitch[key]['type']) ){
            variantIndex = pitch[key].variant.findIndex(v => v.variant_id === variant.variant_id)
            if (variantIndex != -1){
              variantCycleTime = 0 
              variantCycleTime = pitch[key].variant[variantIndex].work.cycle.map(work => work.time_to_complete).reduce((prev,curr) => prev + curr, 0)
              if (variantCycleTime > 0){
                variant.cycle_time += variantCycleTime
                variant.actual_processes += 1
                variant.theoretical_processes += variantCycleTime/pitch[key].takt_time
              }
              if (variant.variant_id == baseVariantID){
                baseCycleTime = 0
                baseCycleTime = pitch[key].variant[variantIndex].work.cycle.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
                if (baseCycleTime > 0){
                  this.teamFormattedInfo.actual_processes += 1
                  this.teamFormattedInfo.base_cycle_time += baseCycleTime
                  this.teamFormattedInfo.theoretical_processes += baseCycleTime/pitch[key].takt_time
                }
              }
            } else {
              baseIndex = pitch[key].variant.findIndex(b => b.variant_id === this.teamFormattedInfo.base_variant_id)
              if (baseIndex != -1){
                baseCycleTime = 0
                baseCycleTime = pitch[key].variant[baseIndex].work.cycle.map(work => work.time_to_complete).reduce((prev,curr) => prev + curr, 0)
                if (baseCycleTime > 0){
                  variant.actual_processes += 1
                  variant.cycle_time += baseCycleTime
                  variant.theoretical_processes += baseCycleTime/pitch[key].takt_time
                }
              }
            }
          }
        })
      })
      
      if (variant.variant_id == this.teamFormattedInfo.base_variant_id)
        this.teamFormattedInfo.theoretical_processes = variant.theoretical_processes
    })
  }


  drop(event: CdkDragDrop<string[]>) {
    console.log(event)
    let validMovement: boolean = true

    if (!this.viewOnlyUserConfirm && this.viewOnly) {
      this.viewModeDialog.open(ViewModeDialog, {
        width: '500px'
      });
      this.viewOnlyUserConfirm = true
    }

    //Parse out current drop values - the pitch/variant into which the work is dropped
    let currentFirstDash = event.container.id.indexOf('-')
    let currentSecondDash = event.container.id.indexOf('-', currentFirstDash + 1)

    let currentIndex: number = -1
    let currentPitchSide: string = ''
    let currentPitchIndex: number = -1
    let currentPitch = Number(event.container.id.substring(0, currentFirstDash))
    let currentVariantIndex: number = -1
    let currentVariant = Number(event.container.id.substring(currentFirstDash + 1, currentSecondDash))
    let currentContainerID = event.container.id.substring(0, currentSecondDash) 
    
    currentPitchIndex = this.pitchPairs.map(pitch => pitch.odd).findIndex(oddPitch => oddPitch.pitch_config_id === currentPitch)
    if (currentPitchIndex == -1){
      currentPitchIndex = this.pitchPairs.map(pitch => pitch.even).findIndex(evenPitch => evenPitch.pitch_config_id === currentPitch)
      if (currentPitchIndex != -1)
        currentPitchSide = 'even'
    } else
      currentPitchSide = 'odd'
    
    if (currentPitchIndex != -1)
      currentVariantIndex = this.pitchPairs[currentPitchIndex][currentPitchSide].variant.findIndex(variant => variant.variant_id === currentVariant)

    //Parse out previous drag values - the pitch/variant from which the work is dragged
    let previousFirstDash = event.previousContainer.id.indexOf('-')
    let previousSecondDash = event.previousContainer.id.indexOf('-', previousFirstDash + 1)

    let previousIndex: number = -1
    let previousPitchIndex: number = -1
    let previousPitchSide: string = ''
    let previousPitch = Number(event.previousContainer.id.substring(0, previousFirstDash))
    let previousVariantIndex: number = -1
    let previousVariant = Number(event.previousContainer.id.substring(previousFirstDash + 1, previousSecondDash))
    let previousContainerID = event.previousContainer.id.substring(0, previousSecondDash)
    
    previousPitchIndex = this.pitchPairs.map(pitch => pitch.odd).findIndex(oddPitch => oddPitch.pitch_config_id === previousPitch)
    if (previousPitchIndex == -1){
      previousPitchIndex = this.pitchPairs.map(pitch => pitch.even).findIndex(evenPitch => evenPitch.pitch_config_id === previousPitch)
      if (previousPitchIndex != -1)
        previousPitchSide = 'even'
    } else
      previousPitchSide = 'odd'
    
      if (previousPitchIndex != -1)
        previousVariantIndex = this.pitchPairs[previousPitchIndex][previousPitchSide].variant.findIndex(variant => variant.variant_id === previousVariant)
    
    //Determines current index
    if (currentPitchSide == 'odd'){
      if (event.container.id.includes('placeholder'))
        currentIndex = event.currentIndex
      else {
        if(previousPitch == currentPitch && previousVariant == currentVariant)
          currentIndex = (event.container.data.length -1) - event.currentIndex
        else
          currentIndex = (event.container.data.length) - event.currentIndex
      }
    } else if (currentPitchSide == 'even') 
      currentIndex = event.currentIndex

    // Determines previous index
    if (previousPitchSide == 'odd'){
      if (event.previousContainer.id.includes('cycle') || event.previousContainer.id.includes('waste'))
        previousIndex = (event.previousContainer.data.length - 1) - event.previousIndex
      else
        previousIndex = event.previousIndex
    } else if (previousPitchSide == 'even')
      previousIndex = event.previousIndex

    //Movement
    console.log('Current: ', currentIndex)
    console.log('Previous: ', previousIndex)
    if (currentContainerID == previousContainerID){
      moveItemInArray(event.container.data, previousIndex, currentIndex)
      if (!this.viewOnly){
        this.pitchSaves.push(this.pitchPairs[currentPitchIndex][currentPitchSide])
        this.saveStatus.message = 'Unsaved Changes'
        this.saveStatus.color = 'red'
      }
    } else {
      //Pitch Movement Validation: from one pitch/variant to another pitch/variant
      if (event.previousContainer.id.includes('fluctuation')){
        let fluctuationLength = this.pitchPairs[currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work.fluctuation.length
        if (fluctuationLength > 0)
          validMovement = false
      }

      if (this.pitchPairs[currentPitchIndex][currentPitchSide].status == 'blocked')
        validMovement = false
      
      console.info("Movement Validation Result: ", validMovement)
      if (validMovement){
        transferArrayItem(event.previousContainer.data, event.container.data, previousIndex, currentIndex)

        //Clean up placeholder array, it should always default back to empty
        if (event.container.id.includes('placeholder')){
          let workType = event.previousContainer.id.substring(previousSecondDash + 1)
          let index = this.pitchPairs[currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work[workType].length
          this.pitchPairs[currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work[workType][index] = this.pitchPairs[currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work.placeholder[0]
          delete this.pitchPairs[currentPitchIndex][currentPitchSide].variant[currentVariantIndex].work.placeholder[0]
        }


        //Add pitch changes to array for pitches to be saved
        this.pitchPairs[currentPitchIndex][currentPitchSide].status = 'active'
        if (!this.viewOnly){
          this.pitchSaves.push(this.pitchPairs[currentPitchIndex][currentPitchSide])
          this.pitchSaves.push(this.pitchPairs[previousPitchIndex][previousPitchSide])
          this.saveStatus.message = 'Auto Saved'
          this.saveStatus.color = '#0277BD'
          this.saveWork()
        }
        this.updateCycleStats();
        this.calculateWorkMinHeight();
      }
    }
  }

  saveWork(){
    let updates: WorkUpdatesType[] = []

    this.pitchSaves.forEach(pitch => {
      pitch.variant.forEach(variant => {
        Object.keys(variant.work).forEach(key =>{
          let sortOrder = 0
          if (key == 'cycle' || key == 'periodic' || key == 'fluctuation'){
            this.sortWork(variant.work[key])

            variant.work[key].forEach(work => {

              let update: WorkUpdatesType = {
                pitchConfigID: pitch.pitch_config_id,
                workID: work.work_id,
                variantID: variant.variant_id,
                sortOrder: work.sort_order,
                pitchID: pitch.pitch_id,
                variantName: variant.variant_name,
                workType: work.type,
                workDescription: work.description
              }
              updates.push(update)
            })
          }
        })
      })
    })
    console.log(updates)

    if(updates.length != 0){
      this.workService.saveTeamWork(this.currentNav.plant_name, this.teamFormattedInfo.team_id, this.teamFormattedInfo.version_id, updates).subscribe(result => {
        this.getTeamWork();
        if(result["Body"] > 0){
          let message = "Saved";
          let action = "";
          this._snackbar.open(message, action, {duration: 2000,});
        }
      });
    }
    this.pitchSaves = [];
  }

  sortWork(work: Work[]){
    console.log(work)
    let sortOrder: number = 0
    work.forEach((w, i) => {
      if (w.type == 'waste'){
        sortOrder = Math.floor(sortOrder) + 0.5
      } else {
        sortOrder = Math.floor(sortOrder) + 1
      }
      w.sort_order = sortOrder
    })
    console.log(work)
  }

  calculateWorkMinAfterCollapse(pitch:WorkstationFormatted){
    this.isExpanded = false;
    pitch.isexpanded = false;
    this.calculateWorkMinHeight();
  }

  calculateWorkMinAfterExpand(pitch:WorkstationFormatted){
    this.isExpanded = true;
    pitch.isexpanded = true;
    this.calculateWorkMinHeight();
  }

  calculateWorkMinHeight(){
    console.info('Calculate Work Min Height')

    let placeholderPadding : number = 5;
    let minHeight: number;
    let calcMinHeight: number;
    let maxPeriodicWork : number = 0;
    let expansionHeader : number = 5;
    let containerHeader : number = 240;

    if (this.teamFormattedInfo.takt_time > 60 && this.teamFormattedInfo.takt_time < 1000)
      placeholderPadding = 20
    else if (this.teamFormattedInfo.takt_time > 1000)
      placeholderPadding = 60
 
    minHeight = (this.teamFormattedInfo.takt_time + 1);
    this.pitchPairs.forEach(pitch => {
      Object.keys(pitch).forEach(key => {
        if (pitch[key] != undefined && pitch[key].hasOwnProperty('variant')){
          //work through variant's work to calculate needed height for a pitch
          pitch[key].variant.forEach(variant=> {
            if (pitch[key].takt_time + placeholderPadding > minHeight)
              minHeight = pitch[key].takt_time + placeholderPadding

            calcMinHeight = 0;
            maxPeriodicWork = 0;
  
            calcMinHeight = calcMinHeight + variant.work.fluctuation.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
    
            if (variant.work.periodic.length > 0)
              maxPeriodicWork = Math.max(...variant.work.periodic.map(out => out.time_to_complete))

            calcMinHeight = calcMinHeight + maxPeriodicWork
            pitch[key].expandedhgt = variant.work.periodic.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)

    
            calcMinHeight += variant.work.cycle.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
            
            if (pitch[key].isexpanded == true){
              this.isExpanded = true
              calcMinHeight = (calcMinHeight - maxPeriodicWork) + expansionHeader + pitch[key].expandedhgt;
            }
            
            calcMinHeight = calcMinHeight + placeholderPadding

            if  (calcMinHeight > minHeight)
              minHeight = calcMinHeight
          })
        }
      })
    })
    this.minHeight = Math.ceil(this.multiplierValue * + minHeight).toString() + "px";
    console.info('MIN Variant Height: ', this.minHeight)
    this.containerminHeight = Math.ceil(this.multiplierValue * + minHeight + containerHeader).toString() + "px";
    console.info('MIN Pitch Height: ', this.containerminHeight)
    this.emptyMinHeight = Math.ceil(this.multiplierValue * + (minHeight) + containerHeader). toString() + "px";
    console.info('EMPTY MIN Height: ', this.emptyMinHeight)
  }

  onChangeEditStatus(view_only: boolean){
    this.viewOnly = view_only
    this.viewOnlyUserConfirm = !view_only
  }

  onChangeVersion(version_id: string){
    this.currentNav.version_id = +version_id;
    this.getTeamWork();
  }

  canDeletePitch(pPitch: WorkstationFormatted): boolean {
    let canDelete: boolean = true
    //looks for subassemblies tied to the pitch if subassemblyies are found cannot delete
    if (pPitch.type == 'pitch'){
      this.pitchPairs.forEach(pitch =>{
        Object.keys(pitch).forEach(key => {
          if (pitch[key] != undefined && pitch[key].hasOwnProperty('pitch_id'))
            if (pitch[key].pitch_id == pPitch.pitch_id && pitch[key].type == 'subassembly')
              canDelete = false
        })
      })
    }
    return canDelete
  }

  createPitchPairs(odd_workstations, even_workstations ){
    odd_workstations.map((workstation, index) => {
      let pitchPair: PitchPair = {
        odd: (workstation != undefined) ? workstation : undefined,
        even: (even_workstations[index] != undefined) ? even_workstations[index] : undefined
      }
      this.pitchPairs.push(pitchPair)

      if (workstation.hasOwnProperty('variant')){
        workstation.variant.forEach(v =>{
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString())
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationFluctuationArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationFluctuationArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPeriodicArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationPeriodicArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationCycleWasteArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationCycleWasteArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPlaceholderArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')
        })
      }

      if(even_workstations[index] != undefined && even_workstations[index].hasOwnProperty('variant')){
        even_workstations[index].variant.forEach(v =>{
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString())
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationFluctuationArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationFluctuationArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPeriodicArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationPeriodicArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationCycleWasteArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationCycleWasteArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPlaceholderArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')
        })
      }
    })
    console.log(this.pitchPairs)
  }

  onSaveWorkHeader(){
    this.saveStatus.message = 'Saved'
    this.saveStatus.color = 'green'
    this.saveWork()
  }

  onSaveWorkPitch(){
    this.saveStatus.message = 'Auto Saved'
    this.saveStatus.color = '#0277BD'
    this.saveWork()
  }

  onChangeDisplayErgoStatus(event: boolean) {
    console.group("on change display ergo")
    console.log(event)
    this.displayErgoScore = event
    if (this.displayErgoScore){
      console.log("in if")
      this.getTeamWork()
    }
    console.groupEnd()
  }
  
}