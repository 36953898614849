import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PlantType, Team, TeamNode } from 'src/app/shared/models/type-list';
import  { TeamService } from 'src/app/team-edit/services/team.service';
import { NavService } from 'src/app/nav/services/nav.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subscription} from 'rxjs';
import { GenericLambdaReturn } from '@gea-mes/cognito';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { pluck, switchMap, filter, take} from 'rxjs/operators';

@Component({
  selector: 'app-migrate-dialog',
  templateUrl: './migrate-dialog.component.html',
  styleUrls: ['./migrate-dialog.component.scss']
})
export class MigrateDialog implements OnInit {

  avNavigation: TeamNode[] = []
  avPlants: any[] = []
  avLines: string[] = []
  avSublines: string[] = []
  avTeams: any[] = []

  fg_Migrate: FormGroup

  plantName: string = ''
  line: string = ''
  subline: string = ''
  teamID: number = 0

  navSub$: Subscription
  

  constructor(
    public dialogRef: MatDialogRef<MigrateDialog>,
    private teamSvc: TeamService,
    private navSvc: NavService,
    private fb_Migrate: FormBuilder,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
       
    this.navSvc.getNavList().pipe(filter(out=>out != null), take(1)).subscribe(
      (out) => {
          this.avNavigation = out.Body;
          this.avPlants = this.avNavigation.map(plant => {return ({plant: plant.search, display: plant.description})});
          console.log('Navigation: ', this.avNavigation)
        }
    );

    

    this.fg_Migrate = this.fb_Migrate.group({
      f_Plant: (this.plantName == null)? '': this.plantName,
      f_Line: (this.line == null)? '': this.line,
      f_Subline: (this.subline == null)? '': this.subline,
      f_Team: (this.teamID == 0)? 0: this.teamID
    })

    this.fg_Migrate.valueChanges.subscribe((f_Values) => {
      this.plantName = f_Values.f_Plant,
      this.line = f_Values.f_Line,
      this.subline = f_Values.f_Subline,
      this.teamID = f_Values.f_Team
    })

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getLines(event){
    this.avLines = []
    console.log(event)
    this.avNavigation.forEach(plant => {
      if (plant.search == event){
        this.avLines = plant.children.map(line => line.search)
      }
    })
    console.log(this.avLines)
  }

  getSublines(event){
    this.avSublines = []
    console.log(event)
    this.avNavigation.forEach(plant => {
      if (plant.search == this.plantName) {
        plant.children.forEach(line => {
          if (line.search == event){
            line.children.forEach(child=> {
              console.log('Line: ', line)
              if (child.subline == ''){
                console.log('Subline: ',child.subline)
                this.avTeams.push({id: child.search, name: child.description})
              } else {
                console.log('Subline:', child.subline)
                this.avSublines.push(child.search)
              }
            })

          }
        })
      }            
    })
  }

  getTeams(event){
    this.avTeams = []
    console.log(event)
    this.avNavigation.forEach(plant => {
      if (plant.search == this.plantName){
        plant.children.forEach(line =>{
          if (line.search == this.line){
            line.children.forEach(subline =>{
              if (subline.search == event){
                this.avTeams = subline.children.map(team =>{
                  return ({id: team.search, name: team.description})
                })
              }
              console.log(this.avTeams)
            })
          }
        })
      }
    })
  }

  migrateTeam(){
    if (this.fg_Migrate.status == 'VALID') {
      this.teamSvc.migrateTeam(this.plantName, this.line, this.subline, this.teamID).subscribe(out => {})
      this.dialogRef.close()
    } else {
      console.error('Invalid teamFormGroup, was not caught in form control error handling.')
    }
  }

}
