import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean>
}

@Injectable()
export class CanDeactivateGuard  {
  component: Object;
  route: ActivatedRouteSnapshot;
  constructor() { }

  canDeactivate(component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      return component.canDeactivate ? component.canDeactivate() : true
  }
}
