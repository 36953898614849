import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, CdkDrag, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.workstationOne.sort(function(a,b){return a.sortPriority - b.sortPriority});
    this.workstationTwo.sort(function(a,b){return a.sortPriority - b.sortPriority});
    this.workstationThree.sort(function(a,b){return a.sortPriority - b.sortPriority});

    this.ws1Stats = this.calculateStats(this.workstationOne);
    this.ws2Stats = this.calculateStats(this.workstationTwo);
    this.ws3Stats = this.calculateStats(this.workstationThree);
  }

  ws1Stats = {};
  ws2Stats = {};
  ws3Stats = {};

  taktTime = 22.34;

  task1 = new WorkTask(1,"Connect wire to pressure sensor","cycle",3,4.32);
  task2 = new WorkTask(2,"Install p-sensor to sump","cycle",3,3.22);
  task3 = new WorkTask(3,"Get and attach pulley wheel & felt screw to offset","cycle",3,7.62);
  task4 = new WorkTask(4,"Replace pressure sensor box with full (140)","periodic",2,3.29);
  task5 = new WorkTask(5,"Pulley wheel container (2400)","periodic",2,3.19);
  task6 = new WorkTask(6,"Fluctuation","fluctuation",1,2.19);
  task7 = new WorkTask(7,"Fluctuation","fluctuation",1,1.87);
  task8 = new WorkTask(8,"Fluctuation","fluctuation",1,2.32);
  task9 = new WorkTask(9,"Hose container (500)","periodic",2,4.28);
  task10 = new WorkTask(10,"Refill tape dispenser (125)","periodic",2,5.26);
  task11 = new WorkTask(1,"Feed drain hose through panel","cycle",3,4.28);
  task12 = new WorkTask(1,"Attach drain hose to pump","cycle",3,3.85);
  task13 = new WorkTask(2,"Tighten hose clamp","cycle",3,5.22);

  workstationOne = [
    this.task1, this.task2, this.task3, this.task4, this.task5, this.task6
  ];

  workstationTwo = [
    this.task7, this.task9, this.task11
  ];

  workstationThree = [
    this.task8, this.task10, this.task12, this.task13
  ];

  drop(event: CdkDragDrop<string[]>) {

    console.log("WorkTask ID: " + event.item.dropContainer.data[event.previousIndex].id + " moved from (Container ID: " + event.previousContainer.id + " Index: " + event.previousIndex + ") to (Container ID: " + event.container.id + " Index: " + event.currentIndex + ")")

    if (event.previousContainer === event.container) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }

    this.workstationOne.sort(function(a,b){return a.sortPriority - b.sortPriority});
    this.workstationTwo.sort(function(a,b){return a.sortPriority - b.sortPriority});
    this.workstationThree.sort(function(a,b){return a.sortPriority - b.sortPriority});

    this.ws1Stats = this.calculateStats(this.workstationOne);
    this.ws2Stats = this.calculateStats(this.workstationTwo);
    this.ws3Stats = this.calculateStats(this.workstationThree);
  }

  getWorkTypeClass(workType: string){
    switch(workType){
      case "cycle":
        return "cycle-box";
      case "periodic":
        return "periodic-box";
      case "fluctuation":
        return "fluctuation-box";
      default:
        return "example-box";
    }
  }

  sortPredicate(index: number, item: CdkDrag<number>) {
    return true;
  }

  calculateStats(workstation:Array<WorkTask>){
    var cycleTime = 0;
    var periodicTime = 0;
    var fluctuationTime = 0;

    for(var i = 0; i < workstation.length; i++){
      if(workstation[i].workType == "cycle"){
        cycleTime = cycleTime + workstation[i].time;
      }else if(workstation[i].workType == "periodic"){
        periodicTime = periodicTime + workstation[i].time;
      }else if(workstation[i].workType == "fluctuation"){
        fluctuationTime = fluctuationTime + workstation[i].time;
      }
    }

    return {"cycle":cycleTime.toFixed(2), "periodic":periodicTime.toFixed(2), "fluctuation":fluctuationTime.toFixed(2), "total":(cycleTime+periodicTime+fluctuationTime).toFixed(2)};
  }
}


class WorkTask{
  id:number;
  title:string;
  workType:string;
  sortPriority:number;
  time:number;

  constructor(id:number, title:string, workType:string, sortPriority:number, time:number){
    this.id = id;
    this.title = title;
    this.workType = workType;
    this.sortPriority = sortPriority;
    this.time = time;
  }
}