<div class = "fx-row-header" style = "margin:8px">
    <a mat-list-item routerLink="/viewteams/" class="navlink">Teams View Dashboard</a>
    <span>
        <button
            id="print" 
            color="primary" 
            mat-raised-button  
            class="btn center blue mobile-btn" 
            type="button" 
            label="Print"
            (click) = "openPrint()">
                Print
        </button>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <button
            id="save" 
            color="primary" 
            mat-raised-button  
            class="btn center blue mobile-btn" 
            type="button" 
            label="Save"
            (click) = "saveWork()">
                Save
        </button>
 </span>
    
</div>

<h3>
    <div>
        Total Theoretical Processes: {{theoreticalProcesses | number: '1.0-1'}}
    </div>
    <div>
        Total Actual Processes: {{actualProcesses}}
    </div>
</h3>

<div class="scrolling-wrapper fx-row">

    <div class="multi-team-row">
        <div class = "multi-team-column" *ngFor = "let team of multiTeams">

            <team-header *ngIf = "team.team_info"
                [plant_name] = "team.team_info.plant_name"
                [team_info] = "team.team_info"
                [save_status] = "'false'"
                [hide_buttons] = "'true'" 
                [multi_team] = "'true'"
                [numberOfPitches] = "numberOfPitches"
                (changedVersion) = "onChangeVersion($event)"> 
            </team-header>
            
            <div *ngIf="team.team_info" cdkDropList id = "{{team.team_info.team_name}}" [cdkDropListData] = "workstationIDArray" [cdkDropListConnectedTo] = "workstationParentListArray">
                <div class="fx-row">
                    <div *ngFor = "let workstation of team.pitches">
                        <div class="fx-column">
                            <div>
                                <div *ngIf = "workstation['odd'] === undefined || (workstation['odd'] | json) === '{}'" class="empty-container" [ngStyle]="{'min-height': emptyMinHeight}">
                                </div>
                                <div *ngIf = "workstation['odd'] !== undefined && (workstation['odd'] | json) !== '{}'" style="page-break-after:auto;page-break-inside:avoid;" class="model-variant-container fx-column" [ngStyle]="{'height': containerminHeight,'width': workstation['odd'] != undefined ? workstation['odd'].minimumwidth : '230px'} ">
                                    <pitch
                                        [pitch] = "workstation['odd']"
                                        [viewOnly] = "'true'"
                                        [multi_team] = "'true'"
                                        [plantName] = "plant_name"
                                        [team_id] = "team.team_info.team_id"
                                        [team_name] = "team.team_info.team_name"
                                        [version_id] = "team.team_info.version_id"
                                        [version_name] = "team.team_info.version_name"
                                        [canDelete] = "'false'"
                                        [minHeight] = "minHeight"
                                        [side] = "'odd'"
                                        [workstationParentListArray] = "workstationParentListArray"
                                        [workstationPlaceholderArray] = "workstationPlaceholderArray"
                                        [workstationFluctuationArray] = "workstationFluctuationArray"
                                        [workstationPeriodicArray] = "workstationPeriodicArray"
                                        [workstationCycleWasteArray] = "workstationCycleWasteArray"
                                        [multiplierValue] = "multiplierValue"
                                        [ergoEnabled] = "'false'"
                                        [timeUnit] = "team.team_info.takt_time_unit"
                                        (collapsedPeriodic) = "calculateWorkMinAfterCollapse(workstation['odd'])"
                                        (expandedPeriodic) = "calculateWorkMinAfterExpand(workstation['odd'])"
                                        (dropped) = "drop($event)">
                                    </pitch>
                                </div>
                            </div>
                            <br>
                            <div>
                                <div *ngIf ="workstation['even'] !== undefined && (workstation['even'] | json) !== '{}'" style="page-break-after:auto;page-break-inside:avoid;" class="model-variant-container fx-column" [ngStyle]="{'height': containerminHeight,'width': workstation['even'] != undefined ? workstation['even'].minimumwidth : '230px'}">
                                    <pitch
                                        [pitch] = "workstation['even']"
                                        [viewOnly] = "'true'"
                                        [multi_team] = "'true'"
                                        [plantName] = "plant_name"
                                        [team_id] = "team.team_info.team_id"
                                        [team_name] = "team.team_info.team_name"
                                        [version_id] = "team.team_info.version_id"
                                        [version_name] = "team.team_info.version_name"
                                        [canDelete] = "'false'"
                                        [minHeight] = "minHeight"
                                        [side] = "'even'"
                                        [workstationParentListArray] = "workstationParentListArray"
                                        [workstationPlaceholderArray] = "workstationPlaceholderArray"
                                        [workstationFluctuationArray] = "workstationFluctuationArray"
                                        [workstationPeriodicArray] = "workstationPeriodicArray"
                                        [workstationCycleWasteArray] = "workstationCycleWasteArray"
                                        [multiplierValue] = "multiplierValue"
                                        [ergoEnabled] = "'false'"
                                        (collapsedPeriodic) = "calculateWorkMinAfterCollapse(workstation['even'])"
                                        (expandedPeriodic) = "calculateWorkMinAfterExpand(workstation['even'])"
                                        (dropped) = "drop($event)">
                                    </pitch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngFor = "let number of team.empty_containers">
                        <div class="empty-container" [ngStyle]="{'min-height': emptyMinHeight}"></div>
                    </div>
                    <div class="separator" [ngStyle]="{'height': separatorHeight}"></div>
                </div>
            </div>
        </div>
    </div>
</div>