<h3 mat-dialog-title>{{teamName}} - {{versionName}} - {{pitchFormType}} 
  &nbsp;&nbsp; 
  <span [ngStyle]="{'color': serviceColor, 'font-size': '150%', 'font-weight': 'bold'}" [innerHTML] = "serviceAttribute"></span>
</h3>
<div mat-dialog-content>
  <form [formGroup]="pitchFormGroup">

    <mat-form-field class="card-dialog">
      <mat-select id="type" matInput cdkTextareaAutosize placeholder="Type" formControlName="form_pitchType" required>
        <mat-option *ngFor="let type of typeOptions" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="card-dialog">
      <input id="pitch" type="text" matInput cdkTextareaAutosize placeholder="Pitch" formControlName="form_pitchID"
        required>
    </mat-form-field>
    <div *ngIf="pitchFormGroup.get('form_pitchID').invalid && 
            pitchFormGroup.get('form_pitchID').errors && 
            (pitchFormGroup.get('form_pitchID').dirty || pitchFormGroup.get('form_pitchID').touched)">
      <small class="error" *ngIf="pitchFormGroup.get('form_pitchID').hasError('required')"> Pitch is Required </small>
      <small class="error"
        *ngIf="!pitchFormGroup.get('form_pitchID').hasError('required') && pitchFormGroup.get('form_pitchID').hasError('lengthThree')">
        Pitch must be at least 3 characters </small>
      <small class="error"
        *ngIf="!pitchFormGroup.get('form_pitchID').hasError('lengthThree') && pitchFormGroup.get('form_pitchID').hasError('rightThreeNumber')">
        Pitch must end with a value in the range 000 to 999 </small>
    </div>

    <mat-form-field class="card-dialog" *ngIf="this.pitchType == 'subassembly'">
      <mat-select id="subassembly" matInput cdkTextareaAutosize placeholder="Subassembly" formControlName="form_subassemblyID">
        <mat-option *ngFor="let option of subassemblyIDOptions" [value]="option">
          {{option}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="card-dialog" *ngIf="this.pitchType == 'pitch'">
      <mat-select id="status" matInput cdkTextareaAutosize placeholder="Status" formControlName="form_status">
        <mat-option *ngFor="let pStatus of pitchStatuses" [value]="pStatus" [disabled]="(pStatus === 'active' && status !== 'active') || (pStatus !== 'active' && status ==='active')">
          {{pStatus}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="card-dialog" *ngIf="this.pitchType == 'waterspider' || this.pitchType == 'kitter' || this.pitchType == 'repacker'">
      <mat-select id="pitch ties" matInput cdkTextareaAutosize placeholder="Pitches" formControlName="form_service_ties" multiple>
        <mat-option *ngFor="let pitch of availablePitches" [value]="pitch.pitch_config_id" disabled="true">
          {{pitch.pitch_id}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="card-dialog">
      <input id="pitchname" type="text" matInput cdkTextareaAutosize placeholder="Name" formControlName="form_pitchName">
    </mat-form-field>

    <mat-form-field class="card-dialog">
      <input id="taktTime" type="number" matInput cdkTextareaAutosize placeholder="TaktTime"
        formControlName="form_taktTime" required>
    </mat-form-field>
    <div *ngIf="pitchFormGroup.get('form_taktTime').invalid && 
        pitchFormGroup.get('form_taktTime').errors && 
        (pitchFormGroup.get('form_taktTime').dirty || pitchFormGroup.get('form_taktTime').touched)">
      <small class="error" *ngIf="pitchFormGroup.get('form_taktTime').hasError('required')">
        Takt time is required.
      </small>
    </div>

    <mat-form-field class="card-dialog">
      <mat-select id="type" matInput cdkTextareaAutosize placeholder="Variants" formControlName="form_variants" multiple >
        <mat-option *ngFor="let variant of availableVariantNames" [value]="variant">
          {{variant}}
        </mat-option>
      </mat-select>
    </mat-form-field>
 <div *ngIf="pitchFormGroup.get('form_variants').invalid && 
    pitchFormGroup.get('form_variants').errors && 
    (pitchFormGroup.get('form_variants').dirty || pitchFormGroup.get('form_variants').touched)">
  <small class="error" *ngIf="pitchFormGroup.get('form_variants').hasError('required')">
    Variant is required.
  </small>
</div>
    <mat-form-field class="card-dialog">
      <mat-select id="type" matInput cdkTextareaAutosize placeholder="Flags" formControlName="form_flags" multiple >
        <mat-option *ngFor="let flag of availableFlagIDs" [value]="flag">
          {{flag}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
<div *ngIf="isBusy">

  <mat-spinner></mat-spinner>

</div>

<div mat-dialog-actions class="fx-row-space-evenly-center">
  <div *ngIf="pitchFormGroup.status === 'VALID' && !unique" class="fx-row-space-evenly-center" [style]="{'color': 'red', 'font-weight': 'bold', 'padding-bottom': '20px'}">
    A pitch can only be created or updated if a pitch with the same ID does not exist for the team.
  </div>
  <button *ngIf="pitchFormType == 'Create New Pitch'" [disabled]="pitchFormGroup.status === 'INVALID' || isBusy" mat-raised-button
    color="primary" (click)="createPitch()">Create</button>
  <button *ngIf="pitchFormType == 'Edit Pitch'" [disabled]="pitchFormGroup.status === 'INVALID' || isBusy" mat-raised-button color="primary" (click)="updatePitch()">Update</button>
  <button *ngIf="canDelete" [disabled] = "isBusy" mat-raised-button color="warn" (click)="deletePitch()">Delete Pitch</button>
  <span class="spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>