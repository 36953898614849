<div style="width: 100%" class="fx-row-none">
  <div> <!-- style="min-width: 200px; max-width: 1000px;">-->
    <mat-tree [dataSource]="teamSource" [treeControl]="treeControl" class="example-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node">
            <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          <button mat-raised-button color="primary" (click)="navigate(node.plant_name, node.line, node.subline, node.team_id, node.version_id, node.display_name)">Team Leader {{node.description}}</button>
        </li>
      </mat-tree-node>

      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.description">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <button mat-button color="primary" matTreeNodeToggle [ngClass]="node.plant ? 'tree-plant-node' : 'tree-section-node'">{{node.description}}</button>
          </div>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
