import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WorkblockDialog } from '../workblock-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Work, EditVariantWork, VariantFormatted} from 'src/app/shared/models/type-list'
import { DefaultColors, WorkTypes } from 'src/app/shared/models/enum-list';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WorkService } from '../services/work.service';

@Component({
  selector: 'variant',
  templateUrl: './variant.component.html',
  styleUrls: ['./variant.component.scss']
})

export class Variant implements OnChanges {
 workObject = {
    cycle               : [],
    periodic            : [],
    fluctuation         : [],
    placeholder         : [],
    max_cycle_sort      : 0,
    max_periodic_sort   : 0,
    max_fluctuation_sort: 0,
  }
 variant_work: EditVariantWork
 maxPeriodicHeight: string = '3px'
 placeholderHeight: string = '5px'
 panelOpenState: boolean = false
 expandedHeight: string
  @Input()  
    variant: VariantFormatted = {
      work: this.workObject,
      variant_name: "",
      variant_id: 0,
      placeholderhgt: "10px"
    };
  @Input()
    viewOnly = false; 
  @Input()
    multi_team = false;
  @Input()
    plantName = "";  
  @Input()
    team_id = 0;
  @Input()
    team_name = "";
  @Input()
    version_id = 0;
  @Input()
    pitch_config_id = 0;
  @Input()
    pitch_id = "";
  @Input()
    subassembly_id = "";
  @Input()
    pitch_status = "";
  @Input()
    minHeight = "0px";
  @Input()
    side = "";
  @Input()
    workstationParentListArray = [];
  @Input()
    workstationPlaceholderArray = [];
  @Input()
    workstationFluctuationArray = [];
  @Input()
    workstationPeriodicArray = [];
  @Input()
    workstationCycleWasteArray = [];
  @Input()
    multiplierValue = 15;
  @Input()
    taktTime = 0;
  @Input()
    periodicCount = 0;
  @Input()
    timeUnit = "s";
  

  @Output() createdWork           = new EventEmitter();
  @Output() savedWork             = new EventEmitter();
  @Output() loadAvailableRegions  = new EventEmitter();
  @Output() workDrop              = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() collapsePeriodic      = new EventEmitter();
  @Output() expandPeriodic        = new EventEmitter();
  @Output() reload                = new EventEmitter();

  constructor(
    private workDialog: MatDialog,
    private workService: WorkService,
   ) { }

  ngOnChanges(): void {
    if(this.variant!= undefined){ 
      this.workService.variant_work$.subscribe(out => this.variant_work = out)
      if (this.variant_work != null){
        if(this.variant_work.pitch_config_id == this.pitch_config_id && this.variant_work.variant_id == this.variant.variant_id)
          this.createWork(this.variant_work.current_type)
      }
      this.placeholderHeight = this.getPlaceholderHeight()
    }
  }
  
  createWork(current_type?: string, looping?: boolean){
    this.savedWork.emit()
    let defaultWork: Work = {
      work_id: 0,
      type: WorkTypes.Cycle,
      region: 0,
      description: "",
      time_to_complete: 0,
      variant: this.variant.variant_id.toString(),
      variant_id: this.variant.variant_id,
      frequency: 0,
      sort_order: 0,
      color: DefaultColors.Default_Cycle,
      time_unit: this.timeUnit,
      service_config_id: 0
    }
    current_type? defaultWork.type = current_type : defaultWork.type = WorkTypes.Cycle;
    if (defaultWork.type != WorkTypes.Cycle){
      switch(defaultWork.type){
        case WorkTypes.Periodic: defaultWork.color = DefaultColors.Default_Periodic; break;
        case WorkTypes.Fluctuation: defaultWork.color = DefaultColors.Default_Fluctuation; break;
      }
    }
      
      console.info('Create New Work')
      const dialogRef = this.workDialog.open(WorkblockDialog, {
        width: '500px',
        data: 
          { 
            formType: 'Create New Work',
            pitch_config_id: this.pitch_config_id,
            pitch_id: this.pitch_id,
            pitch_status: this.pitch_status,
            plant_name: this.plantName,
            team_id: this.team_id,
            subassembly_id: this.subassembly_id,
            version_id: this.version_id,
            work: defaultWork,
            max_cycle_sort: this.variant.work.max_cycle_sort,
            max_periodic_sort: this.variant.work.max_periodic_sort,
            max_fluctuation_sort: this.variant.work.max_fluctuation_sort,
            variant_id: this.variant.variant_id,
            variant_name: this.variant.variant_name
          }       
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined){
          let variantWork: EditVariantWork = {
            team_id: this.team_id,
            pitch_config_id: this.pitch_config_id,
            variant_id: this.variant.variant_id,
            current_type: result.current_type
          }
          this.workService.variant_work$.next(variantWork)
          this.reload.emit()
          this.loadAvailableRegions.emit()   
        }
        else 
          this.workService.variant_work$.next(null)
      
      });
    }
    
  drop(event: CdkDragDrop<string[]>) {
    console.log('Variant DROP')
    this.workDrop.emit(event)
  }

  calculateCycleTime():number {
    return this.variant.work.cycle.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
  }

  calculateProgress(): number{
    let cycle_time = this.calculateCycleTime()
    let periodic_time = this.variant.work.periodic.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
    let fluctuation_time = this.variant.work.fluctuation.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
    let base_total_time = (cycle_time + periodic_time + fluctuation_time )
      
      return (base_total_time/this.taktTime) * 100;
  }


  containsPeriodicWork(): boolean{
    var found: boolean = false;
    if (this.variant.work.periodic.length > 0) 
        found = true
    return found
  }


  calculateAfterCollapse(){
    this.collapsePeriodic.emit()

  }

  calculateAfterExpand(){
    this.expandPeriodic.emit()
  }

  getMaxPeriodicHeight(){
    let max: number = 0;

    this.variant.work.periodic.forEach(work => {
      if(max == 0)
        max = work.time_to_complete;
      if(work.time_to_complete > max)
        max = work.time_to_complete
    });
    return Math.ceil(this.multiplierValue * + max).toString() + "px";
  }

  getMaxTimePeriodicWork(){
    let max: Work;

    this.variant.work.periodic.forEach(work => {
      if(max == undefined || max == null)
        max = work;
      if(work.time_to_complete > max.time_to_complete)
        max = work
    });

    return max;
  }

  getTeamWork() {
    this.createdWork.emit()
  }

  saveWork() {
    this.savedWork.emit()
  }

  calculateTaktLinePosition(){
    let taktLinePosition: string;
    if (this.side == 'odd')
      taktLinePosition = (10 + Math.ceil(this.multiplierValue * this.taktTime)).toString() + "px";
    else
      taktLinePosition = (-10 + Math.ceil(-this.multiplierValue * this.taktTime)).toString() + "px";
      
    return taktLinePosition;
  }

  getPlaceholderHeight(){
    let placeholderHeight : number = 5
    let workHeight : number = 0
    let periodicMaxTime : number = 0
    let periodicHeight :number = 0
    var variantHeight: number = parseInt(this.minHeight)
    let maxPeriodicWork: Work

    workHeight = workHeight + (this.variant.work.fluctuation.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0))  
    this.variant.work.fluctuation.forEach(work =>{
      workHeight = workHeight + Math.ceil(this.multiplierValue * + work.time_to_complete) ;
    })

    maxPeriodicWork = this.getMaxTimePeriodicWork()
    if (maxPeriodicWork)
      periodicMaxTime = this.getMaxTimePeriodicWork().time_to_complete

    this.variant.work.periodic.forEach(work =>{
      periodicHeight = periodicHeight + Math.ceil(this.multiplierValue * + work.time_to_complete) ;
    })

    workHeight = workHeight + Math.ceil(this.multiplierValue * + periodicMaxTime) ;
        
    this.variant.work.cycle.forEach(work =>{
      workHeight = workHeight + Math.ceil(this.multiplierValue * + work.time_to_complete) ;
    })

    if (this.panelOpenState == true)   
      placeholderHeight = variantHeight - workHeight - periodicHeight  + Math.ceil(this.multiplierValue * + periodicMaxTime) -47;
    else
      placeholderHeight = variantHeight - workHeight

    return placeholderHeight.toString()+"px"
  }

  calculateVariantHeight(status: string){
    if (status == 'open')
      this.panelOpenState = true
    else
      this.panelOpenState = false
    this.placeholderHeight = this.getPlaceholderHeight()
  }

  getExpandedHeight(){
    let expandedHeight: number
    let titleHeight: number = Math.ceil(this.multiplierValue *+ 2)

    this.variant.work.periodic.forEach(work =>{
      expandedHeight += Math.ceil(this.multiplierValue * + work.time_to_complete) ;
    })

    this.expandedHeight = expandedHeight.toString() + 'px'
  
    return titleHeight.toString() + 'px' 
  }

  onReload(){
    this.reload.emit()
  }
}
