import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'version-notice',
  templateUrl: './version-notice.component.html',
  styleUrls: ['./version-notice.component.scss']
})
export class VersionNotice  {

  activeVersion: string;
  versionName: string;

  constructor(
    public dialogRef: MatDialogRef<VersionNotice>,
    @Inject(MAT_DIALOG_DATA) public versionData: VersionNoticeData,
  ) 
  { 
    console.debug(versionData)
    versionData.active_version? this.activeVersion = versionData.active_version : this.activeVersion = ''
    versionData.version_name? this.versionName = versionData.version_name : this.versionName = ''
  }

  onNoClick(): void {
    const confirmResponse = {
      response: false  
    }
    this.dialogRef.close(confirmResponse)
  }
  
  onConfirm(): void {
    const confirmResponse = {
      response: true  
    }
    this.dialogRef.close(confirmResponse)
  }
}


export interface VersionNoticeData {
  active_version: string;
  version_name: string;
}
