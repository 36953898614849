<div 
    id = "{{work.work_id}}-work" 
    class = "{{work.type + '-box'}}" 
    *ngIf = "work.work_id" 
    [ngStyle] = "{'height': height,'min-height': minHeight, 'background-color': backgroundColor}" 
    (dblclick) = "!viewOnly && !multi_team && updateWork()" 
    (mouseup) = "resetWorkHeight()" 
    (mousedown) = "expandWorkHeight()"  
    cdkDrag>
        {{work.description}}
        <span *ngIf="work.type == 'periodic'">
            (f={{work.frequency}})
        </span>
        <div class="time-to-complete">
            {{work.time_to_complete}}{{work.time_unit}}
        </div> 
</div>