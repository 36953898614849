import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { first } from 'rxjs/operators';
import { Team } from 'src/app/shared/models/type-list';
import { WorkService } from '../services/work.service';

export interface TeamLog {
  timestamp: Date;
  userid: string;
  action: string [];
  user_name: string;
}

@Component({
  selector: 'app-log-dialog.component',
  templateUrl: './log-dialog.component.html',
  styleUrls: ['./log-dialog.component.scss']
})
export class LogDialog implements OnInit {
  dataSource: MatTableDataSource<TeamLog>;
  loadedRecords: number=0;
  loading:boolean=true;
  records:number=100;

  displayedColumns: string[] = ["Timestamp", "User", "Action"];

  constructor(
              private workService: WorkService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<LogDialog>,
              @Inject(MAT_DIALOG_DATA) public inData:Team
              ) {}

  ngOnInit(): void {
    this.getLog();
  }

  getLog() {
    this.workService.getTeamLog(this.inData.team_id, this.inData.version_id, this.records).pipe(first()).subscribe( out => { 
      console.debug("getLog", out);
      this.dataSource = out['Body'];
      this.loadedRecords = out['Body'].length;
      this.loading = false;

    });
  }

  onNoClick():void {
    this.dialog.closeAll();
  }

}
