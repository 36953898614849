import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeaMesLoginComponent, GeaMesCognitoAuthGuard, GeaMesCognitoModule, GeaMesAuthInterceptor, COGNITO_CONFIG, APPLICATION_NAME, ALLOW_SCREENAUTH, APIGATEWAY_CONFIG } from '@gea-mes/cognito';
import { GEA_MES_NAVBAR_COGNITO_CONFIG,GEA_MES_NAVBAR_APPLICATION_NAME,GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE,GEA_MES_NAVBAR_MENU_SERVICE,GEA_MES_NAVBAR_ENV_PROD_STATUS, GEA_ENVIROMENT, NavItem} from '@gea-mes/navbar';
import { LocationStrategy, PathLocationStrategy, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { appinfo } from './appinfo';
import { BoardComponent } from './board/board.component';
import { TeamWorkComponent } from './work/team-work.component';
import { TeamSelect } from './nav/team-select/team-select.component';
import { TeamEditComponent } from './team-edit/team-edit.component';
import { PitchEditComponent } from './team-edit/pitch/pitch-edit.component';
import { VariantEditComponent } from './team-edit/variant/variant-edit.component';
import { VersionEditComponent } from './team-edit/version/version-edit.component';
import { RegionEditComponent } from './team-edit/region-edit/region-edit.component';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { BehaviorSubject } from 'rxjs';
import { CanDeactivateGuard } from './work/services/can-deactivate-guard.service';
import { TeamSelectEditComponent } from './nav/team-select-edit/team-select-edit.component';
import { TeamSelectMultiComponent } from './nav/team-select-multi/team-select-multi.component';
import { MultiTeamWorkComponent } from './multi-team/multi-team-work/multi-team-work.component';

const routes: Routes = [
  {path: 'login', component: GeaMesLoginComponent},
  {
      path: '', canActivate: [GeaMesCognitoAuthGuard],
      children: [
      {path: '', redirectTo:'/plants', pathMatch: 'full'},
      {path: 'board', component: BoardComponent},
      {path: 'plants', component: TeamSelect },
      {path: 'plants/:plant_name', component: TeamSelect},
      {path: 'plants/:plant_name/:line', component: TeamSelect},
      {path: 'plants/:plant_name/:line/:subline', component: TeamSelect},
      {path: 'lines/:plant_name', component: TeamSelect},
      {path: 'teams', component: TeamSelectEditComponent},
      {path: 'teams/:plant_name/:line', component: TeamSelectEditComponent},
      {path: 'teams/:plant_name', component: TeamSelectEditComponent},
      {path: 'teams/:plant_name/:line/:subline', component: TeamSelectEditComponent},
      {path: 'viewteams', component: TeamSelectMultiComponent},
      {path: 'viewteams/:plant_name/:line/:subline', component: TeamSelectMultiComponent},
      {path: 'teamview', component: TeamSelect},
      {path: 'teamwork/:plant_name/:line/:team/:version', component:TeamWorkComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'teamwork/:plant_name/:line/:subline/:team/:version', component:TeamWorkComponent, canDeactivate: [CanDeactivateGuard]},
      {path: 'teamedit', component: TeamEditComponent},
      {path: 'teamedit/:plant_name', component: TeamEditComponent},
      {path: 'teamedit/:plant_name/:line', component: TeamEditComponent},
      {path: 'teamedit/:plant_name/:line/:subline', component: TeamEditComponent},
      {path: 'pitchedit/:plant_name/:line/:subline/:team/:version', component: PitchEditComponent},
      {path: 'pitchedit/:plant_name/:line/:team/:version', component: PitchEditComponent},
      {path: 'variantedit/:plant_name/:line/:subline/:team', component: VariantEditComponent},
      {path: 'versionedit/:plant_name/:line/:subline/:team', component: VersionEditComponent},
      {path: 'regionedit/:plant_name/:line/:subline/:team', component: RegionEditComponent},
      {path: 'multiteamwork', component: MultiTeamWorkComponent}
      ]
  },
  {path: '**', redirectTo:'/plants' },
  ];

  export function getBaseHref(platformLocation: PlatformLocation): string {
    return platformLocation.getBaseHrefFromDOM();
  }

@NgModule({
  imports: [GeaMesCognitoModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    { provide: COGNITO_CONFIG, useValue: environment.cognitoOptions },    
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: GeaMesAuthInterceptor, multi: true },
    { provide: APPLICATION_NAME, useValue: appinfo.name.toUpperCase() },
    { provide: APIGATEWAY_CONFIG, useValue: environment.apiGateway },
    { provide: ALLOW_SCREENAUTH, useValue: environment.AllowScreenAuth },
    { provide: GEA_MES_NAVBAR_APPLICATION_NAME,useValue:appinfo.name.toUpperCase()}, 
    { provide: GEA_MES_NAVBAR_COGNITO_CONFIG, useValue: environment.cognitoOptions }, 
    { provide: GEA_MES_NAVBAR_COGNITO_AUTH_SERVICE, useClass: GeaMesCognitoAuthService },
     { provide: GEA_MES_NAVBAR_ENV_PROD_STATUS, useValue: environment.production },
     { provide: GEA_ENVIROMENT, useValue: environment },
     { provide: GEA_MES_NAVBAR_MENU_SERVICE, useValue: new BehaviorSubject<NavItem[]>([]) },
     CanDeactivateGuard    

  ]
})
export class AppRoutingModule { }
