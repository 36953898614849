import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, Subscription} from 'rxjs';
import { Version, LineTeam, Navigation, TeamNode } from 'src/app/shared/models/type-list';
import { TeamService } from '../services/team.service';
import { filter, pluck, switchMap, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LogDialog } from '../../work/team-header/log-dialog.component';
import { TeamVersionDialog } from './team-version-dialog.component';
import { NavService } from 'src/app/nav/services/nav.service';
import { DeleteDialogComponent } from '../../shared/delete-dialog.component'
import * as dayjs from 'dayjs';

@Component({
  selector: 'version-edit',
  templateUrl: './version-edit.component.html',
  styleUrls: ['./version-edit.component.scss']
})

export class VersionEditComponent implements OnInit, OnDestroy {
  displayLine: string
  displayVersionColumns: string[] = ['version_name', 'demand', 'num_pitches','two_sided', 'takt_time', 'active', 'base_variant_name', 'updated_date', 'actions']
  displayPlant: string
  lineTeam: LineTeam
  plantName: string
  line: string
  subline: string
  team: string
  teamID: number
  versionSubscription: Subscription
  versionList: Version[] = []
  plantDisplayName: string
  activeVersion: Version;

  constructor(
    private teamService: TeamService,
    private route: ActivatedRoute,
    private router: Router,
    private versionDialog: MatDialog,
    private logDialog: MatDialog,
    private navService: NavService
  ) { 
      this.plantName = this.route.snapshot.paramMap.get('plant_name');
      this.line = this.route.snapshot.paramMap.get('line')
      this.team = this.route.snapshot.paramMap.get('team');
      this.subline = this.route.snapshot.paramMap.get('subline')
      this.displayLine = this.line
      if (this.subline != '')
        this.displayLine = this.displayLine + ' - ' + this.subline
  }

  ngOnInit(): void {
    this.navService.getCurrentNav().pipe(
      filter(out => out != null), // Filter out empty values
      take(1) // Take only the first value that passes the above filter
      ).subscribe(
        (out:Navigation) => {
          this.plantDisplayName = out.plant_display;
        }
      );
    
    
    this.teamService.line_team$.subscribe(out =>{
      this.lineTeam = out
      if (this.lineTeam == null || this.lineTeam == undefined)
        this.router.navigate(['teams']);
      else
        this.getVersionList()
    });
  }

  ngOnDestroy(): void {
    
  }

  getVersionList(){
    let version : Version
    let versionObservable: Observable<Version[]> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
       this.teamService.getVersions(this.lineTeam.team_id)),
       pluck('Body'));

    if (this.versionSubscription) this.versionSubscription.unsubscribe();     
       
    this.versionSubscription = versionObservable.subscribe((out) => { 
      this.versionList = out
      console.log(this.versionList)
      this.activeVersion = this.versionList.filter(version => version.active == 1)[0]
      console.log('Active: ', this.activeVersion)
    });
  }

  createVersion(){

    const dialogRef = this.versionDialog.open(TeamVersionDialog, {
      width: '500px',
      data: 
      { 
        plant_name: this.plantName,
        site_config_id: this.lineTeam.site_config_id,
        team_id: this.lineTeam.team_id,
        team_name: this.lineTeam.team_name,
        takt_time_unit: this.lineTeam.takt_time_unit,
        form_type: 'Create New Version',
        active_version: this.activeVersion
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.versionList = []
      this.getVersionList()
    });
  }


  getPerDeletedDate(d){
    let t:string
    console.log(' Get new date ')
    t = dayjs(d).add(7,'day').format('MM-DD-YYYY');
    return t
  }
  updateVersion(version: Version){

    const dialogRef = this.versionDialog.open(TeamVersionDialog, {
      width: '500px',
      data: 
      { 
        plant_name: this.plantName,
        site_config_id: this.lineTeam.site_config_id,
        team_id: this.lineTeam.team_id,
        team_name: this.lineTeam.team_name,
        version_id: version.version_id,
        version_name: version.version_name,
        demand: version.demand,
        num_pitches: version.num_pitches,
        two_sided: version.two_sided,
        takt_time: version.takt_time,
        active: version.active,
        base_variant_id: version.base_variant_id,
        takt_time_unit: this.lineTeam.takt_time_unit,
        form_type: 'Update Version',
        active_version: this.activeVersion
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.versionList = []
      this.getVersionList()
    });
  }

  gotoPitchList(version: Version){
    this.teamService.version$.next(version)
    console.log('Line Team: ', this.lineTeam)
    this.router.navigate(['pitchedit/', this.plantName, this.lineTeam.line, this.lineTeam.subline, this.lineTeam.team_name, version.version_name]);
  }
 
  deleteVersion(version: Version){
    this.teamService.version$.next(version)
    console.log('Delete Version : ', version)
    const dialogRef = this.versionDialog.open(DeleteDialogComponent, {
      width: '500px',
      data: 
      { 
        plant_name: this.plantName,
        team_name: version.team_name,
        version_name: version.version_name,
        form_type: 'deleteVersion'
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.action == 'delete') 
        this.teamService.deleteVersion(this.plantName, version.team_id, version.version_id,version.version_name).subscribe(out => {
          console.log('this version was removed  ', out)
          this.versionList = []
          this.getVersionList()
        })
    });

  }

  undeleteVersion(version: Version){
    this.teamService.version$.next(version)
    console.log('UnDelete Version : ', version)


    this.teamService.undeleteVersion(this.plantName, version.team_id, version.version_id,version.version_name).subscribe(out => {
          console.log('this version was undelete  ', out)
          this.versionList = []
          this.getVersionList()

    });

  }

  duplicateVersion(version: Version){
    const dialogRef = this.versionDialog.open(TeamVersionDialog, {
      width: '500px',
      data: 
      { 
        plant_name: this.plantName,
        site_config_id: this.lineTeam.site_config_id,
        team_id: this.lineTeam.team_id,
        team_name: this.lineTeam.team_name,
        version_id: version.version_id,
        version_name: version.version_name,
        demand: 0,
        num_pitches: version.num_pitches,
        two_sided: version.two_sided,
        takt_time: version.takt_time,
        active: 0,
        base_variant_id: version.base_variant_id,
        form_type: 'Duplicate Version'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.versionList = []
      this.getVersionList()
    });
  }

  goBackToTeamList(){
    this.router.navigate(['teamedit/', this.plantName, this.line, this.subline]);
  }

  openLog() {
    this.logDialog.open(LogDialog, {
      width: '750px',
      data: 
      { 
        plant_name: this.plantName,
        site_config_id: this.lineTeam.site_config_id,
        team_id: this.lineTeam.team_id,
        team_name: this.lineTeam.team_name
      }
    });
  }
}
