<h2 mat-dialog-title>
  {{formType}}
</h2>
<h4>
  Pitch: {{this.pitch_id}} 
  <span *ngIf="this.subassembly_id">
    Subassembly: {{this.subassembly_id}}
  </span>
  <br>
    Variant: {{this.variant_name}}
</h4>

<div mat-dialog-content>
    <form [formGroup]="workblockFormGroup">

      <mat-form-field class="card-dialog">
        <mat-select id = "check_type" matInput cdkTextareaAutosize placeholder = "Work Type" formControlName="form_WorkType" required> 
            <mat-option *ngFor="let type of workTypes" [value]="type.value">
                {{type.display}}
            </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="workblockFormGroup.get('form_WorkType').invalid && workblockFormGroup.get('form_WorkType').errors && (workblockFormGroup.get('form_WorkType').dirty || workblockFormGroup.get('form_WorkType').touched)">
        <small class="error"
          *ngIf = "workblockFormGroup.get('form_WorkType').hasError('required')">
              Work Type is Required
        </small>
      </div>   

      <br>
      <mat-form-field class = "card-dialog" *ngIf="!(work.type == 'fluctuation')">
        <textarea id = "check_description" matInput cdkTextareaAutosize placeholder = "Work Description" formControlName="form_WorkDescription"  required></textarea>
      </mat-form-field>
      <div *ngIf="workblockFormGroup.get('form_WorkDescription').invalid && workblockFormGroup.get('form_WorkDescription').errors && (workblockFormGroup.get('form_WorkDescription').dirty || workblockFormGroup.get('form_WorkDescription').touched)">
        <small class="error"
          *ngIf = "workblockFormGroup.get('form_WorkDescription').hasError('required')">
              Description is Required
        </small>
      </div>

      <br>
      <mat-form-field class = "card-dialog">
        <input id = "check_time" type = "number" matInput cdkTextareaAutosize placeholder = "Time To Complete (in seconds)" formControlName="form_TimeToComplete" required>
      </mat-form-field>
      <div *ngIf="workblockFormGroup.get('form_TimeToComplete').invalid && workblockFormGroup.get('form_TimeToComplete').errors && (workblockFormGroup.get('form_TimeToComplete').dirty || workblockFormGroup.get('form_TimeToComplete').touched)">
        <small class="error"
          *ngIf = "workblockFormGroup.get('form_TimeToComplete').hasError('required')">
              Time To Complete is Required
        </small>
      </div>

      <br>
      <mat-form-field class = "card-dialog" *ngIf="(work.type == 'cycle' && isServicePitch)">
        <mat-select id="check_serviceconfigid" matInput cekTextareaAutosize placeholder = "Service Pitch" formControlName="form_ServiceConfig">
          <mat-select-trigger *ngIf = "servicePitchName != '< No Region >'">
            <span *ngIf="serviceAttribute" [ngStyle]="{'color': serviceColor, 'font-size':'100%', 'font-weight': 'bold'}" [innerHTML]="serviceAttribute"></span>
            <span [ngStyle]="{'color': serviceColor}"> {{servicePitchName}}</span>
          </mat-select-trigger>
            <mat-option *ngFor="let pitch of pitchList" [value]="pitch.pitch_config_id">
              <mat-select-trigger>
                <span *ngIf="pitch.service_attribute" [ngStyle]="{'color': pitch.service_color, 'font-size':'100%', 'font-weight': 'bold'}" [innerHTML]="pitch.service_attribute"></span>
                <span [ngStyle]="{'color': pitch.service_color}"> {{pitch.pitch_id}}</span>

              </mat-select-trigger>
            </mat-option>
        </mat-select>        
      </mat-form-field>



      <br>
      <mat-form-field class = "card-dialog" *ngIf="work.type == 'cycle' && teamRegion ">
        <mat-select id="check_region" matInput cekTextareaAutosize placeholder = "Region" formControlName="form_Region">
          <mat-select-trigger *ngIf = "regionName != '< No Region >'">
            <mat-icon [ngStyle]="{'color': work.color}">lens</mat-icon>{{regionName}}
          </mat-select-trigger>
            <mat-option *ngFor="let region of availableRegions" [value]="region.region_id">
              <mat-select-trigger>
                <mat-icon *ngIf = "region.region_id != 0" [ngStyle]="{'color': region.color}">lens</mat-icon>{{region.region}}
              </mat-select-trigger>
            </mat-option>
        </mat-select>
      </mat-form-field>


      <div *ngIf="work.type == 'periodic'">
        <mat-form-field class = "card-dialog">
          <input matInput type = "number" cdkTextareaAutosize placeholder = "Frequency" formControlName="form_Frequency">
        </mat-form-field>
      </div>
    </form>
</div>            
<br>
<div *ngIf="isBusy">

  <mat-spinner></mat-spinner>

</div>
<div mat-dialog-actions class="fx-row-space-evenly-center">
  <button *ngIf = "formType == 'Create New Work'" [disabled] = "workblockFormGroup.status === 'INVALID' || isBusy" mat-raised-button color="primary" (click)="createNewWorkBlock()">Create</button>
  <button *ngIf = "formType == 'Update Work'" [disabled]="isBusy" mat-raised-button color="primary" (click) = "updateWorkBlock()">Update</button>
  <button *ngIf = "formType == 'Update Work'" [disabled]="isBusy" mat-raised-button color="primary" (click) = "createNewWorkBlock()">Duplicate</button>
  <button *ngIf = "formType == 'Update Work'" mat-raised-button color="warn" (click) = "deleteWorkBlock()">Delete</button> 
  <span class = "spacer"></span>
  <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
</div>
