import { Component, EventEmitter, Input, Output, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LogDialog } from './log-dialog.component';
import { TeamService } from 'src/app/team-edit/services/team.service';
import { PrintDialog } from '../print-dialog/print-dialog.component';
import { Observable, Subscription} from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { ActivatedRoute, ParamMap, PRIMARY_OUTLET } from '@angular/router';
import { Version, Region, PitchType } from 'src/app/shared/models/type-list';
import { VersionDialog } from 'src/app/work/team-header/version-dialog.component';
import { PitchDialog } from 'src/app/team-edit/pitch/pitch-dialog.component';
import { WorkService } from '../services/work.service';
import { ErgoStatsDialog } from './ergo-stats-dialog.component';
import { Navigation } from '../../shared/models/type-list';
import { environment } from 'src/environments/environment';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { UpdateStandardWorkDialog } from './update-standardwork-dialog.component';

@Component({
  selector: 'team-header',
  templateUrl: './team-header.component.html',
  styleUrls: ['./team-header.component.scss']
})
export class TeamHeader implements OnChanges, OnDestroy {
  versionSubscription: Subscription;
  regionSubscription: Subscription;

  @Input()
    plant_name = ""
  @Input()  
    team_info = {
      team_id: 0,
      team_name: "",
      version_id: 0,
      version_name: "",
      active: 0,
      standardwork_status: "",
      num_pitches: 0,
      two_sided: 0,
      takt_time: 0,
      base_variant_id: 0,
      base_cycle_time: 0,
      variant_cycle_times: [],
      theoretical_processes: 0,
      actual_processes: 0,
      takt_time_unit: "s",
      ergo_stats: {
        green: 0,
        yellow: 0,
        red: 0,
        gray: 0
      }
    };
  @Input()
    save_status = {
      message: "",
      color: "primary"
    }
  @Input()
    hide_buttons = false;
  
  @Input()
    multi_team = false;
  @Input ()
    numberOfPitches = 0;
  @Output() changedEditStatus = new EventEmitter<boolean>();
  @Output() changedDisplayErgoStatus = new EventEmitter<boolean>();
  @Output() changedVersion = new EventEmitter<number>();
  @Output() changedActive = new EventEmitter<number>();
  @Output() savedWork = new EventEmitter();
  @Output() createdPitch = new EventEmitter();

  teamergo_add :any = {urlvalue:"",      ergo_stats: {
    green: 0,
    yellow: 0,
    red: 0,
    gray: 0
  }};
  currentNav: Navigation = {
    plant_name: '',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  }
  viewOnly: boolean = true;
  displayErgoScore: boolean = false;
  viewEditButton: string = "Enter Edit Mode";
  available_versions: Version[] = [];
  valid_versions: Version[] = [];
  selectedVersion: number;
  displayVariantColumns: string[] = ['variant_name','cycle_time','theoretical_processes','actual_processes'];
  available_regions: Region[] = []
  pitchSubscription: Subscription;
  pitchList: PitchType[] = [];
  openState: boolean;
  versionNeedupdate : boolean = false;
  
  constructor(
    private pitchDialog: MatDialog,
    private logDialog: MatDialog,
    private teamService: TeamService,
    private ergoStatsDialog: MatDialog,
    private printDialog: MatDialog,
    private versionDialog: MatDialog,
    private updateSWDialog: MatDialog,
    private route: ActivatedRoute,
    private workService: WorkService,
    private _snackbar: MatSnackBar,
  ){
    this.currentNav.subline = this.route.snapshot.paramMap.get('subline');
    this.currentNav.line = this.route.snapshot.paramMap.get('line');
  } 
  
  ngOnChanges(){
    if (this.team_info != undefined) {
        this.selectedVersion = this.team_info.version_id
        this.getAvailableVersion()
        this.getAvailableRegions()
        this.getPitchList()
        if (this.team_info.takt_time <= 1 || this.team_info.base_variant_id == 0 )
          this.versionNeedupdate = true
    } 
  }

  ngOnDestroy() {
    if (this.versionSubscription) this.versionSubscription.unsubscribe();
  }

  changeEditStatus(){
    console.log('Inside team-header', this.viewOnly)
    if(this.viewOnly){
      if (this.team_info.active == 1){
        const dialogRef = this.versionDialog.open(VersionDialog, {
          width: '500px',
          data: 
          {
            version_name: this.team_info.version_name,
          }
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result != undefined){
            if(result.response == true){
              this.viewOnly = false
              this.viewEditButton = "Enter View Mode"
            }
            else
              this.viewOnly = true
            this.changedEditStatus.emit(this.viewOnly)
          }    
        }); 
      } else {
        this.viewOnly = false
        this.viewEditButton = "Enter View Mode"
        this.changedEditStatus.emit(this.viewOnly)
      }
    }
    else {
      this.viewEditButton = "Enter Edit Mode"
      this.viewOnly = true
      console.log('here', this.viewOnly)
      this.changedEditStatus.emit(this.viewOnly)
    }

  }

  changeVersion(){
    console.log('Inside team-header', this.viewOnly)
    console.log(this.selectedVersion)
    this.changedVersion.emit(this.selectedVersion)
    if (this.viewOnly == false){
      this.changeEditStatus()
    }
  }

  saveWork(){
    this.savedWork.emit()
  }

  createPitch(){
    this.saveWork()
    
    const dialogRef = this.pitchDialog.open(PitchDialog, {
      width: '500px',
      data: 
      {
        plant_name: this.plant_name,
        team_id: this.team_info.team_id,
        team_name: this.team_info.team_name,
        version_id: this.team_info.version_id,
        version_name: this.team_info.version_name,
        takt_time: this.team_info.takt_time,
        pitch_config_id: 0,
        pitch_id: '',
        type: 'pitch',
        subassembly_id: '',
        canDelete: 'false',
        form_type: 'Create New Pitch',
        pitchList: this.pitchList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined){ 
        this.createdPitch.emit()
      }
    });
  }

  updateStandardWork() {
    const dialogRef = this.updateSWDialog.open(UpdateStandardWorkDialog, {
      width: '500px',
      data: this.team_info
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.response == true){

        this.teamService.updateStandardWork(this.plant_name, this.team_info.team_id, this.team_info.version_id).subscribe(
          (out) => {
            console.debug("Standard Work Updated");
            let message = "Standard Work Updated";
            let action = "";
            this._snackbar.open(message, action, {duration: 2000,});
    
          }
        );   

      }
    }); 


  }

  openLog() {
    console.log('Inside team-header.')
    const dialogRef = this.logDialog.open(LogDialog, {
      width: '750px',
      data: this.team_info
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("Closed Log Dialog!!!", result);
    });
  }

  openPrint() {
    console.debug('Open Print Dialog');
    const dialogRef = this.printDialog.open(PrintDialog, {
      width: '500px',
      data: {
        'plant_name': this.plant_name,
        'team_name': this.team_info.team_name,
        'team_id': this.team_info.team_id,
        'version_name': this.team_info.version_name,
        'version_id': this.team_info.version_id,
        'two_sided': this.team_info.two_sided
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.debug("Closed Print Dialog!!!", result);
    });
  }

  getAvailableVersion(){
    let versionObservable: Observable<Version[]> = this.route.paramMap.pipe(switchMap((params: ParamMap) => 
    this.teamService.getVersions(this.team_info.team_id)), 
      pluck('Body')
    );
    if (this.versionSubscription) this.versionSubscription.unsubscribe();     
       
    this.versionSubscription = versionObservable.subscribe((out) => {
      this.available_versions = out
      /* remove deleted versions */
      for (let b of this.available_versions) {
        if ( b.deleted_date == null)
            this.valid_versions.push(b)
      }
      console.log(' The list of valid versions ',this.valid_versions)
    });
  }

  roundToWhole(n: number): number {
    return(Math.ceil(n))
  }

  getAvailableRegions(){
    let regionObservable: Observable<Region[]> = this.route.paramMap.pipe(switchMap((params: ParamMap) => 
      this.workService.getRegions(this.team_info.team_id)), 
      pluck('Body')
    );
    
    if (this.regionSubscription) this.regionSubscription.unsubscribe();     
       
    this.regionSubscription = regionObservable.subscribe((out) => {
      this.available_regions = out
    });
  }

  getPitchList() { 
    let pitchObservable: Observable<PitchType[]> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.teamService.getPitches(this.team_info.team_id, this.team_info.version_id)),
        pluck('Body'));

    if (this.pitchSubscription) {
     this.pitchSubscription.unsubscribe();     
    }   

    this.pitchSubscription = pitchObservable.subscribe((out) => {
      this.pitchList = out
    });
  }

  showErgoScore() {
    let vurl :any ="" ;
    let teaminfo_add : any = this.team_info
    this.teamergo_add.ergo_stats = this.team_info.ergo_stats
    this.teamergo_add.team_id = this.team_info.team_id
    this.teamergo_add.version_id = this.team_info.version_id
    let sublinefmt = this.currentNav.subline.replace(/\s+/g,'%20')
    let linefmt = this.currentNav.line.replace(/\s+/g,'%20')
    console.log("open ergo stats")
    vurl = environment.SSRSUrl + '%2FAll%20Sites%2FYamazumi%2FYamazumi%20Ergo%20Summary&PlantName='+this.plant_name+'&Team='+this.team_info.team_id+'&Subline='+ sublinefmt +'&LineName=' + linefmt +'&rs%3AParameterLanguage=en-US'

    this.teamergo_add.urlvalue = vurl;
    if (!this.displayErgoScore) {
      this.displayErgoScore = !this.displayErgoScore;
      console.log(this.team_info)
      const dialogRef = this.ergoStatsDialog.open(ErgoStatsDialog, {
        width: '850px',
        data: this.teamergo_add
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log("Close Ergo Stats Dialog",result);
        this.changedDisplayErgoStatus.emit(this.displayErgoScore);
      });
    }
    else{
      this.displayErgoScore = !this.displayErgoScore;
      this.changedDisplayErgoStatus.emit(this.displayErgoScore);
    }

  }

  fontSize(){
    

  }
}
