import { Component, OnDestroy,ElementRef, OnInit,AfterViewChecked} from '@angular/core';
import { NavService } from 'src/app/nav/services/nav.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable, Subscription} from 'rxjs';
import { pluck, switchMap } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as dayjs from 'dayjs'
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TeamService } from './services/team.service';
import { TeamDialog } from './team-dialog.component'
import { DeleteDialogComponent } from '../shared/delete-dialog.component'
import { PlantType, Navigation, LineTeam } from '../shared/models/type-list';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.scss']
})
export class TeamEditComponent implements OnInit, OnDestroy, AfterViewChecked {
  plantSubscription: Subscription
  plantList: PlantType[] = []
  teamSubscription: Subscription
  lineTeamList: LineTeam[] = []
  displayedTeamColumns: string[] = ['team_id','line','subline','team_name','takt_time_unit','actions']
  Teamid: any
  elem: HTMLElement;
  isNewTeamId: Boolean = false
  isEdit : Boolean = false
  currentNav: Navigation = {
    plant_name: '',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  }

  constructor( private teamService: TeamService,
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private teamDialog: MatDialog,
    private _snackbar: MatSnackBar
  ){
    this.updateCurrentNav();
  } 

  ngOnInit(): void {
    this.navService.setCurrentLine(this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline);
    this.displayPlantTeams()
    this.isNewTeamId = false
    this.isEdit = false
  }

  updateCurrentNav() {
    this.currentNav.plant_name = this.route.snapshot.paramMap.get('plant_name');
    this.currentNav.line = this.route.snapshot.paramMap.get('line');
    this.currentNav.subline = this.route.snapshot.paramMap.get('subline');
  }

  displayPlantTeams(){
    this.router.navigate(['/teamedit', this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline]);

    this.getTeamList(this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline)
    if(this.isEdit){
      this.teamService.team_id$.next(null) 
    }
  }

  getTeamList(plant_name: string, line_name: string, subline_name: string){
    let teamObservable: Observable<LineTeam[]> = 
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.teamService.getTeams(plant_name, line_name, subline_name)),
        pluck('Body'));
 
    if (this.teamSubscription) this.teamSubscription.unsubscribe();

    this.teamSubscription = teamObservable.subscribe((out) => {
      this.lineTeamList = out
      this.teamService.team_id$.subscribe((out) => {
        this.Teamid = out
        if (this.Teamid != null ){
          this.isNewTeamId = true
        } }) 
    })
    
  }

  createTeam(){
    const dialogRef = this.teamDialog.open(TeamDialog, {
      width: '500px',
      data: 
      { 
        plant_name: this.currentNav.plant_name,
        plant_display_name: this.currentNav.plant_name,
        line: '', 
        subline: '',
        team_name: '',
        line_teams: this.lineTeamList,
        form_type: 'create'
      }
    });
    
    this.isEdit = true
    dialogRef.afterClosed().subscribe(result => { 
      this.updateCurrentNav();
      this.lineTeamList = []
      this.getTeamList(this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline)
    });
  }

  updateTeam(updateTeam:LineTeam){
    const dialogRef = this.teamDialog.open(TeamDialog, {
      width: '500px',
      data: 
      { 
        plant_name: this.currentNav.plant_name,
        plant_display_name: this.currentNav.plant_name,
        line: updateTeam.line, 
        subline: updateTeam.subline,
        team_id: updateTeam.team_id,
        team_name: updateTeam.team_name,
        line_teams: this.lineTeamList,
        form_type: 'update',
        takt_time_unit: updateTeam.takt_time_unit
      }
    });
    this.teamService.team_id$.next(updateTeam.team_id) 
    this.isEdit  = true
    dialogRef.afterClosed().subscribe(result => { 
      this.lineTeamList = []
      this.getTeamList(this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline)

    });
  }

  deleteTeam(deleteTeam:LineTeam){

    let lineArray: string[] = this.lineTeamList.map(line => line.line)

    const dialogRef = this.teamDialog.open(DeleteDialogComponent, {
      width: '500px',
      data: 
      { 
        plant_name: this.currentNav.plant_name,
        plant_display_name: this.currentNav.plant_name,
        line: deleteTeam.line, 
        subline: deleteTeam.subline,
        team_name: deleteTeam.team_name,
        lines: lineArray,
        form_type: 'deleteTeam'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && result.action == 'delete') 
        this.teamService.updateTeam(deleteTeam.team_id, this.currentNav.plant_name, deleteTeam.line, deleteTeam.team_name, 'true', deleteTeam.subline).subscribe(out => {
          this.lineTeamList = []
          this.getTeamList(this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline)
        })
    });
  }

  gotoVersionList(team_info: LineTeam){
    this.teamService.team_id$.next(team_info.team_id) 
    this.teamService.line_team$.next(team_info)
    this.router.navigate(['versionedit/', this.currentNav.plant_name, team_info.line, team_info.subline, team_info.team_name]);
  }

  gotoVariantList(team_info: LineTeam){
    this.teamService.line_team$.next(team_info)
    this.teamService.team_id$.next(team_info.team_id) 
    this.router.navigate(['variantedit/', this.currentNav.plant_name, team_info.line, team_info.subline, team_info.team_name]);
  }

  gotoRegionList(team_info: LineTeam){
    this.teamService.line_team$.next(team_info)
    this.teamService.team_id$.next(team_info.team_id) 
    this.router.navigate(['regionedit/', this.currentNav.plant_name, team_info.line, team_info.subline, team_info.team_name]);
  }

  ngOnDestroy(): void {
    if (this.teamSubscription) this.teamSubscription.unsubscribe();
    if (this.plantSubscription) this.plantSubscription.unsubscribe();
    this.isNewTeamId = false
  }

  ngAfterViewChecked(): void{
    if (this.isNewTeamId ){
      this.getScroll()
      this.isNewTeamId = false
      if (!this.isEdit){
        this.teamService.team_id$.next(null) 
      }
    }
  }

  getScroll(){
    this.teamService.team_id$.subscribe(out => this.Teamid = out) 

    let id =this.Teamid
      const elem: HTMLElement|null = document.getElementById(id)
      if (elem != null){
        elem.scrollIntoView({behavior: 'smooth', block: 'center'})
      }
  }

  getTimeUnit(unit: string){
    return (unit == 'm') ? 'Minutes' : 'Seconds'
  }
}