export class EnumList {

}
export enum DragType {
    Order,
    Container
  }
  
 export enum WorkstationTypeEnum {
    Pitch = "pitch",
    Subassembly = "subassembly"
  }
  
 export enum WorkstationStatus {
    Open = "open",
    Active = "active",
    Blocked = "blocked"
  }
  
 export enum WorkTypes {
    Cycle = "cycle",
    Waste = "waste",
    Periodic = "periodic",
    Fluctuation = "fluctuation"
  }

  export enum RegionColors {
    Blue = "#96B9F3",
    Orange = "#FF9C71",
    Gray = "#C5C7C7",
    Purple = "#CD93F0",
    Brown = "#CDA399",
    Teal = "#64E9E9",
    Pink = "#F3A5D6",
    Tan = "#EACEC4",
    SkyBlue = "#49BFE3",
    DarkPink = "#FF24D7",
    DarkOrange = "#FA820A"
  }

  export enum ErgoColors {
    Green = "#00C200",
    Yellow = "#FFFF00",
    Red = "#FF0000",
    Gray = "#C5C7C7"
  }

  export enum DefaultColors {
    Default_Cycle = "#00C200",
    Default_Periodic = "#FFFF00",
    Default_Fluctuation = "#FF0000",
    Default_Waste = "#FFFFFF"
  }

  export enum ServiceColors {
    DarkRed = "#990000",
    Purple = "#9933FF",
    DarkBlue = "#0000FF",
    DarkGreen = "#00CC66"
  }

  export enum ServiceAttributes {
    WaterSpider = "&#10043;",
    Kitter = "&#10039;",
    Repacker = "&#9679;"
  }

  