<div *ngIf = "side == 'odd'">
    <div *ngIf="!multi_team" class="pitch-top">
        <mat-slide-toggle *ngIf="pitch.pitch_config_id " (change)="onBlockToggle($event)" [checked]="pitch.status === 'blocked'" [disabled]="pitch.status === 'active' || viewOnly">
        </mat-slide-toggle>
        <button class="ergo-tag" *ngIf="displayErgoScore" [disabled]="!ergoAdminFlag" [ngStyle]="{'background-color': getErgoScoreCategory(pitch.ergo_score)}" mat-mini-fab id="ergo score" (click)="!viewOnly && updateErgoScore()">
            <span *ngIf="pitch.ergo_score != -1">{{pitch.ergo_score}}</span>
            <span *ngIf="pitch.ergo_score == -1">--</span>
        </button>
        <span class="ergo-space" *ngIf="displayErgoSpace"></span>
    </div>

    <div class="fx-row-center">
        <div *ngFor = "let variant of pitch.variant">
            <div class="fx-column">
                <variant
                    [variant] = "variant"
                    [viewOnly] = "viewOnly"
                    [multi_team] = "multi_team"
                    [plantName] = "plantName"
                    [team_id] = "team_id"
                    [team_name] = "team_name"
                    [version_id] = "version_id"
                    [pitch_config_id] = "pitch.pitch_config_id"
                    [pitch_id] = "pitch.pitch_id"
                    [subassembly_id] = "pitch.subassembly_id"
                    [pitch_status] = "pitch.status"
                    [minHeight] = "minHeight"
                    [side] = "side"
                    [workstationParentListArray] = "workstationParentListArray"
                    [workstationPlaceholderArray] = "workstationPlaceholderArray"
                    [workstationFluctuationArray] = "workstationFluctuationArray"
                    [workstationPeriodicArray] = "workstationPeriodicArray"
                    [workstationCycleWasteArray] = "workstationCycleWasteArray"
                    [multiplierValue] = "multiplierValue"
                    [periodicCount] = "countPeriodicWork(variant)"
                    [taktTime] = "pitch.takt_time"
                    [timeUnit] = "timeUnit"
                    (reload) = "onReload()"
                    (savedWork) = "saveWork()"
                    (loadAvailableRegions) = "onLoadAvailableRegions()"
                    (workDrop) = "onDrop($event)"
                    (collapsePeriodic) = "onCollapsePeriodic()"
                    (expandPeriodic) = "onExpandPeriodic()">
                </variant>
            </div>
        </div>
    </div>

    <div class = "container-header">
        <div class="pitch-detail">
            <div class="service"> 
                <span *ngIf="pitch.service_pitches.length == 0">
                </span>
                <span *ngIf="pitch.service_attribute" [ngStyle]="{'color': pitch.service_color, 'font-size':'225%', 'font-weight': 'bold'}" [innerHTML]="pitch.service_attribute">  
                </span>
                <span *ngFor="let service of pitch.service_pitches" [matTooltip]="service.service_id" [ngStyle]="{'color': service.service_color, 'font-size':'130%','font-weight': 'bold'}" [innerHTML]="service.service_attribute">
                </span>
            </div>
            <div class="pitch-name">
                <button color="primary" mat-raised-button id="edit pitch" class="btn center blue mobile-btn" type="button" label="Edit Pitch" (click)="standardwork_status != 'Migrated' && !viewOnly && updatePitch()">
                    {{pitch.pitch_id}}
                    <span *ngIf="pitch.type == 'subassembly'">
                        ({{pitch.subassembly_id}})
                    </span>
                </button>
            </div>
            <div class="flag">
                <div class="fx-row-center">
                    <span style="font-size:155%; color: darkred; font-weight: bold;" *ngFor="let flag of pitch.flags" [innerHTML] = "flag.attribute">
                    </span>
                    <span *ngIf="pitch.flags.length == 0">&nbsp;</span>
                </div>
            </div>
        </div>
        <div class="pitchname-padding">
            {{pitch.pitch_name}}
        </div>
    </div>
</div>

<div *ngIf="side == 'even'">
    <div *ngIf="!multi_team" class="pitch-top">
        <mat-slide-toggle class="toggle-format" *ngIf="pitch.pitch_config_id" (change)="onBlockToggle($event)" [checked]="pitch.status === 'blocked'" [disabled]="pitch.status === 'active' || viewOnly">
        </mat-slide-toggle>
        <button class="ergo-tag" *ngIf="displayErgoScore" [disabled]="!ergoAdminFlag" [ngStyle]="{'background-color': getErgoScoreCategory(pitch.ergo_score)}" mat-mini-fab id="ergo score" (click)="!viewOnly && updateErgoScore()">
            <span *ngIf="pitch.ergo_score != -1">{{pitch.ergo_score}}</span>
            <span *ngIf="pitch.ergo_score == -1">--</span>
        </button>
        <span class="ergo-space" *ngIf="displayErgoSpace"></span>
    </div>
    
    <dt class="container-header">
        <div class="pitchname-padding">
            {{pitch.pitch_name}}
        </div>
        <div class="pitch-detail">
            <div class="service"> 
                <span *ngIf="pitch.service_pitches.length == 0">
                </span>
                <span *ngIf="pitch.service_attribute" [ngStyle]="{'color': pitch.service_color, 'font-size':'225%', 'font-weight': 'bold'}" [innerHTML]="pitch.service_attribute">  
                </span>
                <span *ngFor="let service of pitch.service_pitches" [matTooltip]="service.service_id" [ngStyle]="{'color': service.service_color, 'font-size':'130%','font-weight': 'bold'}" [innerHTML]="service.service_attribute">
                </span>
            </div>
            <div class="pitch-name">
                <button color="primary" mat-raised-button id="edit pitch" class="btn center blue mobile-btn" type="button" label="Edit Pitch" (click)="!viewOnly && updatePitch()">
                    {{pitch.pitch_id}}
                    <span *ngIf="pitch.type == 'subassembly'">
                        ({{pitch.subassembly_id}})
                    </span>
                </button>
            </div>
            <div class="flag">
                <div class="fx-row-center">
                    <span style="font-size:155%; color: darkred; font-weight: bold;" *ngFor="let flag of pitch.flags" [innerHTML] = "flag.attribute">
                    </span>
                    <span *ngIf="pitch.flags.length == 0"></span>
                </div>
            </div>
        </div>
    </dt>

    <div class="fx-row-center">
        <div  *ngFor = "let variant of pitch.variant"  > 
            <div class="fx-column">
                <variant *ngIf = "variant !== undefined"
                    [variant] = "variant"
                    [viewOnly] = "viewOnly"
                    [multi_team] = "multi_team"
                    [plantName] = "plantName"
                    [team_id] = "team_id"
                    [team_name] = "team_name"
                    [version_id] = "version_id"
                    [pitch_config_id] = "pitch.pitch_config_id"
                    [pitch_id] = "pitch.pitch_id"
                    [subassembly_id] = "pitch.subassembly_id"
                    [pitch_status] = "pitch.status"
                    [minHeight] = "minHeight"
                    [side] = "'even'"
                    [workstationParentListArray] = "workstationParentListArray"
                    [workstationPlaceholderArray] = "workstationPlaceholderArray"
                    [workstationFluctuationArray] = "workstationFluctuationArray"
                    [workstationPeriodicArray] = "workstationPeriodicArray"
                    [workstationCycleWasteArray] = "workstationCycleWasteArray"
                    [multiplierValue] = "multiplierValue"
                    [periodicCount] = "countPeriodicWork(variant)"
                    [taktTime] = "pitch.takt_time"
                    (reload) = "onReload()"
                    (savedWork) = "saveWork()"
                    (loadAvailableRegions) = "onLoadAvailableRegions()"
                    (workDrop) = "onDrop($event)"
                    (collapsePeriodic) = "onCollapsePeriodic()"
                    (expandPeriodic) = "onExpandPeriodic()">
                </variant>
            </div> 
        </div>
    </div>
</div>