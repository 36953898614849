import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WorkService } from '../services/work.service';
import { saveAs } from 'file-saver';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TeamFormatted, TeamVersion, ViewType } from 'src/app/shared/models/type-list';

export interface PrintDialogData {
  plant_name: string;
  team_name: string;
  team_id: number;
  version_name: string;
  version_id: number;
  team_list: TeamVersion[];
}

@Component({
  selector: 'app-print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialog {

  pdf:Blob;
  downloadComplete:Boolean=false;
  downloadError:Boolean=false;
  loading:Boolean;
  twoSided: number;
  printSingleSided: string;
  printEmptyPitches: string = '0';
  printErgoScore: string = '0';
  printFormGroup: UntypedFormGroup;
  sideOptions: ViewType[] = [{value: '1', view: 'Yes'},{value: '0', view: 'No'}];
  emptyOptions: ViewType[] = [{value: '1', view: 'Yes'},{value: '0', view: 'No'}];
  ergoOptions: ViewType[] = [{value: '1', view: 'Yes'},{value: '0', view: 'No'}];
  teamList: TeamVersion[]=[];
  plantName: string;
  teamID: number;
  versionName: string;


  constructor(
    private workService: WorkService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrintDialog>,
    @Inject(MAT_DIALOG_DATA) public inData:PrintDialogData,
    private printFormBuilder: UntypedFormBuilder
    ) 
    {
      if (inData.team_list != undefined){
        this.teamList = inData.team_list
        this.plantName = inData.plant_name
        this.teamID = 0
        this.versionName = "MultiTeam"
      }  
      else {
        let teamVersion:TeamVersion ={team_id: inData.team_id.toString(), version_id: inData.version_id.toString()}
        this.teamList.push(teamVersion)
        this.plantName = inData.plant_name
        this.teamID = inData.team_id
        this.versionName = inData.version_name
      }         

      inData.two_sided? this.twoSided = inData.two_sided: this.twoSided = 0
      if (this.twoSided == 0)
        this.printSingleSided = '1'
      else
        this.printSingleSided = '0'

      this.printFormGroup = this.printFormBuilder.group({
        form_printSingleSided :  this.printSingleSided,
        form_printEmptyPitches : this.printEmptyPitches,
        form_printErgoScore : this.printErgoScore
      })

      this.printFormGroup.valueChanges.subscribe(formValues => {
        this.printSingleSided = formValues.form_printSingleSided
        this.printEmptyPitches = formValues.form_printEmptyPitches
        this.printErgoScore = formValues.form_printErgoScore
      })
    }
      
  
  getPDF() {
    console.debug("Downloading PDF");

    let skipEmptyPitches: boolean
    if (this.printEmptyPitches == '1')
      skipEmptyPitches = false
    else
      skipEmptyPitches = true
    
    let singleSided: boolean
    if (this.printSingleSided == '1')
      singleSided = true
    else
      singleSided = false
    
    let printErgoScore: boolean
    if (this.printErgoScore == '1')
      printErgoScore = true
    else
      printErgoScore = false

    this.loading = true;
    
    this.workService.getPDF(this.teamList, singleSided, skipEmptyPitches, printErgoScore).subscribe(
      (blob) => {
        console.debug("Received PDF")
        this.pdf = blob; // Save the blob to be accessed later
        this.downloadComplete = true;
        this.downloadError = false;
        this.loading = false;
      },
      (err) => {
        console.error("Error generating PDF", err);
        this.downloadError = true;
        this.loading = false;
      }
      );
  }
  
  download() {
    if (this.downloadComplete) {
      let fileName:string = `yamazumi_${this.plantName}_${this.teamID}_${this.versionName}.pdf`
      saveAs(this.pdf, fileName);
    }
  }

  onNoClick():void {
    this.dialog.closeAll();
  }
}

export interface PrintDialogData {
  two_sided: number;
}