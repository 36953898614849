import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';


import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button'; 
import { HttpClientModule } from '@angular/common/http';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatTreeModule } from '@angular/material/tree';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';


import { BoardComponent } from './board/board.component';
import { TeamWorkComponent } from 'src/app/work/team-work.component';
import { WorkblockDialog } from './work/workblock-dialog.component';
import { TeamSelect } from './nav/team-select/team-select.component';
import { TeamEditComponent } from './team-edit/team-edit.component';
import { PitchEditComponent } from './team-edit/pitch/pitch-edit.component';
import { TeamDialog } from './team-edit/team-dialog.component';
import { PitchDialog } from './team-edit/pitch/pitch-dialog.component';
import { GeaMesNavbarModule } from '@gea-mes/navbar';
import { NavItemService } from './services/nav-item.service';
import { VariantEditComponent } from './team-edit/variant/variant-edit.component';
import { TeamVariantDialogComponent } from './team-edit/variant/team-variant.dialog.component';
import { DeleteDialogComponent } from './shared/delete-dialog.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { LogDialog } from './work/team-header/log-dialog.component';
import { TeamHeader } from './work/team-header/team-header.component';
import { NavLink } from './nav/nav-link/nav-link.component';
import { Work } from './work/work/work.component';
import { PrintDialog } from './work/print-dialog/print-dialog.component';
import { VersionDialog } from './work/team-header/version-dialog.component';
import { UpdateStandardWorkDialog } from './work/team-header/update-standardwork-dialog.component';
import { VersionEditComponent } from './team-edit/version/version-edit.component';
import { TeamVersionDialog } from './team-edit/version/team-version-dialog.component';
import { Variant } from './work/variant/variant.component';
import { RegionEditComponent } from './team-edit/region-edit/region-edit.component';
import { RegionDialogComponent } from './team-edit/region-edit/region-dialog/region-dialog.component';
import { Pitch } from './work/pitch/pitch.component';
import { ErgoDialog } from './team-edit/pitch/ergo-dialog.component';
import { ViewSelectComponent } from './nav/view-select/view-select.component';
import { TeamComponent } from './multi-team/team/team.component';
import { ErgoStatsDialog } from './work/team-header/ergo-stats-dialog.component';
import { TeamSelectEditComponent } from './nav/team-select-edit/team-select-edit.component';
import { TeamSelectMultiComponent } from './nav/team-select-multi/team-select-multi.component';
import { VersionNotice } from './team-edit/version/version-notice.component';
import { MultiTeamWorkComponent } from './multi-team/multi-team-work/multi-team-work.component';
import { MigrateDialog } from './team-edit/migrate-dialog/migrate-dialog.component';
import { PitchWorkCopyDialog } from './team-edit/pitch/pitch-work-copy/pitch-work-copy.component';




@NgModule({
  declarations: [
    AppComponent,
    BoardComponent, 
    TeamWorkComponent, 
    WorkblockDialog, 
    TeamSelect, 
    TeamEditComponent, 
    PitchEditComponent, 
    TeamDialog, 
    PitchDialog, 
    VariantEditComponent, 
    TeamVariantDialogComponent, 
    DeleteDialogComponent, 
    LogDialog, 
    TeamHeader, 
    NavLink, 
    Work,
    PrintDialog,
    VersionEditComponent,
    VersionDialog,
    UpdateStandardWorkDialog,
    TeamVersionDialog,
    Variant,
    RegionEditComponent,
    RegionDialogComponent,
    Pitch,
    ErgoDialog,
    ErgoStatsDialog,
    ViewSelectComponent,
    TeamComponent,
    TeamSelectEditComponent,
    TeamSelectMultiComponent,
    VersionNotice,
    MultiTeamWorkComponent,
    MigrateDialog,
    PitchWorkCopyDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DragDropModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatSnackBarModule,
    MatTableModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    MatCardModule,
    MatExpansionModule,
    MatSlideToggleModule,
    GeaMesNavbarModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    MatTooltipModule
  ],
  providers: [NavItemService],
  bootstrap: [AppComponent]
})
export class AppModule { }
