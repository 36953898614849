import { Component, Input, Output, OnChanges, SimpleChanges, EventEmitter } from '@angular/core';
import { PitchPair, TeamWorkFormatted, TeamFormatted, WorkstationFormatted } from 'src/app/shared/models/type-list';
import { WorkService } from 'src/app/work/services/work.service';

@Component({
  selector: 'team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnChanges {

  @Input()
    plant_name = ''

  @Input()
    line = ''

  @Input()  
    subline = ''

  @Input()
    plant_id = '0'

  @Input() 
    team_id = 0;
  
  @Input()
    version_id = 0;
  
  @Input()
    min_height = 0;
  @Input()
    multi_team = false;
  
  @Output() minHeightUpdate = new EventEmitter<number>();
  @Output() twoSided = new EventEmitter<boolean>();
  @Output() versionChange = new EventEmitter<string>()

  teamFormattedInfo: TeamFormatted;
  multiplierValue: number;
  isExpanded : boolean = false
  minHeight: string
  containerMinHeight : string
  emptyMinHeight: string

  evenWorkstations: WorkstationFormatted;
  oddWorkstations: WorkstationFormatted;

  pitchPairs: PitchPair[] = [];
  pitchesDisplayed: PitchPair[] = []
    
  workstationParentListArray: string[] = []
  workstationFluctuationArray: string[] = []
  workstationPeriodicArray: string[] = []
  workstationCycleWasteArray: string[] = []
  workstationPlaceholderArray: string[] = []

  containerHeader : number = 240;

  constructor(
    private workService: WorkService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    let teamIDChange = changes['team_id'];
    
    if ((teamIDChange != undefined) && teamIDChange.firstChange){
      this.getTeamWork()
    }

    let versionIDChange = changes['version_id']
    if ((versionIDChange != undefined) && !versionIDChange.firstChange && (versionIDChange.previousValue != versionIDChange.currentValue)){
      this.getTeamWork()
    }
    
    let minHeightChange = changes['min_height']
    if ((minHeightChange != undefined) && !minHeightChange.firstChange && (minHeightChange.previousValue != minHeightChange.currentValue)){
      this.minHeight = this.min_height.toString() + "px"
      this.containerMinHeight = Math.ceil(this.min_height + this.containerHeader).toString() + "px"
      this.emptyMinHeight = Math.ceil(this.min_height + this.containerHeader).toString() + "px"
    }
  }

  getTeamWork(){
    this.pitchPairs = []

    this.workService.getWorkFormatted(this.team_id, this.version_id).subscribe((
      out =>{
        this.teamFormattedInfo = out.Body.team_version_info
        if (this.teamFormattedInfo.two_sided == 1)
          this.twoSided.emit(true)

        this.createPitchPairs(out.Body.odd_workstations, out.Body.even_workstations)
        this.pitchesDisplayed = this.pitchPairs

      //multiplier value for determining workblock, pitch, and variant box sizes
      if  (this.teamFormattedInfo.takt_time > 60 &&  this.teamFormattedInfo.takt_time < 1000)
          this.multiplierValue = 3
      else if(this.teamFormattedInfo.takt_time > 1000) 
          this.multiplierValue = 1
      else
        this.multiplierValue = 15
      
      this.calculateWorkMinHeight()
    }))
  }

  createPitchPairs(odd_workstations, even_workstations){
    odd_workstations.map((workstation, index) => {
      let pitchPair: PitchPair = {
        odd: (workstation != undefined) ? workstation : undefined,
        even: (even_workstations[index] != undefined) ? even_workstations[index] : undefined
      }
      this.pitchPairs.push(pitchPair)

      if (workstation.hasOwnProperty('variant')){
        workstation.variant.forEach(v =>{
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString())
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationParentListArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationFluctuationArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationFluctuationArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPeriodicArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationPeriodicArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationCycleWasteArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationCycleWasteArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPlaceholderArray.push(workstation.pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')
        })
      }

      if(even_workstations[index] != undefined && even_workstations[index].hasOwnProperty('variant')){
        even_workstations[index].variant.forEach(v =>{
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString())
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationParentListArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationFluctuationArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-fluctuation')
          this.workstationFluctuationArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPeriodicArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-periodic')
          this.workstationPeriodicArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationCycleWasteArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-cycle')
          this.workstationCycleWasteArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')

          this.workstationPlaceholderArray.push(even_workstations[index].pitch_config_id.toString() + '-' + v.variant_id.toString() + '-placeholder')
        })
      }
    })
  }

  calculateWorkMinHeight(){

    let placeholderPadding : number = 5;
    let minHeight: number;
    let calcMinHeight: number;
    let maxPeriodicWork : number = 0;
    let expansionHeader : number = 5;
    

    if (this.teamFormattedInfo.takt_time > 60 && this.teamFormattedInfo.takt_time < 1000)
      placeholderPadding = 20
    else if (this.teamFormattedInfo.takt_time > 1000)
      placeholderPadding = 60
 
    minHeight = (this.teamFormattedInfo.takt_time + 1);
    this.pitchPairs.forEach(pitch => {
      Object.keys(pitch).forEach(key => {
        if (pitch[key] != undefined && pitch[key].hasOwnProperty('variant')){
          //work through variant's work to calculate needed height for a pitch
          pitch[key].variant.forEach(variant=> {
            if (pitch[key].takt_time + placeholderPadding > minHeight)
              minHeight = pitch[key].takt_time + placeholderPadding

            calcMinHeight = 0;
            maxPeriodicWork = 0;
  
            calcMinHeight = calcMinHeight + variant.work.fluctuation.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
    
            if (variant.work.periodic.length > 0)
              maxPeriodicWork = Math.max(...variant.work.periodic.map(out => out.time_to_complete))

            calcMinHeight = calcMinHeight + maxPeriodicWork
            pitch[key].expandedhgt = variant.work.periodic.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)

    
            calcMinHeight += variant.work.cycle.map(work => work.time_to_complete).reduce((prev, curr) => prev + curr, 0)
            
            if (pitch[key].isexpanded == true){
              this.isExpanded = true
              calcMinHeight = (calcMinHeight - maxPeriodicWork) + expansionHeader + pitch[key].expandedhgt;
            }
            
            calcMinHeight = calcMinHeight + placeholderPadding

            if  (calcMinHeight > minHeight)
              minHeight = calcMinHeight
          })
        }
      })
    })
    if (Math.ceil(this.multiplierValue * + minHeight) > this.min_height){
      this.minHeight = Math.ceil(this.multiplierValue * + minHeight).toString() + "px"
      console.info('MIN Variant Height: ', this.minHeight)
      this.containerMinHeight = Math.ceil(this.multiplierValue * + minHeight + this.containerHeader).toString() + "px"
      console.info('MIN Pitch Height: ', this.containerMinHeight)
      this.emptyMinHeight = Math.ceil(this.multiplierValue * + (minHeight) + this.containerHeader). toString() + "px"
      console.info('EMPTY MIN Height: ', this.emptyMinHeight)
      this.updateMinHeight(Math.ceil(this.multiplierValue* + minHeight))
    } else {
      this.minHeight = this.min_height.toString() + "px"
      this.containerMinHeight = Math.ceil(this.min_height + this.containerHeader).toString() + "px"
      this.emptyMinHeight = Math.ceil(this.min_height + this.containerHeader).toString() + "px"
    }
  }

  onChangeVersion(version_id: string){
    this.versionChange.emit(version_id)
  }

  calculateWorkMinAfterCollapse(pitch:WorkstationFormatted){
    this.isExpanded = false;
    pitch.isexpanded = false;
    this.calculateWorkMinHeight();
  }

  calculateWorkMinAfterExpand(pitch:WorkstationFormatted){
    this.isExpanded = true;
    pitch.isexpanded = true;
    this.calculateWorkMinHeight();
  }

  updateMinHeight(newMinHeight){
    this.minHeightUpdate.emit(newMinHeight)
  }

}
