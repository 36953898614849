
  
  <div *ngIf = "lineTeam">
  
    <mat-toolbar color="primary" class="tableTitleBar">
      <button mat-button color = "white" (click) = "goBackToTeamList()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Version List: {{plantDisplayName}} - {{displayLine}} - {{team}}</span>
      <span class="toolbar-spacer"></span>
      <span>
        <div class="fx-row">
          <button mat-raised-button color="link" (click) = "createVersion()">Create Version</button>
          <button mat-raised-button color="link" (click) = "openLog()">Change Log</button>
        </div>
      </span>
    </mat-toolbar>
        
    <mat-table [dataSource]="versionList">
        
      <ng-container matColumnDef="version_name">
        <mat-header-cell *matHeaderCellDef>Version</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.version_name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="demand">
        <mat-header-cell *matHeaderCellDef>Demand</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.demand}}</mat-cell>
      </ng-container>
        
      <ng-container matColumnDef="num_pitches">
        <mat-header-cell *matHeaderCellDef># Pitches</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.num_pitches}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="two_sided">
        <mat-header-cell *matHeaderCellDef>Two Sided</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.two_sided}}</mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="takt_time">
        <mat-header-cell *matHeaderCellDef>Takt Time</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.takt_time}}</mat-cell>
      </ng-container>
        
      <ng-container matColumnDef="active">
        <mat-header-cell *matHeaderCellDef >Status</mat-header-cell>
          <mat-cell *matCellDef="let d"><span *ngIf = "d.active == '1'">Active</span>
            <span *ngIf = "d.active == '2'">Deleted</span></mat-cell>
      </ng-container>

      <ng-container matColumnDef="base_variant_name">
        <mat-header-cell *matHeaderCellDef >Base Variant</mat-header-cell>
          <mat-cell *matCellDef="let d">{{d.base_variant_name}}</mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="updated_date">
        <mat-header-cell *matHeaderCellDef>Last Updated</mat-header-cell>
        
          <mat-cell *matCellDef="let d"><span *ngIf = "d.active == '1'|| d.active == '0'">{{d.updated_date|date:'short'}}</span>
            <span *ngIf = "d.active == '2'">{{d.deleted_date|date:'short'}}</span></mat-cell>
      </ng-container>     
        
      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let d"> 
              <button *ngIf = "d.active == '1' || d.active == '0'" mat-button color="primary" (click) = "updateVersion(d)"><mat-icon>create</mat-icon>Edit</button>
              <button *ngIf = "d.active == '1' || d.active == '0' " mat-button color="primary" [disabled]="d.takt_time <= 1" (click) = "duplicateVersion(d)"><mat-icon>file_copy</mat-icon>Duplicate</button>
              <button *ngIf = "d.active == '1' || d.active == '0' " mat-button color="primary" [disabled]="d.takt_time <= 1" (click) = "gotoPitchList(d)"><mat-icon>list</mat-icon>Pitch List</button>
              <button *ngIf = "d.active == '1' || d.active == '0' " mat-button color="primary" [disabled]="d.takt_time <= 1" (click) = "deleteVersion(d)"><mat-icon>delete_outline</mat-icon>Delete</button>
              <button *ngIf = "d.active == '2'" mat-button color="primary" style="color: red;" [disabled]="d.takt_time <= 1" (click) = "undeleteVersion(d)"><mat-icon>delete_forever</mat-icon>UnDo</button>
              <span *ngIf="d.takt_time <= 1 && d.base_variant_id == 0" style="color: red;"><mat-icon>error_outline</mat-icon> Version Details must be updated!</span>
              <span *ngIf="d.active == 2" style="color: red;"><mat-icon>error_outline</mat-icon> Permanently deleted on {{getPerDeletedDate(d.deleted_date)}} </span>
          </mat-cell>
      </ng-container>
          
      <mat-header-row *matHeaderRowDef="displayVersionColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayVersionColumns;"></mat-row>
    </mat-table>
  </div>

