<div class="fx-row">
<div class="example-container">
    <h2>Available Teams and Versions</h2>
  
    <div
      cdkDropList
      #todoList="cdkDropList"
      [cdkDropListData]="teamlist"
      [cdkDropListConnectedTo]="[doneList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of teamlist" cdkDrag [cdkDragDisabled] = "disableTeam(item)">
        <span *ngIf="!disableTeam(item)" style="font-weight: 500;">{{item.line}} {{item.subline}} {{item.team_name}} Version: {{item.version_name}}</span>
        <span *ngIf="disableTeam(item)" style="color: lightgray;">{{item.line}} {{item.subline}} {{item.team_name}} Version: {{item.version_name}}</span>
      </div>
    </div>
  </div>

<div class="fx-column-start-end">
  <div class="example-container">
    <h2>Selected Teams and Versions</h2>
  
    <div
      cdkDropList
      #doneList="cdkDropList"
      [cdkDropListData]="done"
      [cdkDropListConnectedTo]="[todoList]"
      class="example-list"
      (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let item of done" cdkDrag>
        {{item.line}} {{item.subline}} {{item.team_name}} Version: {{item.version_name}}</div>
    </div>
  </div>
  <div> 
 
    <button style="margin:5px"  [disabled]="done.length === 0 " mat-raised-button
      color="primary" (click)="viewTeams()" >View</button>
    <button style="margin:5px; background-color:red"   mat-raised-button
      color="primary" (click)="clearDone()" >Clear</button>

  </div>
</div>
</div>
  
