import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { TeamNode } from 'src/app/shared/models/type-list';

@Component({
  selector: 'update-standardwork-dialog',
  templateUrl: './update-standardwork-dialog.component.html',
  styleUrls: ['./update-standardwork-dialog.component.scss']
})
export class UpdateStandardWorkDialog  {

  versionName: string;

  constructor(
    public dialogRef: MatDialogRef<UpdateStandardWorkDialog>,
    @Inject(MAT_DIALOG_DATA) public inData: TeamNode,
  ) 
  { 
    console.log(inData)
  }

  onNoClick(): void {
    const confirmResponse = {
      response: false  
    }
    console.log(confirmResponse)
    this.dialogRef.close(confirmResponse)
  }
  
  onConfirm(): void {
    const confirmResponse = {
      response: true  
    }
    console.log(confirmResponse)
    this.dialogRef.close(confirmResponse)
  }
}
