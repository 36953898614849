import { BreakpointState, Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { switchMap, pluck, first } from 'rxjs/operators';
import { Navigation, TeamNode, LineTeam } from 'src/app/shared/models/type-list';
import { TeamService } from 'src/app/team-edit/services/team.service';
import { TeamDialog } from 'src/app/team-edit/team-dialog.component';
import { NavService } from '../services/nav.service';
import { MigrateDialog } from 'src/app/team-edit/migrate-dialog/migrate-dialog.component';
import { GeaMesCognitoAuthService } from '@gea-mes/cognito';
import { MultiTeamFilter} from 'src/app/shared/models/type-list';

@Component({
  selector: 'app-team-select-edit',
  templateUrl: './team-select-edit.component.html',
  styleUrls: ['./team-select-edit.component.scss']
})
export class TeamSelectEditComponent implements OnInit, OnDestroy {
  isTablet$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]);
  isScreen$: Observable<BreakpointState> = this.breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]);

  navSubscription: Subscription; 
  teamSubscription: Subscription;
  treeControl = new NestedTreeControl<TeamNode>(node => node.children);
  teamSource = new MatTreeNestedDataSource<TeamNode>();
  expandedNodes: TeamNode[] = [];
  showteamsview = false;
  currentNav: Navigation = {
    plant_name: '',
    line: '',
    subline: '',
    team_id: 0,
    version_id: 0
  }
  lineTeamList: LineTeam[] = [];
  PlantName = ''
  Line = ''
  subLine = ''
  display_name = ''

  ergoAdminSub$: Subscription;
  ergoAdminFlag: boolean;

  multiTeamFilter: MultiTeamFilter = { ismultiteam: "false" };

  constructor(
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private teamDialog: MatDialog,
    private teamService: TeamService,
    private migrateDialog: MatDialog,
    private geaMesCognito: GeaMesCognitoAuthService
  ) {

  }

  hasChild = (_: number, node: TeamNode) => !!node.children && node.children.length > 0;
  
  ngOnInit(): void {
    this.ergoAdminSub$ = this.geaMesCognito.isUserInRole("Migrate").pipe(first()).subscribe( out => { 
      this.ergoAdminFlag = out;
      if (this.ergoAdminSub$) this.ergoAdminSub$.unsubscribe(); // We've got an answer, can unsubscribe now
    });
    this.getPlantList();
  }

  ngOnDestroy(): void {
    if (this.navSubscription) this.navSubscription.unsubscribe();
    if (this.teamSubscription) this.teamSubscription.unsubscribe();
  }

  
  getPlantList(){
    let navObservable: Observable<TeamNode[]> = 
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => 
        this.navService.getNavListLines(this.multiTeamFilter)), 
      pluck('Body'));
    if (this.navSubscription) this.navSubscription.unsubscribe();     
       
    this.navSubscription = navObservable.subscribe(
      (out) => {
        this.teamSource.data = out;

        if (this.route.snapshot.paramMap.get("plant_name") !== undefined && this.route.snapshot.paramMap.get("plant_name") !== null){
          this.expandTree("plant_name", out);
        }

        if(this.expandedNodes.length == 1 && this.route.snapshot.paramMap.get("line") !== undefined && this.route.snapshot.paramMap.get("line") !== null){
          this.expandTree("line", this.expandedNodes[0].children);
        }

        if(this.expandedNodes.length == 2 && this.route.snapshot.paramMap.get("subline") !== undefined && this.route.snapshot.paramMap.get("subline") !== null){
          this.expandTree("subline", this.expandedNodes[1].children);
        }
      }
    );
  }

  createTeam(){
    const dialogRef = this.teamDialog.open(TeamDialog, {
      width: '500px',
      data: 
      { 
        plant_name: this.currentNav.plant_name,
        plant_display_name: this.currentNav.plant_name,
        line: '', 
        subline: '',
        team_name: '',
        line_teams: this.lineTeamList,
        form_type: 'create'
      }
    });
    
    dialogRef.afterClosed().subscribe(result => { 
      this.lineTeamList = []
      this.getTeamList(this.currentNav.plant_name, this.currentNav.line, this.currentNav.subline)
    });
  }

  getTeamList(plant_name: string, line_name: string, subline_name: string){
    let teamObservable: Observable<LineTeam[]> = 
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
        this.teamService.getTeams(plant_name, line_name, subline_name)),
        pluck('Body'));
 
    if (this.teamSubscription) this.teamSubscription.unsubscribe();

    this.teamSubscription = teamObservable.subscribe((out) => {
      this.lineTeamList = out
    })
    
  }

  navigate(plant_name: string, line: string, subline: string) {
    console.log("plant_name", plant_name)
    console.log("line", line)
    console.log("subline", subline)
    this.router.navigate(["/teamedit", plant_name, line, subline]);
  }
  
  expandTree(param: string, nodes: TeamNode[]){
    if (this.route.snapshot.paramMap.get(param) !== undefined){
      var valid = false;
      var expandedNode: TeamNode;

      nodes.forEach(node => {
        var routeParam: string = this.route.snapshot.paramMap.get(param);
        console.log(routeParam)
        if(((node.search).toString()).toLowerCase() == routeParam.toLowerCase()){
          expandedNode = node;
          valid = true;
        }
      });

      if(valid){
        console.debug("Valid " + param + " parameter supplied, expanding " + this.route.snapshot.paramMap.get(param));
        this.treeControl.toggle(expandedNode);
        this.expandedNodes.push(expandedNode);
      }else{
        console.warn("Invalid " + param + " parameter provided");
      }
    }
  }

  migrateTeam(){
    const dialogRef = this.migrateDialog.open(MigrateDialog, {
      width: '500px',
      data: 
      {}
    });
    
    dialogRef.afterClosed().subscribe(result => {});
  }


}
