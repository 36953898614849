/*
* Description: Static Application Information
*
* History:
*   03/11/2022  M Smith ITMFG-28835 Initial version, needed because package.json can no longer be read
*
**/
export const appinfo = {
    'name': 'yamazumi',
    'version': '1.0.0'
}