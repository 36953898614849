import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavService } from 'src/app/nav/services/nav.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable, Subscription} from 'rxjs';
import { filter, pluck, switchMap, take } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TeamService } from 'src/app/team-edit/services/team.service'; 
import { VariantType, PitchType,  Version, Team, Navigation } from 'src/app/shared/models/type-list';
import { PitchDialog } from './pitch-dialog.component'
import { PitchWorkCopyDialog } from './pitch-work-copy/pitch-work-copy.component';

@Component({
  selector: 'app-pitch-edit',
  templateUrl: './pitch-edit.component.html',
  styleUrls: ['./pitch-edit.component.scss']
})
export class PitchEditComponent implements OnInit, OnDestroy {
  plant_name: string = ''
  displayPlantName: string = ''
  line: string = ''
  subline: string = ''
  team_id: string = ''
  team: string = ''
  version: string = ''
  display_line: string = ''
  versionData: Version

  pitchSubscription: Subscription
  pitchList: PitchType[] = []
  
  variantSubscription: Subscription
  pitchVariant : string []

  teamInfo: Team

  displayedPitchColumns: string[] = ['pitch_id','pitch_name','pitch_type','subassembly_id', 'takt_time', 'status', 'flags', 'services', 'actions']

  constructor( private teamService: TeamService,
    private navService: NavService,
    private route: ActivatedRoute,
    private router: Router,
    private pitchDialog: MatDialog,
    private pitchWorkCopyDialog: MatDialog
  ){
      this.plant_name = this.route.snapshot.paramMap.get('plant_name');
      this.line = this.route.snapshot.paramMap.get('line')
      this.team = this.route.snapshot.paramMap.get('team');
      this.version = this.route.snapshot.paramMap.get('version');
      this.subline = this.route.snapshot.paramMap.get('subline')
      this.display_line = this.line
      if (this.subline != '')
        this.display_line = this.display_line + ' - ' + this.subline
  } 

  ngOnInit(): void {
    this.navService.getCurrentNav().pipe(
      filter(out => out != null), // Filter out empty values
      take(1) // Take only the first value that passes the above filter
      ).subscribe(
        (out:Navigation) => {
          this.displayPlantName = out.plant_display;
        }
      );
   
    this.teamService.version$.subscribe(out =>{
      this.versionData = out
      if (this.versionData == null || this.versionData == undefined)
        this.router.navigate(['teamedit/', this.plant_name]);
      else
        this.getPitchList()
    });
  }

  getPitchList(){
    this.teamInfo = {
      team_id: this.versionData.team_id,
      team_name: this.versionData.team_name,
      version_id: this.versionData.version_id,
      version_name: this.versionData.version_name,
      active: this.versionData.active,
      num_pitches: this.versionData.num_pitches,
      two_sided: this.versionData.two_sided,
      takt_time: this.versionData.takt_time
    }

    let pitchObservable: Observable<PitchType[]> =
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) =>
       this.teamService.getPitches(this.versionData.team_id, this.versionData.version_id)),
       pluck('Body'));
    if (this.pitchSubscription) this.pitchSubscription.unsubscribe();     
       
    this.pitchSubscription = pitchObservable.subscribe((out) => {
        console.log(out)
        this.pitchList = out
      });
  }

  createPitch(){
    const dialogRef = this.pitchDialog.open(PitchDialog, {
      width: '500px',
      data: 
      {
        plant_name: this.plant_name,
        team_id: this.teamInfo.team_id,
        team_name: this.teamInfo.team_name,
        version_id: this.teamInfo.version_id,
        version_name: this.teamInfo.version_name,
        takt_time: this.teamInfo.takt_time,
        pitch_config_id: 0,
        pitch_id: '',
        type: 'pitch',
        subassembly_id: '',
        can_delete: false,
        form_type: 'Create New Pitch',
        pitchList: this.pitchList,
        pitch_name: '',
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined){
        this.pitchList = []
        this.getPitchList()
      }
    });
  }

  updatePitch(pitch_info: PitchType){
    let pitchVariants: VariantType[] = []
    let totalwork = 0;

    pitch_info.variantDetails.forEach(variant=> {
      let pitchVariant: VariantType = 
      {
        "team_id": this.versionData.team_id,
        "variant_id": variant.variant_id,
        "variant_name": variant.variant_name,
        "sort_order": 0
      }
      pitchVariants.push(pitchVariant)
    })
    
    const dialogRef = this.pitchDialog.open(PitchDialog, {
      width: '500px',
      data: 
      {
        plant_name: this.plant_name,
        team_id: this.teamInfo.team_id,
        team_name: this.teamInfo.team_name,
        version_id: this.teamInfo.version_id,
        version_name: this.teamInfo.version_name,
        pitch_config_id: pitch_info.pitch_config_id,
        pitch_id: pitch_info.pitch_id,
        type: pitch_info.type,
        subassembly_id: pitch_info.subassembly_id,
        takt_time: pitch_info.takt_time,
        pitchVariants: pitchVariants,
        flags: pitch_info.flags,
        can_delete: false,
        form_type: 'Edit Pitch',
        status: pitch_info.status,
        pitchList: this.pitchList,
        pitch_name: pitch_info.pitch_name,
        service_ties: pitch_info.service_ties,
        service_attribute: pitch_info.service_attribute,
        service_color: pitch_info.service_color
      }
    });
    dialogRef.afterClosed().subscribe(result => {
        this.pitchList = []
        this.getPitchList()
    });
  }

  goBackToVersionList(){
    this.router.navigate(['versionedit/', this.plant_name, this.line, this.subline, this.team]);
  }

  copyPitchWork(){
    console.log(this.pitchList)
    console.log()
    const dialogRef = this.pitchWorkCopyDialog.open(PitchWorkCopyDialog, {
      width: '500px',
      data: 
      {
        plant_name: this.plant_name,
        pitch_list: this.pitchList,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined){
        this.getPitchList()
      }
    });
  }

  ngOnDestroy(): void {
    if (this.pitchSubscription) this.pitchSubscription.unsubscribe();
  }
}