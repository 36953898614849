<div class="fx-row-center-center">
  <div class="fx-flex-49">
    <div class="headerTitle">
      <h1>Team Configuration Dashboard</h1>
    </div>
  </div>
  <div class="fx-flex-49">
    <nav-link *ngIf = "currentNav" rootRoute="teams">
    </nav-link>
  </div>
</div>

<div>
  <mat-toolbar color="primary" class="tableTitleBar">
    <span>Team List: {{currentNav.plant_name}}, {{currentNav.line}}, {{currentNav.subline}}</span>
    <span class="toolbar-spacer"></span>
    <span><button mat-raised-button color="link" (click) = "createTeam()">Create New Team</button></span>
  </mat-toolbar>
      
  <mat-table [dataSource]="lineTeamList">

    <ng-container matColumnDef="team_id">
      <mat-header-cell *matHeaderCellDef>Team ID</mat-header-cell>
      <mat-cell id = "{{d.team_id}}" *matCellDef="let d">{{d.team_id}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="line">
      <mat-header-cell *matHeaderCellDef>Line</mat-header-cell>
        <mat-cell *matCellDef="let d">{{d.line}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="subline">
      <mat-header-cell *matHeaderCellDef>Subline</mat-header-cell>
        <mat-cell *matCellDef="let d">{{d.subline}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="team_name">
      <mat-header-cell *matHeaderCellDef>Team Leader</mat-header-cell>
        <mat-cell *matCellDef="let d">{{d.team_name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="takt_time_unit">
      <mat-header-cell *matHeaderCellDef>Time Unit</mat-header-cell>
        <mat-cell *matCellDef="let d">
          {{getTimeUnit(d.takt_time_unit)}}
        </mat-cell>
    </ng-container>
      
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell *matCellDef="let d">
            <button mat-button color="primary" (click) = "updateTeam(d)"><mat-icon>create</mat-icon>Edit</button>
            <button mat-button color="primary" (click) = "gotoVersionList(d)"><mat-icon>calendar_view_day</mat-icon>Versions</button>
            <button mat-button color="primary" (click) = "gotoVariantList(d)"><mat-icon>view_column</mat-icon>Variant</button>
            <button mat-button color="primary" (click) = "gotoRegionList(d)"><mat-icon>language</mat-icon>Region</button>
            <button mat-button color="primary" (click) = "deleteTeam(d)"><mat-icon>delete_outline</mat-icon>Delete</button>
        </mat-cell>
    </ng-container>
        
    <mat-header-row *matHeaderRowDef="displayedTeamColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedTeamColumns;"></mat-row>
  </mat-table>
</div>