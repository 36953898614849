<br><br>
<div>
    <mat-toolbar color="primary" class="tableTitleBar" >
      <button mat-button color = "white" (click) = "goBackToTeamList()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Region List: {{plantDisplayName}} - {{display_line}} - {{team}}</span>
      <span class="toolbar-spacer"></span>
      <span><button mat-raised-button color="link" (click)="createRegion()">Create New Region</button></span>
    </mat-toolbar>
          <mat-table [dataSource]="regionList">
              <ng-container matColumnDef="region">
                  <mat-header-cell *matHeaderCellDef>Region</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.region}}</mat-cell>
                </ng-container>    
              <ng-container matColumnDef="color">
                <mat-header-cell *matHeaderCellDef>Color</mat-header-cell>
                <mat-cell *matCellDef="let d"><mat-icon [ngStyle]="{'color': d.color}">lens</mat-icon>{{d.color_name}}</mat-cell> 
              </ng-container>
          
      

      
              <ng-container matColumnDef="action">
                  <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                  <mat-cell *matCellDef="let d">
                    <button mat-button color="primary" (click) = "updateRegion(d)"><mat-icon>create</mat-icon>Edit</button>
                    <button mat-button color="primary" (click) = "deleteRegion(d)"><mat-icon>delete_outline</mat-icon>Delete</button>
                  </mat-cell>
                </ng-container>  
      
              <mat-header-row *matHeaderRowDef="displayedRegionColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedRegionColumns;"></mat-row>
            </mat-table>
  </div>

