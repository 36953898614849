import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { WorkService } from './services/work.service';
import { Work, Region, WorkType, PitchType } from '../shared/models/type-list';
import { TeamService } from '../team-edit/services/team.service';
import { Subscription } from 'rxjs';
import { pluck } from 'rxjs/operators';


@Component({
  selector: 'app-workblock-dialog',
  templateUrl: './workblock-dialog.component.html',
  styleUrls: ['./workblock-dialog.component.scss']
})
  export class WorkblockDialog implements OnInit {
    plantName: string;
    teamID: number;

    workblockFormGroup: UntypedFormGroup;
    formType: string;
    formTitle: string;
    workTypes: WorkType[];
    work: Work;
    variant_id: number;
    pitch_config_id: number;
    pitch_id: string;
    pitch_status: string;
    subassembly_id: string;
    variant_name: string;

    currentWorkType: string;
    availableRegions: Region[] = [];
    teamRegion: boolean = true;
    timetoComplete: string;
    frequency: string;
    versionID: number;
    maxCycleSort: number;
    maxPeriodicSort: number;
    maxFluctuationSort: number;
    isBusy: boolean = false;
    regionName: string = '';
    servicePitchName: string = '';
    servicePitchID: number = 0;
    serviceColor:string = '';
    serviceAttribute:string = '';
    pitchList: PitchType[] = [];
    pitchSubscription: Subscription;
    isServicePitch : boolean = true;

  constructor(  
    public dialogRef: MatDialogRef<WorkblockDialog>,
      @Inject(MAT_DIALOG_DATA) public data: Input,
      private workblockfb: UntypedFormBuilder,
      private workService: WorkService,
      private teamService: TeamService,
  ) 
  {
    console.info(data)
    
    this.formType = data.formType;
    this.plantName = data.plant_name;
    this.pitch_config_id = data.pitch_config_id;
    this.pitch_id = data.pitch_id;
    this.pitch_status = data.pitch_status;
    this.teamID = data.team_id;
    this.subassembly_id = data.subassembly_id;
    this.variant_id = data.variant_id;
    this.variant_name = data.variant_name;
    this.versionID = data.version_id;
    this.work = data.work;
    this.maxCycleSort = data.max_cycle_sort;
    this.maxPeriodicSort = data.max_periodic_sort;
    this.maxFluctuationSort = data.max_fluctuation_sort;
   
    
    console.log('Pitch Status: ' +  this.pitch_status) 
    console.log('Subassembly ID: ' + this.subassembly_id)
    this.currentWorkType = data.work.type;

    this.workService.getRegions(this.teamID).subscribe((
      out=>{
        this.availableRegions = out.Body
        let noRegion: Region =
        {
          region_id: 0,
          region: '< No Region >',
          color:  ''
        }
        this.availableRegions.unshift(noRegion)
        if ( this.availableRegions.length  == 0 ){
          this.teamRegion = false
        }
        else
        this.setRegionValues()
      }
    ))
        
    if (this.maxFluctuationSort == 0 || this.work.type == 'fluctuation')
      this.workTypes = [{display:"Cycle", value: "cycle"}, {display:"Periodic", value: "periodic"},{display:"Fluctuation", value: "fluctuation"}]
    else
      this.workTypes = [{display:"Cycle", value: "cycle"}, {display:"Periodic", value: "periodic"}]
    
    if (this.work.time_to_complete == 0)
      this.timetoComplete = ""
    else 
      this.timetoComplete = this.work.time_to_complete.toString();
      
    if (this.work.frequency == 0 || this.work.frequency == null)
      this.frequency = ""
    else
      this.frequency = this.work.frequency.toString();


    this.workblockFormGroup = this.workblockfb.group({
      form_WorkDescription: [this.work.description, Validators.required],
      form_WorkType: [this.work.type, Validators.required],
      form_Region: this.work.region,
      form_TimeToComplete: [this.timetoComplete, Validators.required],
      form_Frequency: this.frequency,
      form_ServiceConfig: this.work.service_config_id,
    });

    this.workblockFormGroup.valueChanges.subscribe((formValues) => {
      this.work.description = formValues.form_WorkDescription;
      this.work.type = formValues.form_WorkType;
      this.work.region = formValues.form_Region;
      this.work.service_config_id = formValues.form_ServiceConfig;
      this.work.frequency = (isNaN(formValues.form_Frequency) || formValues.form_Frequency == null) ? 0: formValues.form_Frequency;
      this.work.time_to_complete = formValues.form_TimeToComplete;

      if (formValues.form_WorkType == "fluctuation"){
         this.work.description = "Fluctuation"
      }

      if (formValues.form_WorkType !== "periodic" && this.work.frequency !== 0){
        this.work.frequency = 0;
      }

      this.setRegionValues()
      this.setServicePitchValues()
    });

    this.workblockFormGroup.controls.form_WorkType.valueChanges.subscribe((value) => {
      if (value !== "fluctuation"){
        if(this.workblockFormGroup.controls.form_WorkDescription.value == "Fluctuation"){
          this.work.description = '';
          this.workblockFormGroup.controls.form_WorkDescription.setValue(this.work.description);
        }
        else{
          this.work.description = this.workblockFormGroup.controls.form_WorkDescription.value;
        }
      }else{
        this.workblockFormGroup.controls.form_WorkDescription.setValue("Fluctuation")
      }
    });
  }

  ngOnInit() {
    if (this.pitchSubscription) this.pitchSubscription.unsubscribe();     

    this.pitchSubscription = this.teamService.getPitches(this.teamID, this.versionID).pipe(pluck('Body')).subscribe(
      (out:PitchType[]) => {
        this.pitchList = out.filter(pitch => ["waterspider","kitter", "repacker"].includes(pitch.type)); // Get only service pitches
        // Add a No Service pitch option
        let noServiceConfig: PitchType = {
          pitch_config_id: 0,
          pitch_id: '< No Service Pitch >',
          service_color: '',
          service_attribute: '',
          team_id: -1,
          variantDetails : [],
          version_id: -1,
          type: '',
          subassembly_id: '',
          takt_time: 0,
          status: '',
          flags: [],
          pitch_name: '',
          service_ties: [],
          service_pitches: []
        };
        for (let i = 0; i < this.pitchList.length; i++  ){
          if ( this.pitchList[i].pitch_config_id == this.data.pitch_config_id) {
           this.isServicePitch = false;
          }
        }
        this.pitchList.unshift(noServiceConfig);

        this.setServicePitchValues();
      });
  }

  onNoClick(): void {
    this.workService.variant_work$.next(null)
    this.dialogRef.close();
    
  }

  createNewWorkBlock(): void {
    this.isBusy = true
    console.log('Frequency: ', this.work.frequency)
    if (this.workblockFormGroup.status == 'VALID'){

      this.workService.createWorkBlock(this.plantName, this.work.type, this.work.time_to_complete, this.work.region, this.work.description, this.work.frequency, this.variant_id, this.teamID, this.versionID, this.servicePitchID).subscribe(out=>{
        let workID = out.Body.NEW_WORKID
        this.workService.createPitchWorkConfig(this.pitch_config_id, this.variant_id, workID, this.getSortOrder(this.work.type), this.pitch_status, this.plantName).subscribe(out=>{
          let output: Output = {
            current_type: ' ',
            
          } 

          if(this.work.type == "fluctuation")
            output.current_type = this.currentWorkType;
          else
            output.current_type = this.work.type;
          this.isBusy = false
          this.dialogRef.close(output)
        })
      })
    } else 
      console.error('Invalid workblockFormGroup, was not caught in form control error handling.')
  }

  updateWorkBlock(){
    this.isBusy = true
    if (this.workblockFormGroup.status == 'VALID'){

      this.workService.updateWorkBlock(this.plantName, this.work.work_id, this.work.type, this.work.time_to_complete, this.work.region, this.work.description, this.work.frequency, this.variant_id, this.teamID, this.versionID, this.servicePitchID).subscribe(out=>{
        if (this.work.type != this.currentWorkType)
          this.workService.createPitchWorkConfig(this.pitch_config_id, this.variant_id, this.work.work_id, this.getSortOrder(this.work.type), this.pitch_status, this.plantName).subscribe(out=>{  
            this.isBusy = false
            this.dialogRef.close()
          })
        else
          this.isBusy = false
          this.dialogRef.close()
      })
    } else 
      console.error('Invalid form data, an unhandled error was discovered in workblockFormGroup');
  }

  deleteWorkBlock(){
    this.isBusy = true
    if(this.workblockFormGroup.status == 'VALID')
      this.workService.deleteWorkBlock(this.work.work_id, this.pitch_config_id, this.teamID, this.versionID, this.work.type, this.work.description,this.plantName).subscribe(out => {
        this.isBusy = false
        this.dialogRef.close()
      })
    else 
      console.error('Invalid form data, an unhandled error was discovered in workblockFormGroup');
  }

  getSortOrder(workType): number{
    let sort_order: number = 1

    if (workType == 'cycle' || workType == 'waste'){
      sort_order = this.maxCycleSort + 1
    }
    else if (workType == 'periodic' || workType == 'waste'){
      sort_order = this.maxPeriodicSort + 1
    }
    else if (workType == 'fluctuation' || workType == 'waste') {
      sort_order = this.maxFluctuationSort + 1
    }
      
    return sort_order
  }

  setRegionValues(){
    let regionIndex = this.availableRegions.findIndex(region => region.region_id === this.work.region)
    if (regionIndex != -1){
      this.regionName = this.availableRegions[regionIndex].region
      this.work.color = this.availableRegions[regionIndex].color
    }
    else{
      this.regionName = ''
      this.work.color = ''
    }
  }

  setServicePitchValues() {
    let serviceIdx = this.pitchList.findIndex(pitch => pitch.pitch_config_id === this.work.service_config_id);
    if (serviceIdx != -1) {
      this.servicePitchName = this.pitchList[serviceIdx].pitch_id;
      this.servicePitchID = this.pitchList[serviceIdx].pitch_config_id;
      this.serviceColor = this.pitchList[serviceIdx].service_color;
      this.serviceAttribute = this.pitchList[serviceIdx].service_attribute;
    }
  }
}

export interface Input {
  formType: string;
  pitch_config_id: number;
  pitch_id: string;
  pitch_status: string;
  plant_name: string;
  team_id: number;
  subassembly_id: string;
  version_id: number;
  work: Work;
  max_cycle_sort: number;
  max_periodic_sort: number;
  max_fluctuation_sort: number;
  variant_id: number;
  variant_name: string;
}

export type Output = {
  current_type?: string;
}
