import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TeamService } from '../services/team.service';
import { VariantType, Flag, PitchType, ServiceTie} from 'src/app/shared/models/type-list';



@Component({
  selector: 'app-ergo-dialog',
  templateUrl: './ergo-dialog.component.html',
  styleUrls: ['./ergo-dialog.component.scss']
})
export class ErgoDialog {
  plantName: string;
  teamID: number;
  versionID: number;
  pitchConfigID: number;
  pitchID: string;
  pitchName: string;
  formType: string;
  pitchType: string;
  subassemblyID: string;
  taktTime: number;
  status: string;
  availableVariants: VariantType[] = [];
  availableVariantNames: string[];
  selectedVariants: string[] = [];
  previousSelectedVariants: VariantType[] = [];
  isBusy: boolean = false;
  ergoScore: number;
  ergoComments: string;
  ergoFormGroup: UntypedFormGroup;
  serviceAttribute: string;
  serviceColor: string;
  serviceTies: ServiceTie[] = []

  constructor(
    public dialogRef: MatDialogRef<ErgoDialog>,
    @Inject(MAT_DIALOG_DATA) public ergoData: ErgoDialogData,
    private ergoFormBuilder: UntypedFormBuilder,
    private teamService: TeamService,
  )
  { 
    ergoData.ergo_comments != null && ergoData.ergo_comments != '' ? this.ergoComments = ergoData.ergo_comments : this.ergoComments = ''
    ergoData.ergo_score != null && ergoData.ergo_score > 0 ? this.ergoScore = ergoData.ergo_score : this.ergoScore = null
    ergoData.plant_name ? this.plantName = ergoData.plant_name : this.plantName = ''
    ergoData.team_id ? this.teamID = ergoData.team_id: this.teamID = 0
    ergoData.version_id ? this.versionID = ergoData.version_id: this.teamID = 0
    ergoData.pitch_config_id ? this.pitchConfigID = ergoData.pitch_config_id : this.pitchConfigID = 0
    ergoData.pitch_id ? this.pitchID = ergoData.pitch_id : this.pitchID = ''
    ergoData.type ? this.pitchType = ergoData.type : this.pitchType = ''
    ergoData.subassembly_id ? this.subassemblyID = ergoData.subassembly_id : this.subassemblyID = ''
    ergoData.takt_time ? this.taktTime = ergoData.takt_time : this.taktTime = 0
    ergoData.pitchVariants ? this.selectedVariants = ergoData.pitchVariants.map(variant => variant.variant_name) : this.selectedVariants = []
    ergoData.pitchVariants ? this.previousSelectedVariants = ergoData.pitchVariants : this.previousSelectedVariants = []
    ergoData.form_type  ? this.formType = ergoData.form_type : this.formType = 'Update Ergo Score'
    ergoData.status ? this.status = ergoData.status : this.status = 'open'
    ergoData.pitch_name ? this.pitchName = ergoData.pitch_name : this.pitchName = ''
    ergoData.service_attribute ? this.serviceAttribute = ergoData.service_attribute : this.serviceAttribute = ''
    ergoData.service_color ? this.serviceColor = ergoData.service_color : this.serviceColor = ''

    this.ergoFormGroup = this.ergoFormBuilder.group({
      form_ergoComments : this.ergoComments ,
      form_ergoScore :  this.ergoScore
    })

    this.ergoFormGroup.valueChanges.subscribe((formValues) =>{
      this.ergoScore = formValues.form_ergoScore;
      this.ergoComments = formValues.form_ergoComments;
    });

    this.teamService.getVariant(this.teamID).subscribe((
      out =>{
        this.availableVariants = out.Body
        this.availableVariantNames = this.availableVariants.map(variant => variant.variant_name)
      }
    ));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateErgoScore(){
    let variantIDs: number[] = this.getVariantIDs()
    this.isBusy = true
    if(this.ergoFormGroup.status == 'VALID'){
      if (this.ergoScore == null) {
        this.ergoScore = -1
      }
      if (this.ergoComments == null ){
        this.ergoComments = ''
      }
      this.teamService.updatePitch(this.plantName,this.teamID,this.versionID,this.pitchConfigID,this.pitchID,this.pitchType,this.subassemblyID,this.taktTime,this.status,variantIDs, this.pitchName, this.ergoScore,this.ergoComments, this.serviceAttribute, this.serviceColor).subscribe(out => {
        this.isBusy = false
        this.dialogRef.close()
      })
    } else {
      this.isBusy = false 
    }
  }

  getVariantIDs(): number[] {
    let variantIDs: number[] = []
    this.selectedVariants.forEach(selected => {
      let variantID: VariantType = this.availableVariants.find(variant => variant.variant_name === selected)
      variantIDs.push(variantID.variant_id)
    })
    return variantIDs
  }
}


export interface ErgoDialogData {
  plant_name: string;
  team_id: number;
  version_id: number;
  pitch_config_id: number;
  pitch_id: string;
  type: string;
  subassembly_id: string;
  takt_time: number;
  pitchVariants: VariantType[];
  form_type: string;
  status: string;
  pitch_name: string;
  pitchList: PitchType[];
  ergo_score: number;
  ergo_comments:string;
  service_ties: ServiceTie[];
  service_attribute: string;
  service_color: string;
}
