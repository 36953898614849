import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavService } from '../services/nav.service';
import { Navigation } from 'src/app/shared/models/type-list';


@Component({
  selector: 'nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLink implements OnInit, OnDestroy {
  @Input() rootRoute:string;

  current_nav:Navigation;
  
  navSubscription: Subscription; 

  constructor(
    private navService: NavService
  ){}

  ngOnInit(){
    if (this.navSubscription) this.navSubscription.unsubscribe();

    this.navSubscription = this.navService.getCurrentNav().subscribe(
      (out:Navigation) => {
        this.current_nav = out;
      }
    );
  }

  ngOnDestroy(){
    if (this.navSubscription) this.navSubscription.unsubscribe();
  }
}



