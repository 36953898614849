<h2>Takt Time {{taktTime}}s</h2>
<div cdkDropListGroup>
    <div class="example-container">
        <div cdkDropList id="P4-STR-019" [cdkDropListData]="workstationOne" class="example-list" cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let item of workstationOne" cdkDrag>
                <div id="{{item.id}}" [ngClass]="getWorkTypeClass(item.workType)">
                    <span *ngIf="item.workType == 'cycle'">({{item.id}})</span>{{item.title}} {{item.time}}s
                </div>
            </div>
        </div>
        <h2 class="container-header">P4-STR-019</h2>
        <div>
            <p>Cycle &Sigma; {{ws1Stats["cycle"]}}s</p>
            <p>Periodic &Sigma; {{ws1Stats["periodic"]}}s</p>
            <p>Fluctuation &Sigma; {{ws1Stats["fluctuation"]}}s</p>
            <p>Total &Sigma; {{ws1Stats["total"]}}s</p>
        </div>
    </div>

    <div class="example-container">
        <div cdkDropList id="P4-STR-020" [cdkDropListData]="workstationTwo" class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="example-box" *ngFor="let item of workstationTwo" cdkDrag>
                <div id="{{item.id}}" [ngClass]="getWorkTypeClass(item.workType)">
                    <span *ngIf="item.workType == 'cycle'">({{item.id}})</span>{{item.title}} {{item.time}}s
                </div>
            </div>
        </div>
        <h2 class="container-header">P4-STR-020</h2>
        <div>
            <p>Cycle &Sigma; {{ws2Stats["cycle"]}}s</p>
            <p>Periodic &Sigma; {{ws2Stats["periodic"]}}s</p>
            <p>Fluctuation &Sigma; {{ws2Stats["fluctuation"]}}s</p>
            <p>Total &Sigma; {{ws2Stats["total"]}}s</p>
        </div>
    </div>

    <div class="example-container">
        <div cdkDropList id="P4-STR-021" [cdkDropListData]="workstationThree" class="example-list" (cdkDropListDropped)="drop($event)" [cdkDropListSortPredicate]="sortPredicate">
            <div class="example-box" *ngFor="let item of workstationThree" cdkDrag>
                <div id="{{item.id}}" [ngClass]="getWorkTypeClass(item.workType)">
                    <span *ngIf="item.workType == 'cycle'">({{item.id}})</span>{{item.title}} {{item.time}}s
                </div>
            </div>
        </div>
        <h2 class="container-header">P4-STR-021</h2>
        <div>
            <p>Cycle &Sigma; {{ws3Stats["cycle"]}}s</p>
            <p>Periodic &Sigma; {{ws3Stats["periodic"]}}s</p>
            <p>Fluctuation &Sigma; {{ws3Stats["fluctuation"]}}s</p>
            <p>Total &Sigma; {{ws3Stats["total"]}}s</p>
        </div>
    </div>
</div>