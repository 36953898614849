<div class="fx-row-center-center">
  <div class="fx-flex-10">
  </div>
  <div class="fx-flex-80">
    <h3 mat-dialog-title>{{pitchID}} - {{formType}}</h3>

    <div class="fx-row-space-evenly-center" [style]="{'color': 'red', 'font-weight': 'bold', 'padding-bottom': '20px'}">
      Only trained ergo resources should update the ergo score.
    </div>
    <div class="fx-row-space-evenly-center" [style]="{'color': 'red', 'font-weight': 'bold', 'padding-bottom': '20px'}">
      The ergo score reflects the humantech risk priority score.
    </div>

    <div mat-dialog-content>
      <form [formGroup]="ergoFormGroup">
        <mat-form-field class="card-dialog">
          <input id="Ergo Score" type="number" matInput cdkTextareaAutosize placeholder="Ergo Score" formControlName="form_ergoScore">
        </mat-form-field>
        <mat-form-field class = "card-dialog" >
          <textarea id = "ergo_description" matInput cdkTextareaAutosize placeholder = "Ergo Notes" formControlName="form_ergoComments" ></textarea>
        </mat-form-field>
      </form>
    </div>
    <div mat-dialog-actions class="fx-row-space-evenly-center">
      <button mat-raised-button color="primary" (click)="updateErgoScore()">Update</button>
      <span class="spacer"></span>
      <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
    </div>
    <h3></h3>
  </div>
  <div class="fx-flex-10">
  </div>
</div>

<div *ngIf="isBusy">

  <mat-spinner></mat-spinner>

</div>