<br><br>
<div>
    <mat-toolbar color="primary" class="tableTitleBar" >
      <button mat-button color = "white" (click) = "goBackToVersionList()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Pitch List: {{displayPlantName}} - {{display_line}} - {{team}} - {{version}}</span>
      <span class="toolbar-spacer"></span>
      <span><button mat-raised-button color="link" (click) = "createPitch()">Create New Pitch</button></span>
      <span><button mat-raised-button color="link" (click) = "copyPitchWork()">Transfer Pitch Work </button></span>
    </mat-toolbar>
 
      <!--<div *ngIf="fullSizeMatcher.matches; else cardDisplay"><-->
          <mat-table [dataSource]="pitchList">
              <ng-container matColumnDef="pitch_id">
                  <mat-header-cell *matHeaderCellDef>Pitch</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.pitch_id}}  &nbsp;
                    <span *ngIf="d.service_attribute" [ngStyle]="{'color': d.service_color, 'font-size':'125%', 'font-weight': 'bold'}" [innerHTML]="d.service_attribute">
                    </span>
                  </mat-cell>
              </ng-container>
              <ng-container matColumnDef="pitch_name">
                  <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
                  <mat-cell *matCellDef="let d">{{d.pitch_name}}</mat-cell>
              </ng-container>  
              <ng-container matColumnDef="pitch_type">
                <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.type}}</mat-cell>
              </ng-container>
              <ng-container matColumnDef="subassembly_id">
                <mat-header-cell *matHeaderCellDef>Subassembly</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.subassembly_id}}</mat-cell>
              </ng-container>            
      
              <ng-container matColumnDef="takt_time">
                <mat-header-cell *matHeaderCellDef>Takt Time</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.takt_time}}</mat-cell>
              </ng-container>
      
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell *matCellDef="let d">{{d.status}}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="flags">
                 <mat-header-cell *matHeaderCellDef>Flags</mat-header-cell>
                 <mat-cell *matCellDef ="let d" ><span style="font-size:150%; color: darkred; font-weight: bold;" *ngFor = "let flag of d.flags" [innerHTML] = "flag.attribute"></span></mat-cell>
              </ng-container>

              <ng-container matColumnDef="services">
                <mat-header-cell *matHeaderCellDef>Service Pitches</mat-header-cell>
                <mat-cell *matCellDef ="let d" ><span *ngFor = "let service of d.service_pitches" [ngStyle]="{'font-size':'125%', 'color': service.service_color, 'font-weight': 'bold'}" [innerHTML] = "service.service_attribute"></span></mat-cell>
             </ng-container>
      
              <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                  <mat-cell *matCellDef="let d">
                    <button mat-button color="primary" (click) = "updatePitch(d)"><mat-icon>create</mat-icon>Edit</button>
                  </mat-cell>
                </ng-container>  
      
              <mat-header-row *matHeaderRowDef="displayedPitchColumns"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedPitchColumns;"></mat-row>
            </mat-table>
  
      <!--</div>
      <ng-template #cardDisplay>
          <mat-card *ngFor="let team of lineTeamList" class="objCards mat-elevation-z10">
            <mat-card-header>
              <mat-card-title>{{selectedPlant}} - Team List</mat-card-title>
            </mat-card-header>
            <mat-card-content>
              Line: {{team.line}} <br>
              Subline: {{team.subline}} <br>
              Team: {{team.team_name}} <br>
              Version: {{team.version_name}} <br>
              # Pitches: {{team.num_pitches}}<br>
              Takt Time: {{team.takt_time}} <br>
              Last Updated: {{team.last_updated_date}}
            </mat-card-content>
            <mat-card-footer>
              <mat-card-actions>
                <button [disabled]="!adminFlag" mat-button color="primary"><mat-icon>create</mat-icon>Edit</button>
              </mat-card-actions>
            </mat-card-footer>
          </mat-card>
      </ng-template><-->
  </div>
