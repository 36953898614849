import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Validators, UntypedFormGroup, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { TeamService } from '../services/team.service';
import { DeleteDialogComponent } from 'src/app/shared/delete-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { VariantType, Flag, PitchType, ServiceTie} from 'src/app/shared/models/type-list';
import { ServiceAttributes, ServiceColors} from 'src/app/shared/models/enum-list';



@Component({
  selector: 'app-pitch-dialog',
  templateUrl: './pitch-dialog.component.html',
  styleUrls: ['./pitch-dialog.component.scss']
})
export class PitchDialog {
  plantName: string;
  teamID: number;
  teamName: string;
  versionID: number;
  versionName: string;
  pitchConfigID: number;
  pitchID: string;
  pitchName: string;
  ergoScore: number;
  ergoComments:string;

  pitchType: string;
  subassemblyID: string;
  taktTime: number;
  
  typeOptions: string[];
  subassemblyIDOptions: string[];
  canDelete: boolean;

  pitchFormGroup: UntypedFormGroup;
  pitchFormType: string;
  totalwork: number;
  status: string;

  availableVariants: VariantType[] = [];
  availableVariantNames: string[];
  selectedVariants: string[] = [];
  previousSelectedVariants: VariantType[] = [];

  availableFlags: Flag[] = [];
  availableFlagIDs: string[];
  selectedFlags: string[] = [];
  previousSelectedFlags: Flag[] = [];
  isBusy: boolean = false;
  unique: boolean = true;
  pitchStatuses: string[] = ['open', 'blocked', 'active'];

  availablePitches: ServiceTie[];
  availablePitchIDs: string[];
  selectedServiceTies: number[];
  serviceAttribute: string = ''
  serviceColor: string = ''
  waterSpiders: string[]
  kitters: string[]
  repackers: string[]
  availablePitchIDs_WKR: string[];
  newPitchID: string;
  newServicePitchID: string;
  newSubassemblyPitchID: string;

  serviceType: boolean;

  constructor(
    public dialogRef: MatDialogRef<PitchDialog>,
    @Inject(MAT_DIALOG_DATA) public pitchData: PitchDialogData,
    private pitchFormBuilder: UntypedFormBuilder,
    private teamService: TeamService,
    private deleteDialog: MatDialog,
  )
  { 
    pitchData.form_type  ? this.pitchFormType = pitchData.form_type : this.pitchFormType = 'Create New Pitch'

    pitchData.plant_name ? this.plantName = pitchData.plant_name : this.plantName = ''
    pitchData.team_id ? this.teamID = pitchData.team_id: this.teamID = 0
    pitchData.team_name ? this.teamName = pitchData.team_name: this.teamName = ''
    pitchData.version_id ? this.versionID = pitchData.version_id: this.teamID = 0
    pitchData.version_name ? this.versionName = pitchData.version_name: this.teamName = ''

    
    this.teamService.getVariant(this.teamID).subscribe((
      out =>{
        console.log('getVariant out: ', out)
        this.availableVariants = out.Body
        this.availableVariantNames = this.availableVariants.map(variant => variant.variant_name)
      }
    ));
    console.log('Available Variants: ', this.availableVariants)

    this.teamService.getFlags().subscribe((
      out=>{
        this.availableFlags = out.Body
        this.availableFlagIDs = this.availableFlags.map(flag => flag.flag_id)
      }
    ))

    pitchData.service_ties ? this.availablePitches = pitchData.service_ties : this.availablePitches = []
    this.availablePitchIDs = this.pitchData.pitchList.filter(pitch=> pitch.type === 'pitch').map(pitch => pitch.pitch_id)

    this.waterSpiders = this.pitchData.pitchList.filter(pitch=> pitch.type === 'waterspider').map(w => w.pitch_id)
    this.kitters = this.pitchData.pitchList.filter(pitch=> pitch.type === 'kitter').map(k => k.pitch_id)
    this.repackers = this.pitchData.pitchList.filter(pitch=> pitch.type === 'repacker').map(r => r.pitch_id)
    
    this.availablePitchIDs_WKR = this.pitchData.pitchList.filter(pitch=> pitch.type === 'waterspider' || pitch.type === 'kitter' || pitch.type === 'repacker').map(w => w.pitch_id);
    this.setPrefillIDValues()

    pitchData.type ? this.pitchType = pitchData.type : this.pitchType = ''        
    pitchData.pitch_id ? this.pitchID = pitchData.pitch_id : this.pitchID = this.newPitchID
    pitchData.pitch_config_id ? this.pitchConfigID = pitchData.pitch_config_id : this.pitchConfigID = 0
    pitchData.subassembly_id ? this.subassemblyID = pitchData.subassembly_id : this.subassemblyID = ''
    pitchData.takt_time ? this.taktTime = pitchData.takt_time : this.taktTime = 0
    pitchData.pitchVariants ? this.selectedVariants = pitchData.pitchVariants.map(variant => variant.variant_name) : this.selectedVariants = []
    pitchData.pitchVariants ? this.previousSelectedVariants = pitchData.pitchVariants : this.previousSelectedVariants = []
    pitchData.flags ? this.selectedFlags = pitchData.flags.map(flag => flag.flag_id) : this.selectedFlags = []
    pitchData.flags ? this.previousSelectedFlags = pitchData.flags : this.previousSelectedFlags = []
    pitchData.can_delete ? this.canDelete = pitchData.can_delete : this.canDelete = false
    pitchData.totalwork ? this.totalwork = pitchData.totalwork : this.totalwork = 0
    pitchData.status ? this.status = pitchData.status : this.status = 'open'
    pitchData.pitch_name ? this.pitchName = pitchData.pitch_name : this.pitchName = ''
    pitchData.ergo_score ? this.ergoScore = pitchData.ergo_score : this.ergoScore = -1
    pitchData.service_ties ? this.selectedServiceTies = pitchData.service_ties.map(pitch => pitch.pitch_config_id) : this.selectedServiceTies = []
    pitchData.service_attribute ? this.serviceAttribute = pitchData.service_attribute : this.serviceAttribute = ''
    pitchData.service_color ? this.serviceColor = pitchData.service_color : this.serviceColor = ''

    this.typeOptions = ['pitch', 'subassembly', 'waterspider', 'kitter', 'repacker']
    this.subassemblyIDOptions = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    
    this.pitchFormGroup = this.pitchFormBuilder.group({
      form_pitchID: [(this.pitchID == null)? '': this.pitchID, [Validators.required, CustomValidators.lengthThree, CustomValidators.rightThreeNumber]],
      form_pitchType: [(this.pitchType == null)? 'pitch': this.pitchType, Validators.required],
      form_subassemblyID: [(this.subassemblyID == null)? '': this.subassemblyID],
      form_status: [(this.status == null)? 'open': this.status],
      form_pitchName: [(this.pitchName == null)? '' : this.pitchName],
      form_taktTime: [(this.taktTime == 0)? '' : this.taktTime, Validators.required],
      form_variants: [this.selectedVariants, Validators.required],
      form_flags: [this.selectedFlags],
      form_service_ties: [this.selectedServiceTies]
    })

    this.pitchFormGroup.valueChanges.subscribe((formValues) =>{
      this.pitchID = formValues.form_pitchID,
      this.pitchType = formValues.form_pitchType,
      this.taktTime = formValues.form_taktTime,
      this.selectedVariants = formValues.form_variants,
      this.selectedFlags = formValues.form_flags,
      this.pitchName = formValues.form_pitchName,
      this.status = formValues.form_status,
      this.selectedServiceTies = formValues.form_service_ties
    });

    this.pitchFormGroup.controls.form_pitchType.valueChanges.subscribe((value) => {
      if(value == "pitch"){
        this.pitchFormGroup.controls.form_pitchID.setValue(this.newPitchID)
        this.pitchFormGroup.controls.form_subassemblyID.setValue("")
        this.subassemblyID = "";
        this.serviceAttribute = ''
        this.serviceColor = ''
      }
      else if(value == "subassembly")
      {
        this.pitchFormGroup.controls.form_pitchID.setValue(this.newSubassemblyPitchID)
        this.pitchFormGroup.controls.form_subassemblyID.setValue("A");
        this.subassemblyID = "A";
        this.serviceAttribute = ''
        this.serviceColor = ''
      }
      else {
        if (value == "waterspider"){
          this.pitchFormGroup.controls.form_pitchID.setValue(this.newServicePitchID)
          this.serviceAttribute = ServiceAttributes.WaterSpider
          if (this.waterSpiders.length == 1)
            this.serviceColor = ServiceColors.DarkBlue
          else if (this.waterSpiders.length == 2)
            this.serviceColor = ServiceColors.DarkGreen
          else if (this.waterSpiders.length == 3)
            this.serviceColor = ServiceColors.Purple
          else
            this.serviceColor = ServiceColors.DarkRed
        
          } else if (value == "kitter"){
            this.pitchFormGroup.controls.form_pitchID.setValue(this.newServicePitchID)
            this.serviceAttribute = ServiceAttributes.Kitter
            if (this.kitters.length == 1)
              this.serviceColor = ServiceColors.DarkBlue
            else if (this.kitters.length == 2)
              this.serviceColor = ServiceColors.DarkGreen
            else if (this.kitters.length == 3)
              this.serviceColor = ServiceColors.Purple
            else
              this.serviceColor = ServiceColors.DarkRed
        
          } else if (value == "repacker"){
            this.pitchFormGroup.controls.form_pitchID.setValue(this.newServicePitchID)
            this.serviceAttribute = ServiceAttributes.Repacker
            if (this.repackers.length == 1)
              this.serviceColor = ServiceColors.DarkBlue
            else if (this.repackers.length == 2)
              this.serviceColor = ServiceColors.DarkGreen
            else if (this.repackers.length == 3)
              this.serviceColor = ServiceColors.Purple
            else
              this.serviceColor = ServiceColors.DarkRed
          }

        this.serviceType = true
      }
    });

    this.pitchFormGroup.controls.form_subassemblyID.valueChanges.subscribe((value) => {
      this.subassemblyID = value;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  createPitch(): void {
    let variantIDs: number[] = this.getVariantIDs()
    let flags: string[] = this.selectedFlags
    this.isBusy = true
    this.unique = this.isPitchUniqueCreate()

    if (this.pitchFormGroup.status == 'VALID' && this.unique){
      this.teamService.createPitch(this.plantName,this.teamID,this.versionID,this.pitchID,this.pitchType,this.subassemblyID,this.taktTime,this.status,variantIDs, this.pitchName, this.serviceAttribute, this.serviceColor).subscribe
        (out => {
          let pitchConfigID = out.Body.pitch_config_id

          const pitchFormData = {
            pitch_id: this.pitchID,
            type: this.pitchType
          }

          const pitchBuild = async() =>{
            const flagBuild = flags.length > 0 ? this.teamService.createPitchFlagConfig(pitchConfigID, flags, this.plantName, this.teamID, this.versionID, this.pitchID).subscribe(out => {return true}): false;
            const finishBuild = await Promise.all([flagBuild])

            return finishBuild
          }

        pitchBuild().then((response) =>{
          this.isBusy = false
          this.dialogRef.close(pitchFormData)
        })  

      })
    } else if (!this.unique) {
      this.isBusy = false
    } else {
      console.error('Invalid pitchFormGroup, was not caught in form control error handling.')
    }
  }

  updatePitch(){
    let variantIDs: number[] = this.getVariantIDs()
    let delvariant: VariantType[] = this.findVariantsToDelete()
    let flags: string[] = this.getNewFlags()
    this.isBusy = true
    this.unique = this.isPitchUniqueUpdate()
    
    if(this.pitchFormGroup.status == 'VALID' && this.unique){
      this.teamService.updatePitch(this.plantName,this.teamID,this.versionID,this.pitchConfigID,this.pitchID,this.pitchType,this.subassemblyID,this.taktTime,this.status,variantIDs, this.pitchName, this.ergoScore,this.ergoComments, this.serviceAttribute, this.serviceColor).subscribe
      (out => {
        let pitchConfigID = out.Body.pitch_config_id

        const pitchBuild = async() =>{
          const removeFlags = this.unselectedFlags()
          const flagBuild = flags.length > 0 ? this.teamService.createPitchFlagConfig(pitchConfigID, flags, this.plantName, this.teamID, this.versionID, this.pitchID).subscribe(out => {}): false
          const finishBuild =  await Promise.all([removeFlags, flagBuild])

          return finishBuild
        }

        pitchBuild().then((response) => {
          if (delvariant.length > 0)
            this.deleteVariant()
          else {
            this.isBusy = false
            this.dialogRef.close()
          }
        })

      })
    } else if (!this.unique) {
      this.isBusy = false  
    } else {
      console.error('Invalid pitchFormGroup, was not caught in form control error handling.')
    }
  }

  deletePitch(){
    this.isBusy = true
    if(this.pitchFormGroup.status == 'VALID'){ 
      const dialogRef = this.deleteDialog.open(DeleteDialogComponent, {
        width: '500px',
        data: 
        {
          team_name: this.teamName,
          version_name: this.versionName,
          pitch_config_id: this.pitchConfigID,
          pitch_id: this.pitchID,
          pitchVariants: this.previousSelectedVariants,
          totalwork: this.totalwork,
          can_delete: this.canDelete,
          form_type: 'deletePitch'
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result.action == 'delete'){
            this.teamService.deletePitch(this.pitchConfigID,this.plantName,this.teamID,this.versionID,this.pitchID).subscribe(out=> {
              this.isBusy = false
              this.dialogRef.close()
            })
        } else {
          this.isBusy = false
          this.dialogRef.close()
        }
      })
    } else {
      console.error('Invalid pitchFormGroup, was not caught in form control error handling.')
    }
  }

  deleteVariant(){
    let variants: VariantType[] = this.findVariantsToDelete()
    this.isBusy = true
    if(this.pitchFormGroup.status == 'VALID'){ 
      let variantsToDelete: VariantType[] = variants
      let totalVariantwork: number = 0
      let index: number
      variantsToDelete.forEach(variant =>{
        index = this.previousSelectedVariants.findIndex(id => id.variant_id === variant.variant_id) 
        totalVariantwork = totalVariantwork + this.previousSelectedVariants[index].total_work
      })
      const dialogRef = this.deleteDialog.open(DeleteDialogComponent, {
        width: '500px',
        data: 
        {
          plant_name: this.plantName,
          team_name: this.teamName,
          version_name: this.versionName,
          pitch_config_id: this.pitchConfigID,
          pitch_id: this.pitchID,
          pitchVariants: this.previousSelectedVariants,
          uncheckedvariants: variants.map(variant => variant.variant_name),
          uncheckedvariantswork : totalVariantwork,
          totalwork:this.totalwork,
          can_delete: this.canDelete,
          form_type: 'deleteVariant'
        }
      });
    
      dialogRef.afterClosed().subscribe(result => {
        if (result != undefined && result.action == 'delete'){
            variantsToDelete.forEach(variant => {
              this.teamService.deletePitchVariant(this.pitchConfigID,variant.variant_id,this.plantName,this.teamID,this.versionID,this.pitchID,variant.variant_name).subscribe(out=> {
                this.isBusy = false
                this.dialogRef.close()
                })
              })
          }
        else {
          this.isBusy = false
          this.dialogRef.close()
        }
      })
    } else {
      console.error('Invalid pitchFormGroup, was not caught in form control error handling.')
    }
  }

  getVariantIDs(): number[] {
    let variantIDs: number[] = []
    this.selectedVariants.forEach(selected => {
      let variantID: VariantType = this.availableVariants.find(variant => variant.variant_name === selected)
      variantIDs.push(variantID.variant_id)
    })
    return variantIDs
  }

  findVariantsToDelete(): VariantType[] {
    let variants: VariantType[] = []
    this.previousSelectedVariants.forEach (prv_selected =>{
      if (this.selectedVariants.find(name => name === prv_selected.variant_name) == undefined)
        variants.push(prv_selected)
    })
    return variants
  }

  getNewFlags() : string[] {
    let flags: string[] = []
    this.selectedFlags.forEach (selected => {
      if (this.previousSelectedFlags.find(id => id.flag_id === selected) == undefined)
        flags.push(selected)
    })
    return flags
  }

  async unselectedFlags(){
    await 
    this.previousSelectedFlags.forEach(prv_selected =>{
      if (this.selectedFlags.find(id => id === prv_selected.flag_id) == undefined)
      {
        this.teamService.deletePitchFlagConfig(this.pitchConfigID, prv_selected.flag_id, this.plantName, this.teamID, this.versionID, this.pitchID).subscribe(out=>{})
      }
    })
  }

  normalizeString(s: string): string {
    return s.replace(/\s+/g, '').toLowerCase()
  }

  isPitchUniqueCreate(): boolean {
    var filteredList = this.pitchData.pitchList
    var unique: boolean = true

    filteredList.forEach(pitch => {
      if (this.normalizeString(pitch.pitch_id) == this.normalizeString(this.pitchID)){
        if (this.pitchType == 'subassembly' && pitch.type == 'subassembly'){
          if (this.normalizeString(pitch.subassembly_id) == this.normalizeString(this.subassemblyID)){
            unique = false
          }
        } else if (this.pitchType == 'pitch') {
          unique = false
        }
      }
    })
    
    return unique
  }

  isPitchUniqueUpdate(): boolean {
    var filteredList = this.pitchData.pitchList
    var unique: boolean = true
    let variantIndex: number

    filteredList.forEach(pitch => {
      if (this.normalizeString(pitch.pitch_id) == this.normalizeString(this.pitchID) && pitch.pitch_config_id != this.pitchConfigID){
        if (this.pitchType == 'subassembly' && pitch.type == 'subassembly'){
          if (this.normalizeString(pitch.subassembly_id) == this.normalizeString(this.subassemblyID)){
            console.log('subassembly uniqueness')
            unique = false
          }
        } else if (this.pitchType == 'pitch' && pitch.type == 'pitch') {
          console.log('pitch uniqueness')
          unique = false
        }
      }
    })
    
    return unique
  }

  getPrefillPitchID(type:string): string {
    let prefillPitchID: string = '';    
    let pitchIDList: string[] = [];
    let lastPitchID: string = '';
    let lastPitchIDNum: number = 0;
    console.log('Type: ', type)
    if (type == 'service')
      pitchIDList = this.availablePitchIDs_WKR     
    else
      pitchIDList = this.availablePitchIDs  
    console.log('Pitches:', pitchIDList)  
    if (pitchIDList.length > 0) {
      console.log('Pitch ID List:', pitchIDList)
      lastPitchID = pitchIDList[pitchIDList.length - 1]
      lastPitchIDNum = Number(lastPitchID.slice(lastPitchID.length - 3))
      lastPitchIDNum = lastPitchIDNum + 1    
      prefillPitchID = lastPitchID.slice(0, - 3) + lastPitchIDNum.toString().padStart(3, "0")
    } else if (type == 'pitch' && this.availablePitchIDs_WKR.length > 0) {      
      prefillPitchID = this.availablePitchIDs_WKR[this.availablePitchIDs_WKR.length - 1].slice(0, -3) + '001'
    } else if (type =='service' && this.availablePitchIDs.length > 0) {
      prefillPitchID = this.availablePitchIDs[this.availablePitchIDs.length - 1].slice(0, - 3) + '900'
    }
    return prefillPitchID;
  }

  setPrefillIDValues(){

    if (this.pitchData.form_type == 'Create New Pitch')
    {
      this.newPitchID = this.getPrefillPitchID('pitch')
      this.newSubassemblyPitchID = ''
      this.newServicePitchID = this.getPrefillPitchID('service')
    } else {
      if (this.pitchData.type == 'waterspider' || this.pitchData.type == 'repacker' || this.pitchData.type == 'kitter'){
        this.newPitchID = this.getPrefillPitchID('pitch')
        this.newSubassemblyPitchID = ''
        this.newServicePitchID = this.pitchData.pitch_id
      } else if (this.pitchData.type == 'subassembly') {
        this.newPitchID = this.getPrefillPitchID('pitch')
        this.newSubassemblyPitchID = this.pitchData.pitch_id
        this.newServicePitchID = this.getPrefillPitchID('service')
      } else {
        this.newPitchID = this.pitchData.pitch_id
        this.newSubassemblyPitchID = this.pitchData.pitch_id
        this.newServicePitchID = this.getPrefillPitchID('service')
      }
    }
    console.log('Pitch ID:', this.newPitchID)
    console.log('Service ID:', this.newServicePitchID)
    console.log('Subassembly ID:', this.newSubassemblyPitchID)
  }
}

export class CustomValidators {
  static lengthThree: ValidatorFn = (formGroup: UntypedFormGroup) => {
    var isValid: Boolean = false;
    var pitchIDStr: string = formGroup.value;
    
    if(pitchIDStr.length < 3){
      console.log("String less than 3 characters")
      isValid = false;
    }else{
      isValid = true;
    }

    return isValid ? null : { lengthThree: true };
  }

  static rightThreeNumber: ValidatorFn = (formGroup: UntypedFormGroup) => {
    var isValid: Boolean = false;
    var pitchIDStr: string = formGroup.value;
    var pitchID_lastchars:string = pitchIDStr.slice(pitchIDStr.length - 3);
    var isNotNumber: Boolean = false;
        
    for (var i = 0; i < pitchID_lastchars.length; i++) {

      if(!Number.isInteger(Number(pitchID_lastchars[i])) && isNotNumber == false){
        isNotNumber = true;
        break;        
      }
    }

    if (isNotNumber == true){
      console.log("Right three are not a number");
    }
    else {
      isValid = true;
    }

    return isValid ? null : { rightThreeNumber: true };
  }

}

export interface PitchDialogData {
  plant_name: string;
  team_id: number;
  team_name: string;
  version_id: number;
  version_name: string;
  pitch_config_id: number;
  pitch_id: string;
  type: string;
  subassembly_id: string;
  takt_time: number;
  pitchVariants: VariantType[];
  flags: Flag[];
  can_delete: boolean;
  form_type: string;
  totalwork: number;
  status: string;
  pitch_name: string;
  pitchList: PitchType[];
  ergo_score: number;
  service_ties: ServiceTie[];
  service_attribute: string;
  service_color: string;
}
