<h3 mat-dialog-title>Migrate to Standardized Work</h3>


<div mat-dialog-content [style.height.px]=325>
    <form [formGroup]="fg_Migrate">

      <mat-form-field class = "card-dialog">
        <mat-select id = "plantName" matInput cdkTextareaAutosize placeholder="Plant" formControlName="f_Plant" (valueChange)="getLines($event)">
            <mat-option *ngFor="let plant of avPlants" [value]="plant.plant">   
                {{plant.display}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class = "card-dialog">
        <mat-select id = "line" matInput cdkTextareaAutosize placeholder="Line" formControlName="f_Line" (valueChange)="getSublines($event)">
            <mat-option *ngFor="let line of avLines" [value]="line">   
                {{line}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class = "card-dialog">
        <mat-select id = "subline" matInput cdkTextareaAutosize placeholder="Subline" formControlName="f_Subline" (valueChange)="getTeams($event)">
            <mat-option *ngFor="let subline of avSublines" [value]="subline">   
                {{subline}}
            </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class = "card-dialog">
        <mat-select id = "team" matInput cdkTextareaAutosize placeholder="Team" formControlName="f_Team">
            <mat-option *ngFor="let team of avTeams" [value]="team.id">   
                {{team.name}}
            </mat-option>
        </mat-select>
      </mat-form-field>

    </form>

    

<div>

    <div mat-dialog-actions class="fx-row-space-evenly-center">
        <button [disabled] = "fg_Migrate.status === 'INVALID'" mat-raised-button color="primary" (click)="migrateTeam()">Submit</button>
        <button mat-raised-button color="primary" (click)="onNoClick()">Close</button>
    </div>




